@import "colors";
@import "fonts";

body {
  width: 100%;
  height: 100%;
  font-weight: 300;
  color: @dark;
  background-color: @light;
  font-size: 0.875rem;
}

html {
  width: 100%;
  height: 100%;
}

h1 {
  margin: 0 0 35px;
  font-family: "Simplon";
  font-weight: 300;
  letter-spacing: 1px;
}
h4,
h5 {
  margin: 0 0 35px;
  font-weight: 300;
  letter-spacing: 1px;
}

h6 {
  font-family: "Simplon";
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 15px;
}

h5 {
  font-size: 12px;
}

h2 {
  margin: 0 0 35px;
  font-family: "Simplon";
  font-weight: 300;
  letter-spacing: 1px;
}

h3 {
  //Adding Important on these to override type.less styling from bootstrap that isn't present in prod
  margin: 0 0 35px !important;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 1.875rem !important;
}

p {
  margin: 0 0 25px;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 300;
}

label {
  font-weight: 600;
  font-family: "Simplon";
}

a {
  color: @dark;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

a:hover,
a:focus {
  text-decoration: none;
  color: #cccccc;
}

.italic {
  font-style: italic;
}
