@import 'colors';

.dropdown-menu-component {
  display: flex;
  align-items: stretch;
  align-content: stretch;

  .dropdown-menu {
    width: 100%;
  }

  .dropdown-menu-loading-animate {
    animation: dropdown-menu-loading-animate .7s infinite linear;
  }

  @keyframes dropdown-menu-loading-animate {
    from {
      transform: scale(1) rotate(0deg);
    }
    to {
      transform: scale(1) rotate(360deg);
    }
  }

  a {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .main-button {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1;
    text-align: left;
  }

  .clear-selection {
    font-size: 20px;
    float: left;
    padding: 6px;
    color: transparent;

    &.has-selection {
      cursor: pointer;
      color: @gray;

      &.disabled, &.disabled:hover {
        color: @gray;
        cursor: not-allowed;
        opacity: .65;
      }

      &:hover {
        color: @watermelon;
      }
    }
  }
}
