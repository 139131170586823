@import "colors";

@media only screen {

  .error-logo {
    border-bottom: 1px solid @lightgray;
    padding: 30px 0;
  }

  form {
    .has-error {
      color: @error;
    }

    .has-error input.ng-invalid-required:focus {
      background-color: white;
    }
  }

}

