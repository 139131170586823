@import "colors";

@media only screen {

  #register-title {
    padding-top: 100px;
    font-size: 46px;
  }

  .phone-num {
    margin-bottom: 15px;
    padding: 5px;
  }

  .phone-num-background {
    padding: 7px;
    background-color: @lightgray;
    border-radius: 3px;
  }

  .verification-box {
    margin: auto;
    text-align: center;
    width: 100px;
  }

  .btn-text {
    height: 50px;
    width: 250px;
  }

  #register-body {
    padding-bottom: 100px;
  }

  .terms_of_use {
    font-size: 15px;
    margin-bottom: 15px;
    padding: 15px;
  }

  .terms_in_modal {
    margin-bottom: 30px;
    overflow: auto;
    max-height: 450px;
    border-radius: 3px;
    border: 1px solid @unavailable;
    overflow-y: auto;
  }

  .terms_of_use > h1 {
    font-size: 20px;
  }

  .terms_of_use > h2 {
    font-size: 18px;
  }

  .terms_of_use > p {
    font-size: 14px;
  }

  .terms_of_use > * > h2 {
    font-size: 18px;
  }

  .terms_of_use > * > p {
    font-size: 14px;
  }

  .terms_of_use > * > * > p {
    font-size: 14px;
  }

  .terms_of_use > * > p {
    counter-increment: par-num
  }
  .terms_of_use > * > h2 {
    counter-increment: hdr-num;
    counter-reset: par-num
  }
  .terms_of_use > * > p:before {
    content: counter(hdr-num) "." counter(par-num) " "
  }

}
