.dc-settings-page {
  h4 {
    margin: 0 0 5px 0;
  }

  hr {
    margin: 10px 0 10px 0;
  }

  .buttons {
    float: right;
    margin-left: 15px;
  }

  .row {
    margin: 0;
  }

  .editable {

    .checkbox {
      display: flex;
    }

    .editable-weekday {
      margin-left: 25px;
    }
  }
}