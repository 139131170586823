@import "colors";
@import "buttons";

@media only screen {

  .account-settings {
    margin-top: 32px;

    #warehouse .editable-input {
      width: 255px;
    }

    #max_drivers .editable-input {
      width: 100px;
    }

    #default_service_time .editable-input {
      width: 100px;
    }

    .editable-input {
      height: 30px;
      padding: 6px 6px;
      border: 1px solid #ccc;
      border-radius: 2px;
    }

    .editable-buttons button {
           padding: 2px 4px;
           margin-top: 5px;
           font-size: 11px;
           line-height: 14px;
           border-radius: 2px;
           border: 1px solid @steelblue;
           color: @light;
           background-color: @steelblue;
         }

    .editable-buttons button:hover,
    .editable-buttons button:focus {
      border: 1px solid darken(@steelblue, 11%);
      outline: none;
      color: @light;
      background-color: darken(@steelblue, 11%);
    }

    .editable-empty, a.editable-empty {
      font-style: normal;
      color: @watermelon;
      border-bottom: 1px solid @watermelon;
    }

    .editable-click, .a.editable-click {
      color: @dark;
      border-bottom: 1px solid lighten(@darkgray, 15%);
      padding-bottom: 3px;
    }
    
  }

  .operating-days-label {
    display: inline-block;
    min-width: 100px;
  }

  input.form-control.half-width {
    width: 40%;
  }

}
