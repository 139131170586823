@import "colors";

.user-profiles-panel {

  .role {
    margin-right: 2px;
  }

  .delete-user-icon {
    color: @graybluelabel;
  }

  .delete-user-icon:hover {
    color: @watermelon;
  }

  .user-search {
    width: 50%;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .hover-to-show:hover .hover-content {
    visibility: visible;
    transition: none;
  }

  .hover-content {
    visibility: hidden;
    transition: none;
  }

  .darker-hover:hover {
    background-color: darken(@lightgray, 10%);
  }

  .lighter-background {
    background-color: darken(@lightgray, 4%);
  }

}

