@import "colors";

.driver-profiles-panel {

  .delete-driver-icon {
    color: @graybluelabel;
  }

  .delete-driver-icon:hover {
    color: @watermelon;
  }

  .driver-search {
    width: 50%;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .driver-profiles {
    height: 200px;
    width: 85%;
    margin: auto;
    overflow-x: hidden;
    border-radius: 5px;
    border: 1px solid lighten(@unavailable, 15%);

  }

  .separator {
    height: 50px;
  }

  .settings-field {
    margin-left: 30px;
  }

  .default-login-secret-code-header {
    font-size: 24px;
  }

  .secret-code-setting-form-field {
    margin-top: 10px;

    .default-secret-code-input {
      margin-left: 20px;
    }

    input {
      margin-left: 30px;
    }
  }

  .settings-header {
    margin-top: 10px;
    font-size: 24px;
  }

  .setting-description {
    margin-left: 25px;
    font-size: 12px;
  }

  .hover-to-show:hover .hover-content {
    visibility: visible;
    transition: none;
  }

  .hover-content {
    visibility: hidden;
    transition: none;
  }

  .darker-hover:hover {
    background-color: darken(@lightgray, 10%);
  }

  .lighter-background {
    background-color: darken(@lightgray, 4%);
  }

  .no-drivers {
    color: @unavailable;
    font-weight: bolder;
    padding-top: 50px;
    margin-bottom: 10px;
  }

  .no-drivers-desc {
    color: @unavailable;
    font-weight: bolder;
  }

  #driver-settings-section {
    background-color: @lightgray;
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
    border-radius: 5px;
  }

  #current-driver-stats-section {
    background-color: @lightgray;
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
  }

  .limited-width {
    max-width: 240px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

}

