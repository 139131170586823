@font-face {
  font-family: 'Glyphicons Regular';
  src: url('../fonts/glyphicons-regular.eot');
  src: url('../fonts/glyphicons-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-regular.woff2') format('woff2'), url('../fonts/glyphicons-regular.woff') format('woff'), url('../fonts/glyphicons-regular.ttf') format('truetype'), url('../fonts/glyphicons-regular.svg#glyphiconsregular') format('svg')
}

.glyphicon {
  font-family:'Glyphicons Regular' !important;
}

.glyphicon-glass:before {
  content: "\E001" !important;
}

.glyphicon-leaf:before {
  content: "\E002" !important;
}

.glyphicon-dog:before {
  content: "\E003" !important;
}

.glyphicon-user:before {
  content: "\E004" !important;
}

.glyphicon-girl:before {
  content: "\E005" !important;
}

.glyphicon-car:before {
  content: "\E006" !important;
}

.glyphicon-user-add:before {
  content: "\E007" !important;
}

.glyphicon-user-remove:before {
  content: "\E008" !important;
}

.glyphicon-film:before {
  content: "\E009" !important;
}

.glyphicon-magic:before {
  content: "\E010" !important;
}

.glyphicon-envelope:before {
  content: "\E011" !important;
}

.glyphicon-camera:before {
  content: "\E012" !important;
}

.glyphicon-heart:before {
  content: "\E013" !important;
}

.glyphicon-beach-umbrella:before {
  content: "\E014" !important;
}

.glyphicon-train:before {
  content: "\E015" !important;
}

.glyphicon-print:before {
  content: "\E016" !important;
}

.glyphicon-bin:before {
  content: "\E017" !important;
}

.glyphicon-music:before {
  content: "\E018" !important;
}

.glyphicon-music-alt:before {
  content: "\E019" !important;
}

.glyphicon-heart-empty:before {
  content: "\E020" !important;
}

.glyphicon-home:before {
  content: "\E021" !important;
}

.glyphicon-snowflake:before {
  content: "\E022" !important;
}

.glyphicon-fire:before {
  content: "\E023" !important;
}

.glyphicon-magnet:before {
  content: "\E024" !important;
}

.glyphicon-parents:before {
  content: "\E025" !important;
}

.glyphicon-binoculars:before {
  content: "\E026" !important;
}

.glyphicon-road:before {
  content: "\E027" !important;
}

.glyphicon-search:before {
  content: "\E028" !important;
}

.glyphicon-cars:before {
  content: "\E029" !important;
}

.glyphicon-notes-2:before {
  content: "\E030" !important;
}

.glyphicon-pencil:before {
  content: "\E031" !important;
}

.glyphicon-bus:before {
  content: "\E032" !important;
}

.glyphicon-wifi-alt:before {
  content: "\E033" !important;
}

.glyphicon-luggage:before {
  content: "\E034" !important;
}

.glyphicon-old-man:before {
  content: "\E035" !important;
}

.glyphicon-woman:before {
  content: "\E036" !important;
}

.glyphicon-file:before {
  content: "\E037" !important;
}

.glyphicon-coins:before {
  content: "\E038" !important;
}

.glyphicon-plane:before {
  content: "\E039" !important;
}

.glyphicon-notes:before {
  content: "\E040" !important;
}

.glyphicon-stats:before {
  content: "\E041" !important;
}

.glyphicon-charts:before {
  content: "\E042" !important;
}

.glyphicon-pie-chart:before {
  content: "\E043" !important;
}

.glyphicon-group:before {
  content: "\E044" !important;
}

.glyphicon-keys:before {
  content: "\E045" !important;
}

.glyphicon-calendar:before {
  content: "\E046" !important;
}

.glyphicon-router:before {
  content: "\E047" !important;
}

.glyphicon-camera-small:before {
  content: "\E048" !important;
}

.glyphicon-star-empty:before {
  content: "\E049" !important;
}

.glyphicon-star:before {
  content: "\E050" !important;
}

.glyphicon-link:before {
  content: "\E051" !important;
}

.glyphicon-eye-open:before {
  content: "\E052" !important;
}

.glyphicon-eye-close:before {
  content: "\E053" !important;
}

.glyphicon-alarm:before {
  content: "\E054" !important;
}

.glyphicon-clock:before {
  content: "\E055" !important;
}

.glyphicon-stopwatch:before {
  content: "\E056" !important;
}

.glyphicon-projector:before {
  content: "\E057" !important;
}

.glyphicon-history:before {
  content: "\E058" !important;
}

.glyphicon-truck:before {
  content: "\E059" !important;
}

.glyphicon-cargo:before {
  content: "\E060" !important;
}

.glyphicon-compass:before {
  content: "\E061" !important;
}

.glyphicon-keynote:before {
  content: "\E062" !important;
}

.glyphicon-paperclip:before {
  content: "\E063" !important;
}

.glyphicon-power:before {
  content: "\E064" !important;
}

.glyphicon-lightbulb:before {
  content: "\E065" !important;
}

.glyphicon-tag:before {
  content: "\E066" !important;
}

.glyphicon-tags:before {
  content: "\E067" !important;
}

.glyphicon-cleaning:before {
  content: "\E068" !important;
}

.glyphicon-ruler:before {
  content: "\E069" !important;
}

.glyphicon-gift:before {
  content: "\E070" !important;
}

.glyphicon-umbrella:before {
  content: "\E071" !important;
}

.glyphicon-book:before {
  content: "\E072" !important;
}

.glyphicon-bookmark:before {
  content: "\E073" !important;
}

.glyphicon-wifi:before {
  content: "\E074" !important;
}

.glyphicon-cup:before {
  content: "\E075" !important;
}

.glyphicon-stroller:before {
  content: "\E076" !important;
}

.glyphicon-headphones:before {
  content: "\E077" !important;
}

.glyphicon-headset:before {
  content: "\E078" !important;
}

.glyphicon-warning-sign:before {
  content: "\E079" !important;
}

.glyphicon-signal:before {
  content: "\E080" !important;
}

.glyphicon-retweet:before {
  content: "\E081" !important;
}

.glyphicon-refresh:before {
  content: "\E082" !important;
}

.glyphicon-roundabout:before {
  content: "\E083" !important;
}

.glyphicon-random:before {
  content: "\E084" !important;
}

.glyphicon-heat:before {
  content: "\E085" !important;
}

.glyphicon-repeat:before {
  content: "\E086" !important;
}

.glyphicon-display:before {
  content: "\E087" !important;
}

.glyphicon-log-book:before {
  content: "\E088" !important;
}

.glyphicon-address-book:before {
  content: "\E089" !important;
}

.glyphicon-building:before {
  content: "\E090" !important;
}

.glyphicon-eyedropper:before {
  content: "\E091" !important;
}

.glyphicon-adjust:before {
  content: "\E092" !important;
}

.glyphicon-tint:before {
  content: "\E093" !important;
}

.glyphicon-crop:before {
  content: "\E094" !important;
}

.glyphicon-vector-path-square:before {
  content: "\E095" !important;
}

.glyphicon-vector-path-circle:before {
  content: "\E096" !important;
}

.glyphicon-vector-path-polygon:before {
  content: "\E097" !important;
}

.glyphicon-vector-path-line:before {
  content: "\E098" !important;
}

.glyphicon-vector-path-curve:before {
  content: "\E099" !important;
}

.glyphicon-vector-path-all:before {
  content: "\E100" !important;
}

.glyphicon-font:before {
  content: "\E101" !important;
}

.glyphicon-italic:before {
  content: "\E102" !important;
}

.glyphicon-bold:before {
  content: "\E103" !important;
}

.glyphicon-text-underline:before {
  content: "\E104" !important;
}

.glyphicon-text-strike:before {
  content: "\E105" !important;
}

.glyphicon-text-height:before {
  content: "\E106" !important;
}

.glyphicon-text-width:before {
  content: "\E107" !important;
}

.glyphicon-text-resize:before {
  content: "\E108" !important;
}

.glyphicon-left-indent:before {
  content: "\E109" !important;
}

.glyphicon-right-indent:before {
  content: "\E110" !important;
}

.glyphicon-align-left:before {
  content: "\E111" !important;
}

.glyphicon-align-center:before {
  content: "\E112" !important;
}

.glyphicon-align-right:before {
  content: "\E113" !important;
}

.glyphicon-justify:before {
  content: "\E114" !important;
}

.glyphicon-list:before {
  content: "\E115" !important;
}

.glyphicon-text-smaller:before {
  content: "\E116" !important;
}

.glyphicon-text-bigger:before {
  content: "\E117" !important;
}

.glyphicon-embed:before {
  content: "\E118" !important;
}

.glyphicon-embed-close:before {
  content: "\E119" !important;
}

.glyphicon-table:before {
  content: "\E120" !important;
}

.glyphicon-message-full:before {
  content: "\E121" !important;
}

.glyphicon-message-empty:before {
  content: "\E122" !important;
}

.glyphicon-message-in:before {
  content: "\E123" !important;
}

.glyphicon-message-out:before {
  content: "\E124" !important;
}

.glyphicon-message-plus:before {
  content: "\E125" !important;
}

.glyphicon-message-minus:before {
  content: "\E126" !important;
}

.glyphicon-message-ban:before {
  content: "\E127" !important;
}

.glyphicon-message-flag:before {
  content: "\E128" !important;
}

.glyphicon-message-lock:before {
  content: "\E129" !important;
}

.glyphicon-message-new:before {
  content: "\E130" !important;
}

.glyphicon-inbox:before {
  content: "\E131" !important;
}

.glyphicon-inbox-plus:before {
  content: "\E132" !important;
}

.glyphicon-inbox-minus:before {
  content: "\E133" !important;
}

.glyphicon-inbox-lock:before {
  content: "\E134" !important;
}

.glyphicon-inbox-in:before {
  content: "\E135" !important;
}

.glyphicon-inbox-out:before {
  content: "\E136" !important;
}

.glyphicon-cogwheel:before {
  content: "\E137" !important;
}

.glyphicon-cogwheels:before {
  content: "\E138" !important;
}

.glyphicon-picture:before {
  content: "\E139" !important;
}

.glyphicon-adjust-alt:before {
  content: "\E140" !important;
}

.glyphicon-database-lock:before {
  content: "\E141" !important;
}

.glyphicon-database-plus:before {
  content: "\E142" !important;
}

.glyphicon-database-minus:before {
  content: "\E143" !important;
}

.glyphicon-database-ban:before {
  content: "\E144" !important;
}

.glyphicon-folder-open:before {
  content: "\E145" !important;
}

.glyphicon-folder-plus:before {
  content: "\E146" !important;
}

.glyphicon-folder-minus:before {
  content: "\E147" !important;
}

.glyphicon-folder-lock:before {
  content: "\E148" !important;
}

.glyphicon-folder-flag:before {
  content: "\E149" !important;
}

.glyphicon-folder-new:before {
  content: "\E150" !important;
}

.glyphicon-edit:before {
  content: "\E151" !important;
}

.glyphicon-new-window:before {
  content: "\E152" !important;
}

.glyphicon-check:before {
  content: "\E153" !important;
}

.glyphicon-unchecked:before {
  content: "\E154" !important;
}

.glyphicon-more-windows:before {
  content: "\E155" !important;
}

.glyphicon-show-big-thumbnails:before {
  content: "\E156" !important;
}

.glyphicon-show-thumbnails:before {
  content: "\E157" !important;
}

.glyphicon-show-thumbnails-with-lines:before {
  content: "\E158" !important;
}

.glyphicon-show-lines:before {
  content: "\E159" !important;
}

.glyphicon-playlist:before {
  content: "\E160" !important;
}

.glyphicon-imac:before {
  content: "\E161" !important;
}

.glyphicon-macbook:before {
  content: "\E162" !important;
}

.glyphicon-ipad:before {
  content: "\E163" !important;
}

.glyphicon-iphone:before {
  content: "\E164" !important;
}

.glyphicon-iphone-transfer:before {
  content: "\E165" !important;
}

.glyphicon-iphone-exchange:before {
  content: "\E166" !important;
}

.glyphicon-ipod:before {
  content: "\E167" !important;
}

.glyphicon-ipod-shuffle:before {
  content: "\E168" !important;
}

.glyphicon-ear-plugs:before {
  content: "\E169" !important;
}

.glyphicon-record:before {
  content: "\E170" !important;
}

.glyphicon-step-backward:before {
  content: "\E171" !important;
}

.glyphicon-fast-backward:before {
  content: "\E172" !important;
}

.glyphicon-rewind:before {
  content: "\E173" !important;
}

.glyphicon-play:before {
  content: "\E174" !important;
}

.glyphicon-pause:before {
  content: "\E175" !important;
}

.glyphicon-stop:before {
  content: "\E176" !important;
}

.glyphicon-forward:before {
  content: "\E177" !important;
}

.glyphicon-fast-forward:before {
  content: "\E178" !important;
}

.glyphicon-step-forward:before {
  content: "\E179" !important;
}

.glyphicon-eject:before {
  content: "\E180" !important;
}

.glyphicon-facetime-video:before {
  content: "\E181" !important;
}

.glyphicon-download-alt:before {
  content: "\E182" !important;
}

.glyphicon-mute:before {
  content: "\E183" !important;
}

.glyphicon-volume-down:before {
  content: "\E184" !important;
}

.glyphicon-volume-up:before {
  content: "\E185" !important;
}

.glyphicon-screenshot:before {
  content: "\E186" !important;
}

.glyphicon-move:before {
  content: "\E187" !important;
}

.glyphicon-more:before {
  content: "\E188" !important;
}

.glyphicon-brightness-reduce:before {
  content: "\E189" !important;
}

.glyphicon-brightness-increase:before {
  content: "\E190" !important;
}

.glyphicon-plus-sign:before {
  content: "\E191" !important;
}

.glyphicon-minus-sign:before {
  content: "\E192" !important;
}

.glyphicon-remove-sign:before {
  content: "\E193" !important;
}

.glyphicon-ok-sign:before {
  content: "\E194" !important;
}

.glyphicon-question-sign:before {
  content: "\E195" !important;
}

.glyphicon-info-sign:before {
  content: "\E196" !important;
}

.glyphicon-exclamation-sign:before {
  content: "\E197" !important;
}

.glyphicon-remove-circle:before {
  content: "\E198" !important;
}

.glyphicon-ok-circle:before {
  content: "\E199" !important;
}

.glyphicon-ban-circle:before {
  content: "\E200" !important;
}

.glyphicon-download:before {
  content: "\E201" !important;
}

.glyphicon-upload:before {
  content: "\E202" !important;
}

.glyphicon-shopping-cart:before {
  content: "\E203" !important;
}

.glyphicon-lock:before {
  content: "\E204" !important;
}

.glyphicon-unlock:before {
  content: "\E205" !important;
}

.glyphicon-electricity:before {
  content: "\E206" !important;
}

.glyphicon-ok:before {
  content: "\E207" !important;
}

.glyphicon-remove:before {
  content: "\E208" !important;
}

.glyphicon-cart-in:before {
  content: "\E209" !important;
}

.glyphicon-cart-out:before {
  content: "\E210" !important;
}

.glyphicon-arrow-left:before {
  content: "\E211" !important;
}

.glyphicon-arrow-right:before {
  content: "\E212" !important;
}

.glyphicon-arrow-down:before {
  content: "\E213" !important;
}

.glyphicon-arrow-up:before {
  content: "\E214" !important;
}

.glyphicon-resize-small:before {
  content: "\E215" !important;
}

.glyphicon-resize-full:before {
  content: "\E216" !important;
}

.glyphicon-circle-arrow-left:before {
  content: "\E217" !important;
}

.glyphicon-circle-arrow-right:before {
  content: "\E218" !important;
}

.glyphicon-circle-arrow-top:before {
  content: "\E219" !important;
}

.glyphicon-circle-arrow-down:before {
  content: "\E220" !important;
}

.glyphicon-play-button:before {
  content: "\E221" !important;
}

.glyphicon-unshare:before {
  content: "\E222" !important;
}

.glyphicon-share:before {
  content: "\E223" !important;
}

.glyphicon-chevron-right:before {
  content: "\E224" !important;
}

.glyphicon-chevron-left:before {
  content: "\E225" !important;
}

.glyphicon-bluetooth:before {
  content: "\E226" !important;
}

.glyphicon-euro:before {
  content: "\E227" !important;
}

.glyphicon-usd:before {
  content: "\E228" !important;
}

.glyphicon-gbp:before {
  content: "\E229" !important;
}

.glyphicon-retweet-2:before {
  content: "\E230" !important;
}

.glyphicon-moon:before {
  content: "\E231" !important;
}

.glyphicon-sun:before {
  content: "\E232" !important;
}

.glyphicon-cloud:before {
  content: "\E233" !important;
}

.glyphicon-direction:before {
  content: "\E234" !important;
}

.glyphicon-brush:before {
  content: "\E235" !important;
}

.glyphicon-pen:before {
  content: "\E236" !important;
}

.glyphicon-zoom-in:before {
  content: "\E237" !important;
}

.glyphicon-zoom-out:before {
  content: "\E238" !important;
}

.glyphicon-pin:before {
  content: "\E239" !important;
}

.glyphicon-albums:before {
  content: "\E240" !important;
}

.glyphicon-rotation-lock:before {
  content: "\E241" !important;
}

.glyphicon-flash:before {
  content: "\E242" !important;
}

.glyphicon-map-marker:before {
  content: "\E243" !important;
}

.glyphicon-anchor:before {
  content: "\E244" !important;
}

.glyphicon-conversation:before {
  content: "\E245" !important;
}

.glyphicon-chat:before {
  content: "\E246" !important;
}

.glyphicon-note-empty:before {
  content: "\E247" !important;
}

.glyphicon-note:before {
  content: "\E248" !important;
}

.glyphicon-asterisk:before {
  content: "\E249" !important;
}

.glyphicon-divide:before {
  content: "\E250" !important;
}

.glyphicon-snorkel-diving:before {
  content: "\E251" !important;
}

.glyphicon-scuba-diving:before {
  content: "\E252" !important;
}

.glyphicon-oxygen-bottle:before {
  content: "\E253" !important;
}

.glyphicon-fins:before {
  content: "\E254" !important;
}

.glyphicon-fishes:before {
  content: "\E255" !important;
}

.glyphicon-boat:before {
  content: "\E256" !important;
}

.glyphicon-delete:before {
  content: "\E257" !important;
}

.glyphicon-sheriffs-star:before {
  content: "\E258" !important;
}

.glyphicon-qrcode:before {
  content: "\E259" !important;
}

.glyphicon-barcode:before {
  content: "\E260" !important;
}

.glyphicon-pool:before {
  content: "\E261" !important;
}

.glyphicon-buoy:before {
  content: "\E262" !important;
}

.glyphicon-spade:before {
  content: "\E263" !important;
}

.glyphicon-bank:before {
  content: "\E264" !important;
}

.glyphicon-vcard:before {
  content: "\E265" !important;
}

.glyphicon-electrical-plug:before {
  content: "\E266" !important;
}

.glyphicon-flag:before {
  content: "\E267" !important;
}

.glyphicon-credit-card:before {
  content: "\E268" !important;
}

.glyphicon-keyboard-wireless:before {
  content: "\E269" !important;
}

.glyphicon-keyboard-wired:before {
  content: "\E270" !important;
}

.glyphicon-shield:before {
  content: "\E271" !important;
}

.glyphicon-ring:before {
  content: "\E272" !important;
}

.glyphicon-cake:before {
  content: "\E273" !important;
}

.glyphicon-drink:before {
  content: "\E274" !important;
}

.glyphicon-beer:before {
  content: "\E275" !important;
}

.glyphicon-fast-food:before {
  content: "\E276" !important;
}

.glyphicon-cutlery:before {
  content: "\E277" !important;
}

.glyphicon-pizza:before {
  content: "\E278" !important;
}

.glyphicon-birthday-cake:before {
  content: "\E279" !important;
}

.glyphicon-tablet:before {
  content: "\E280" !important;
}

.glyphicon-settings:before {
  content: "\E281" !important;
}

.glyphicon-bullets:before {
  content: "\E282" !important;
}

.glyphicon-cardio:before {
  content: "\E283" !important;
}

.glyphicon-t-shirt:before {
  content: "\E284" !important;
}

.glyphicon-pants:before {
  content: "\E285" !important;
}

.glyphicon-sweater:before {
  content: "\E286" !important;
}

.glyphicon-fabric:before {
  content: "\E287" !important;
}

.glyphicon-leather:before {
  content: "\E288" !important;
}

.glyphicon-scissors:before {
  content: "\E289" !important;
}

.glyphicon-bomb:before {
  content: "\E290" !important;
}

.glyphicon-skull:before {
  content: "\E291" !important;
}

.glyphicon-celebration:before {
  content: "\E292" !important;
}

.glyphicon-tea-kettle:before {
  content: "\E293" !important;
}

.glyphicon-french-press:before {
  content: "\E294" !important;
}

.glyphicon-coffee-cup:before {
  content: "\E295" !important;
}

.glyphicon-pot:before {
  content: "\E296" !important;
}

.glyphicon-grater:before {
  content: "\E297" !important;
}

.glyphicon-kettle:before {
  content: "\E298" !important;
}

.glyphicon-hospital:before {
  content: "\E299" !important;
}

.glyphicon-hospital-h:before {
  content: "\E300" !important;
}

.glyphicon-microphone:before {
  content: "\E301" !important;
}

.glyphicon-webcam:before {
  content: "\E302" !important;
}

.glyphicon-temple-christianity-church:before {
  content: "\E303" !important;
}

.glyphicon-temple-islam:before {
  content: "\E304" !important;
}

.glyphicon-temple-hindu:before {
  content: "\E305" !important;
}

.glyphicon-temple-buddhist:before {
  content: "\E306" !important;
}

.glyphicon-bicycle:before {
  content: "\E307" !important;
}

.glyphicon-life-preserver:before {
  content: "\E308" !important;
}

.glyphicon-share-alt:before {
  content: "\E309" !important;
}

.glyphicon-comments:before {
  content: "\E310" !important;
}

.glyphicon-flower:before {
  content: "\E311" !important;
}

.glyphicon-baseball:before {
  content: "\E312" !important;
}

.glyphicon-rugby:before {
  content: "\E313" !important;
}

.glyphicon-ax:before {
  content: "\E314" !important;
}

.glyphicon-table-tennis:before {
  content: "\E315" !important;
}

.glyphicon-bowling:before {
  content: "\E316" !important;
}

.glyphicon-tree-conifer:before {
  content: "\E317" !important;
}

.glyphicon-tree-deciduous:before {
  content: "\E318" !important;
}

.glyphicon-more-items:before {
  content: "\E319" !important;
}

.glyphicon-sort:before {
  content: "\E320" !important;
}

.glyphicon-filter:before {
  content: "\E321" !important;
}

.glyphicon-gamepad:before {
  content: "\E322" !important;
}

.glyphicon-playing-dices:before {
  content: "\E323" !important;
}

.glyphicon-calculator:before {
  content: "\E324" !important;
}

.glyphicon-tie:before {
  content: "\E325" !important;
}

.glyphicon-wallet:before {
  content: "\E326" !important;
}

.glyphicon-piano:before {
  content: "\E327" !important;
}

.glyphicon-sampler:before {
  content: "\E328" !important;
}

.glyphicon-podium:before {
  content: "\E329" !important;
}

.glyphicon-soccer-ball:before {
  content: "\E330" !important;
}

.glyphicon-blog:before {
  content: "\E331" !important;
}

.glyphicon-dashboard:before {
  content: "\E332" !important;
}

.glyphicon-certificate:before {
  content: "\E333" !important;
}

.glyphicon-bell:before {
  content: "\E334" !important;
}

.glyphicon-candle:before {
  content: "\E335" !important;
}

.glyphicon-pushpin:before {
  content: "\E336" !important;
}

.glyphicon-iphone-shake:before {
  content: "\E337" !important;
}

.glyphicon-pin-flag:before {
  content: "\E338" !important;
}

.glyphicon-turtle:before {
  content: "\E339" !important;
}

.glyphicon-rabbit:before {
  content: "\E340" !important;
}

.glyphicon-globe:before {
  content: "\E341" !important;
}

.glyphicon-briefcase:before {
  content: "\E342" !important;
}

.glyphicon-hdd:before {
  content: "\E343" !important;
}

.glyphicon-thumbs-up:before {
  content: "\E344" !important;
}

.glyphicon-thumbs-down:before {
  content: "\E345" !important;
}

.glyphicon-hand-right:before {
  content: "\E346" !important;
}

.glyphicon-hand-left:before {
  content: "\E347" !important;
}

.glyphicon-hand-up:before {
  content: "\E348" !important;
}

.glyphicon-hand-down:before {
  content: "\E349" !important;
}

.glyphicon-fullscreen:before {
  content: "\E350" !important;
}

.glyphicon-shopping-bag:before {
  content: "\E351" !important;
}

.glyphicon-book-open:before {
  content: "\E352" !important;
}

.glyphicon-nameplate:before {
  content: "\E353" !important;
}

.glyphicon-nameplate-alt:before {
  content: "\E354" !important;
}

.glyphicon-vases:before {
  content: "\E355" !important;
}

.glyphicon-bullhorn:before {
  content: "\E356" !important;
}

.glyphicon-dumbbell:before {
  content: "\E357" !important;
}

.glyphicon-suitcase:before {
  content: "\E358" !important;
}

.glyphicon-file-import:before {
  content: "\E359" !important;
}

.glyphicon-file-export:before {
  content: "\E360" !important;
}

.glyphicon-bug:before {
  content: "\E361" !important;
}

.glyphicon-crown:before {
  content: "\E362" !important;
}

.glyphicon-smoking:before {
  content: "\E363" !important;
}

.glyphicon-cloud-upload:before {
  content: "\E364" !important;
}

.glyphicon-cloud-download:before {
  content: "\E365" !important;
}

.glyphicon-restart:before {
  content: "\E366" !important;
}

.glyphicon-security-camera:before {
  content: "\E367" !important;
}

.glyphicon-expand:before {
  content: "\E368" !important;
}

.glyphicon-collapse:before {
  content: "\E369" !important;
}

.glyphicon-collapse-top:before {
  content: "\E370" !important;
}

.glyphicon-globe-af:before {
  content: "\E371" !important;
}

.glyphicon-global:before {
  content: "\E372" !important;
}

.glyphicon-spray:before {
  content: "\E373" !important;
}

.glyphicon-nails:before {
  content: "\E374" !important;
}

.glyphicon-claw-hammer:before {
  content: "\E375" !important;
}

.glyphicon-classic-hammer:before {
  content: "\E376" !important;
}

.glyphicon-hand-saw:before {
  content: "\E377" !important;
}

.glyphicon-riflescope:before {
  content: "\E378" !important;
}

.glyphicon-electrical-socket-eu:before {
  content: "\E379" !important;
}

.glyphicon-electrical-socket-us:before {
  content: "\E380" !important;
}

.glyphicon-message-forward:before {
  content: "\E381" !important;
}

.glyphicon-coat-hanger:before {
  content: "\E382" !important;
}

.glyphicon-dress:before {
  content: "\E383" !important;
}

.glyphicon-bathrobe:before {
  content: "\E384" !important;
}

.glyphicon-shirt:before {
  content: "\E385" !important;
}

.glyphicon-underwear:before {
  content: "\E386" !important;
}

.glyphicon-log-in:before {
  content: "\E387" !important;
}

.glyphicon-log-out:before {
  content: "\E388" !important;
}

.glyphicon-exit:before {
  content: "\E389" !important;
}

.glyphicon-new-window-alt:before {
  content: "\E390" !important;
}

.glyphicon-video-sd:before {
  content: "\E391" !important;
}

.glyphicon-video-hd:before {
  content: "\E392" !important;
}

.glyphicon-subtitles:before {
  content: "\E393" !important;
}

.glyphicon-sound-stereo:before {
  content: "\E394" !important;
}

.glyphicon-sound-dolby:before {
  content: "\E395" !important;
}

.glyphicon-sound-5-1:before {
  content: "\E396" !important;
}

.glyphicon-sound-6-1:before {
  content: "\E397" !important;
}

.glyphicon-sound-7-1:before {
  content: "\E398" !important;
}

.glyphicon-copyright-mark:before {
  content: "\E399" !important;
}

.glyphicon-registration-mark:before {
  content: "\E400" !important;
}

.glyphicon-radar:before {
  content: "\E401" !important;
}

.glyphicon-skateboard:before {
  content: "\E402" !important;
}

.glyphicon-golf-course:before {
  content: "\E403" !important;
}

.glyphicon-sorting:before {
  content: "\E404" !important;
}

.glyphicon-sort-by-alphabet:before {
  content: "\E405" !important;
}

.glyphicon-sort-by-alphabet-alt:before {
  content: "\E406" !important;
}

.glyphicon-sort-by-order:before {
  content: "\E407" !important;
}

.glyphicon-sort-by-order-alt:before {
  content: "\E408" !important;
}

.glyphicon-sort-by-attributes:before {
  content: "\E409" !important;
}

.glyphicon-sort-by-attributes-alt:before {
  content: "\E410" !important;
}

.glyphicon-compressed:before {
  content: "\E411" !important;
}

.glyphicon-package:before {
  content: "\E412" !important;
}

.glyphicon-cloud-plus:before {
  content: "\E413" !important;
}

.glyphicon-cloud-minus:before {
  content: "\E414" !important;
}

.glyphicon-disk-save:before {
  content: "\E415" !important;
}

.glyphicon-disk-open:before {
  content: "\E416" !important;
}

.glyphicon-disk-saved:before {
  content: "\E417" !important;
}

.glyphicon-disk-remove:before {
  content: "\E418" !important;
}

.glyphicon-disk-import:before {
  content: "\E419" !important;
}

.glyphicon-disk-export:before {
  content: "\E420" !important;
}

.glyphicon-tower:before {
  content: "\E421" !important;
}

.glyphicon-send:before {
  content: "\E422" !important;
}

.glyphicon-git-branch:before {
  content: "\E423" !important;
}

.glyphicon-git-create:before {
  content: "\E424" !important;
}

.glyphicon-git-private:before {
  content: "\E425" !important;
}

.glyphicon-git-delete:before {
  content: "\E426" !important;
}

.glyphicon-git-merge:before {
  content: "\E427" !important;
}

.glyphicon-git-pull-request:before {
  content: "\E428" !important;
}

.glyphicon-git-compare:before {
  content: "\E429" !important;
}

.glyphicon-git-commit:before {
  content: "\E430" !important;
}

.glyphicon-construction-cone:before {
  content: "\E431" !important;
}

.glyphicon-shoe-steps:before {
  content: "\E432" !important;
}

.glyphicon-plus:before {
  content: "\E433" !important;
}

.glyphicon-minus:before {
  content: "\E434" !important;
}

.glyphicon-redo:before {
  content: "\E435" !important;
}

.glyphicon-undo:before {
  content: "\E436" !important;
}

.glyphicon-golf:before {
  content: "\E437" !important;
}

.glyphicon-hockey:before {
  content: "\E438" !important;
}

.glyphicon-pipe:before {
  content: "\E439" !important;
}

.glyphicon-wrench:before {
  content: "\E440" !important;
}

.glyphicon-folder-closed:before {
  content: "\E441" !important;
}

.glyphicon-phone-alt:before {
  content: "\E442" !important;
}

.glyphicon-earphone:before {
  content: "\E443" !important;
}

.glyphicon-floppy-disk:before {
  content: "\E444" !important;
}

.glyphicon-floppy-saved:before {
  content: "\E445" !important;
}

.glyphicon-floppy-remove:before {
  content: "\E446" !important;
}

.glyphicon-floppy-save:before {
  content: "\E447" !important;
}

.glyphicon-floppy-open:before {
  content: "\E448" !important;
}

.glyphicon-translate:before {
  content: "\E449" !important;
}

.glyphicon-fax:before {
  content: "\E450" !important;
}

.glyphicon-factory:before {
  content: "\E451" !important;
}

.glyphicon-shop-window:before {
  content: "\E452" !important;
}

.glyphicon-shop:before {
  content: "\E453" !important;
}

.glyphicon-kiosk:before {
  content: "\E454" !important;
}

.glyphicon-kiosk-wheels:before {
  content: "\E455" !important;
}

.glyphicon-kiosk-light:before {
  content: "\E456" !important;
}

.glyphicon-kiosk-food:before {
  content: "\E457" !important;
}

.glyphicon-transfer:before {
  content: "\E458" !important;
}

.glyphicon-money:before {
  content: "\E459" !important;
}

.glyphicon-header:before {
  content: "\E460" !important;
}

.glyphicon-blacksmith:before {
  content: "\E461" !important;
}

.glyphicon-saw-blade:before {
  content: "\E462" !important;
}

.glyphicon-basketball:before {
  content: "\E463" !important;
}

.glyphicon-server:before {
  content: "\E464" !important;
}

.glyphicon-server-plus:before {
  content: "\E465" !important;
}

.glyphicon-server-minus:before {
  content: "\E466" !important;
}

.glyphicon-server-ban:before {
  content: "\E467" !important;
}

.glyphicon-server-flag:before {
  content: "\E468" !important;
}

.glyphicon-server-lock:before {
  content: "\E469" !important;
}

.glyphicon-server-new:before {
  content: "\E470" !important;
}

.glyphicon-charging-station:before {
  content: "\E471" !important;
}

.glyphicon-gas-station:before {
  content: "\E472" !important;
}

.glyphicon-target:before {
  content: "\E473" !important;
}

.glyphicon-bed:before {
  content: "\E474" !important;
}

.glyphicon-mosquito:before {
  content: "\E475" !important;
}

.glyphicon-dining-set:before {
  content: "\E476" !important;
}

.glyphicon-plate-of-food:before {
  content: "\E477" !important;
}

.glyphicon-hygiene-kit:before {
  content: "\E478" !important;
}

.glyphicon-blackboard:before {
  content: "\E479" !important;
}

.glyphicon-marriage:before {
  content: "\E480" !important;
}

.glyphicon-bucket:before {
  content: "\E481" !important;
}

.glyphicon-none-color-swatch:before {
  content: "\E482" !important;
}

.glyphicon-bring-forward:before {
  content: "\E483" !important;
}

.glyphicon-bring-to-front:before {
  content: "\E484" !important;
}

.glyphicon-send-backward:before {
  content: "\E485" !important;
}

.glyphicon-send-to-back:before {
  content: "\E486" !important;
}

.glyphicon-fit-frame-to-image:before {
  content: "\E487" !important;
}

.glyphicon-fit-image-to-frame:before {
  content: "\E488" !important;
}

.glyphicon-multiple-displays:before {
  content: "\E489" !important;
}

.glyphicon-handshake:before {
  content: "\E490" !important;
}

.glyphicon-child:before {
  content: "\E491" !important;
}

.glyphicon-baby-formula:before {
  content: "\E492" !important;
}

.glyphicon-medicine:before {
  content: "\E493" !important;
}

.glyphicon-atv-vehicle:before {
  content: "\E494" !important;
}

.glyphicon-motorcycle:before {
  content: "\E495" !important;
}

.glyphicon-bed-alt:before {
  content: "\E496" !important;
}

.glyphicon-tent:before {
  content: "\E497" !important;
}

.glyphicon-glasses:before {
  content: "\E498" !important;
}

.glyphicon-sunglasses:before {
  content: "\E499" !important;
}

.glyphicon-family:before {
  content: "\E500" !important;
}

.glyphicon-education:before {
  content: "\E501" !important;
}

.glyphicon-shoes:before {
  content: "\E502" !important;
}

.glyphicon-map:before {
  content: "\E503" !important;
}

.glyphicon-cd:before {
  content: "\E504" !important;
}

.glyphicon-alert:before {
  content: "\E505" !important;
}

.glyphicon-piggy-bank:before {
  content: "\E506" !important;
}

.glyphicon-star-half:before {
  content: "\E507" !important;
}

.glyphicon-cluster:before {
  content: "\E508" !important;
}

.glyphicon-flowchart:before {
  content: "\E509" !important;
}

.glyphicon-commodities:before {
  content: "\E510" !important;
}

.glyphicon-duplicate:before {
  content: "\E511" !important;
}

.glyphicon-copy:before {
  content: "\E512" !important;
}

.glyphicon-paste:before {
  content: "\E513" !important;
}

.glyphicon-bath-bathtub:before {
  content: "\E514" !important;
}

.glyphicon-bath-shower:before {
  content: "\E515" !important;
}

.glyphicon-shower:before {
  content: "\E516" !important;
}

.glyphicon-menu-hamburger:before {
  content: "\E517" !important;
}

.glyphicon-option-vertical:before {
  content: "\E518" !important;
}

.glyphicon-option-horizontal:before {
  content: "\E519" !important;
}

.glyphicon-currency-conversion:before {
  content: "\E520" !important;
}

.glyphicon-user-ban:before {
  content: "\E521" !important;
}

.glyphicon-user-lock:before {
  content: "\E522" !important;
}

.glyphicon-user-flag:before {
  content: "\E523" !important;
}

.glyphicon-user-asterisk:before {
  content: "\E524" !important;
}

.glyphicon-user-alert:before {
  content: "\E525" !important;
}

.glyphicon-user-key:before {
  content: "\E526" !important;
}

.glyphicon-user-conversation:before {
  content: "\E527" !important;
}

.glyphicon-database:before {
  content: "\E528" !important;
}

.glyphicon-database-search:before {
  content: "\E529" !important;
}

.glyphicon-list-alt:before {
  content: "\E530" !important;
}

.glyphicon-hazard-sign:before {
  content: "\E531" !important;
}

.glyphicon-hazard:before {
  content: "\E532" !important;
}

.glyphicon-stop-sign:before {
  content: "\E533" !important;
}

.glyphicon-lab:before {
  content: "\E534" !important;
}

.glyphicon-lab-alt:before {
  content: "\E535" !important;
}

.glyphicon-ice-cream:before {
  content: "\E536" !important;
}

.glyphicon-ice-lolly:before {
  content: "\E537" !important;
}

.glyphicon-ice-lolly-tasted:before {
  content: "\E538" !important;
}

.glyphicon-invoice:before {
  content: "\E539" !important;
}

.glyphicon-cart-tick:before {
  content: "\E540" !important;
}

.glyphicon-hourglass:before {
  content: "\E541" !important;
}

.glyphicon-cat:before {
  content: "\E542" !important;
}

.glyphicon-lamp:before {
  content: "\E543" !important;
}

.glyphicon-scale-classic:before {
  content: "\E544" !important;
}

.glyphicon-eye-plus:before {
  content: "\E545" !important;
}

.glyphicon-eye-minus:before {
  content: "\E546" !important;
}

.glyphicon-quote:before {
  content: "\E547" !important;
}

.glyphicon-bitcoin:before {
  content: "\E548" !important;
}

.glyphicon-yen:before {
  content: "\E549" !important;
}

.glyphicon-ruble:before {
  content: "\E550" !important;
}

.glyphicon-erase:before {
  content: "\E551" !important;
}

.glyphicon-podcast:before {
  content: "\E552" !important;
}

.glyphicon-firework:before {
  content: "\E553" !important;
}

.glyphicon-scale:before {
  content: "\E554" !important;
}

.glyphicon-king:before {
  content: "\E555" !important;
}

.glyphicon-queen:before {
  content: "\E556" !important;
}

.glyphicon-pawn:before {
  content: "\E557" !important;
}

.glyphicon-bishop:before {
  content: "\E558" !important;
}

.glyphicon-knight:before {
  content: "\E559" !important;
}

.glyphicon-mic-mute:before {
  content: "\E560" !important;
}

.glyphicon-voicemail:before {
  content: "\E561" !important;
}

.glyphicon-paragraph-alt:before {
  content: "\E562" !important;
}

.glyphicon-person-walking:before {
  content: "\E563" !important;
}

.glyphicon-person-wheelchair:before {
  content: "\E564" !important;
}

.glyphicon-underground:before {
  content: "\E565" !important;
}

.glyphicon-car-hov:before {
  content: "\E566" !important;
}

.glyphicon-car-rental:before {
  content: "\E567" !important;
}

.glyphicon-transport:before {
  content: "\E568" !important;
}

.glyphicon-taxi:before {
  content: "\E569" !important;
}

.glyphicon-ice-cream-no:before {
  content: "\E570" !important;
}

.glyphicon-uk-rat-u:before {
  content: "\E571" !important;
}

.glyphicon-uk-rat-pg:before {
  content: "\E572" !important;
}

.glyphicon-uk-rat-12a:before {
  content: "\E573" !important;
}

.glyphicon-uk-rat-12:before {
  content: "\E574" !important;
}

.glyphicon-uk-rat-15:before {
  content: "\E575" !important;
}

.glyphicon-uk-rat-18:before {
  content: "\E576" !important;
}

.glyphicon-uk-rat-r18:before {
  content: "\E577" !important;
}

.glyphicon-tv:before {
  content: "\E578" !important;
}

.glyphicon-sms:before {
  content: "\E579" !important;
}

.glyphicon-mms:before {
  content: "\E580" !important;
}

.glyphicon-us-rat-g:before {
  content: "\E581" !important;
}

.glyphicon-us-rat-pg:before {
  content: "\E582" !important;
}

.glyphicon-us-rat-pg-13:before {
  content: "\E583" !important;
}

.glyphicon-us-rat-restricted:before {
  content: "\E584" !important;
}

.glyphicon-us-rat-no-one-17:before {
  content: "\E585" !important;
}

.glyphicon-equalizer:before {
  content: "\E586" !important;
}

.glyphicon-speakers:before {
  content: "\E587" !important;
}

.glyphicon-remote-control:before {
  content: "\E588" !important;
}

.glyphicon-remote-control-tv:before {
  content: "\E589" !important;
}

.glyphicon-shredder:before {
  content: "\E590" !important;
}

.glyphicon-folder-heart:before {
  content: "\E591" !important;
}

.glyphicon-person-running:before {
  content: "\E592" !important;
}

.glyphicon-person:before {
  content: "\E593" !important;
}

.glyphicon-voice:before {
  content: "\E594" !important;
}

.glyphicon-stethoscope:before {
  content: "\E595" !important;
}

.glyphicon-paired:before {
  content: "\E596" !important;
}

.glyphicon-activity:before {
  content: "\E597" !important;
}

.glyphicon-watch:before {
  content: "\E598" !important;
}

.glyphicon-scissors-alt:before {
  content: "\E599" !important;
}

.glyphicon-car-wheel:before {
  content: "\E600" !important;
}

.glyphicon-chevron-up:before {
  content: "\E601" !important;
}

.glyphicon-chevron-down:before {
  content: "\E602" !important;
}

.glyphicon-superscript:before {
  content: "\E603" !important;
}

.glyphicon-subscript:before {
  content: "\E604" !important;
}

.glyphicon-text-size:before {
  content: "\E605" !important;
}

.glyphicon-text-color:before {
  content: "\E606" !important;
}

.glyphicon-text-background:before {
  content: "\E607" !important;
}

.glyphicon-modal-window:before {
  content: "\E608" !important;
}

.glyphicon-newspaper:before {
  content: "\E609" !important;
}

.glyphicon-tractor:before {
  content: "\E610" !important;
}

.glyphicon-camping:before {
  content: "\E611" !important;
}

.glyphicon-camping-benches:before {
  content: "\E612" !important;
}

.glyphicon-forest:before {
  content: "\E613" !important;
}

.glyphicon-park:before {
  content: "\E614" !important;
}

.glyphicon-field:before {
  content: "\E615" !important;
}

.glyphicon-mountains:before {
  content: "\E616" !important;
}

.glyphicon-fees-payments:before {
  content: "\E617" !important;
}

.glyphicon-small-payments:before {
  content: "\E618" !important;
}

.glyphicon-mixed-buildings:before {
  content: "\E619" !important;
}

.glyphicon-industrial-zone:before {
  content: "\E620" !important;
}

.glyphicon-visitor-tag:before {
  content: "\E621" !important;
}

.glyphicon-businessman:before {
  content: "\E622" !important;
}

.glyphicon-meditation:before {
  content: "\E623" !important;
}

.glyphicon-bath:before {
  content: "\E624" !important;
}

.glyphicon-donate:before {
  content: "\E625" !important;
}

.glyphicon-sauna:before {
  content: "\E626" !important;
}

.glyphicon-bedroom-nightstand:before {
  content: "\E627" !important;
}

.glyphicon-bedroom-lamp:before {
  content: "\E628" !important;
}

.glyphicon-doctor:before {
  content: "\E629" !important;
}

.glyphicon-engineering-networks:before {
  content: "\E630" !important;
}

.glyphicon-washing-machine:before {
  content: "\E631" !important;
}

.glyphicon-dryer:before {
  content: "\E632" !important;
}

.glyphicon-dishwasher:before {
  content: "\E633" !important;
}

.glyphicon-luggage-group:before {
  content: "\E634" !important;
}

.glyphicon-teenager:before {
  content: "\E635" !important;
}

.glyphicon-person-stick:before {
  content: "\E636" !important;
}

.glyphicon-person-stick-old:before {
  content: "\E637" !important;
}

.glyphicon-traveler:before {
  content: "\E638" !important;
}

.glyphicon-veteran:before {
  content: "\E639" !important;
}

.glyphicon-group-chat:before {
  content: "\E640" !important;
}

.glyphicon-elections:before {
  content: "\E641" !important;
}

.glyphicon-law-justice:before {
  content: "\E642" !important;
}

.glyphicon-judiciary:before {
  content: "\E643" !important;
}

.glyphicon-house-fire:before {
  content: "\E644" !important;
}

.glyphicon-firefighters:before {
  content: "\E645" !important;
}

.glyphicon-police:before {
  content: "\E646" !important;
}

.glyphicon-ambulance:before {
  content: "\E647" !important;
}

.glyphicon-light-beacon:before {
  content: "\E648" !important;
}

.glyphicon-important-day:before {
  content: "\E649" !important;
}

.glyphicon-bike-park:before {
  content: "\E650" !important;
}

.glyphicon-object-align-top:before {
  content: "\E651" !important;
}

.glyphicon-object-align-bottom:before {
  content: "\E652" !important;
}

.glyphicon-object-align-horizontal:before {
  content: "\E653" !important;
}

.glyphicon-object-align-left:before {
  content: "\E654" !important;
}

.glyphicon-object-align-vertical:before {
  content: "\E655" !important;
}

.glyphicon-object-align-right:before {
  content: "\E656" !important;
}

.glyphicon-reflect-y:before {
  content: "\E657" !important;
}

.glyphicon-reflect-x:before {
  content: "\E658" !important;
}

.glyphicon-tick:before {
  content: "\E659" !important;
}

.glyphicon-lawnmower:before {
  content: "\E660" !important;
}

.glyphicon-call-redirect:before {
  content: "\E661" !important;
}

.glyphicon-call-ip:before {
  content: "\E662" !important;
}

.glyphicon-call-record:before {
  content: "\E663" !important;
}

.glyphicon-call-ringtone:before {
  content: "\E664" !important;
}

.glyphicon-call-traffic:before {
  content: "\E665" !important;
}

.glyphicon-call-hold:before {
  content: "\E666" !important;
}

.glyphicon-call-incoming:before {
  content: "\E667" !important;
}

.glyphicon-call-outgoing:before {
  content: "\E668" !important;
}

.glyphicon-call-video:before {
  content: "\E669" !important;
}

.glyphicon-call-missed:before {
  content: "\E670" !important;
}

.glyphicon-theater:before {
  content: "\E671" !important;
}

.glyphicon-heartbeat:before {
  content: "\E672" !important;
}

.glyphicon-kettlebell:before {
  content: "\E673" !important;
}

.glyphicon-fireplace:before {
  content: "\E674" !important;
}

.glyphicon-street-lights:before {
  content: "\E675" !important;
}

.glyphicon-pedestrian:before {
  content: "\E676" !important;
}

.glyphicon-flood:before {
  content: "\E677" !important;
}

.glyphicon-open-water:before {
  content: "\E678" !important;
}

.glyphicon-for-sale:before {
  content: "\E679" !important;
}

.glyphicon-dustbin:before {
  content: "\E680" !important;
}

.glyphicon-door:before {
  content: "\E681" !important;
}

.glyphicon-camp-fire:before {
  content: "\E682" !important;
}

.glyphicon-fleur-de-lis:before {
  content: "\E683" !important;
}

.glyphicon-temperature-settings:before {
  content: "\E684" !important;
}

.glyphicon-article:before {
  content: "\E685" !important;
}

.glyphicon-sunbath:before {
  content: "\E686" !important;
}

.glyphicon-balanced-diet:before {
  content: "\E687" !important;
}

.glyphicon-ticket:before {
  content: "\E688" !important;
}

.glyphicon-parking-ticket:before {
  content: "\E689" !important;
}

.glyphicon-parking-meter:before {
  content: "\E690" !important;
}

.glyphicon-laptop:before {
  content: "\E691" !important;
}

.glyphicon-tree-structure:before {
  content: "\E692" !important;
}

.glyphicon-weather-warning:before {
  content: "\E693" !important;
}

.glyphicon-temperature-low:before {
  content: "\E694" !important;
}

.glyphicon-temperature-high:before {
  content: "\E695" !important;
}

.glyphicon-temperature-low-warning:before {
  content: "\E696" !important;
}

.glyphicon-temperature-high-warning:before {
  content: "\E697" !important;
}

.glyphicon-hurricane:before {
  content: "\E698" !important;
}

.glyphicon-storm:before {
  content: "\E699" !important;
}

.glyphicon-sorted-waste:before {
  content: "\E700" !important;
}

.glyphicon-ear:before {
  content: "\E701" !important;
}

.glyphicon-ear-deaf:before {
  content: "\E702" !important;
}

.glyphicon-file-plus:before {
  content: "\E703" !important;
}

.glyphicon-file-minus:before {
  content: "\E704" !important;
}

.glyphicon-file-lock:before {
  content: "\E705" !important;
}

.glyphicon-file-cloud:before {
  content: "\E706" !important;
}

.glyphicon-file-cloud-download:before {
  content: "\E707" !important;
}

.glyphicon-file-cloud-upload:before {
  content: "\E708" !important;
}

.glyphicon-paragraph:before {
  content: "\E709" !important;
}

.glyphicon-list-numbered:before {
  content: "\E710" !important;
}

.glyphicon-donate-heart:before {
  content: "\E711" !important;
}

.glyphicon-government:before {
  content: "\E712" !important;
}

.glyphicon-maze:before {
  content: "\E713" !important;
}

.glyphicon-chronicle:before {
  content: "\E714" !important;
}

.glyphicon-user-structure:before {
  content: "\E715" !important;
}

.glyphicon-recycle:before {
  content: "\E716" !important;
}

.glyphicon-gas:before {
  content: "\E717" !important;
}

.glyphicon-waste-pipe:before {
  content: "\E718" !important;
}

.glyphicon-water-pipe:before {
  content: "\E719" !important;
}

.glyphicon-parking:before {
  content: "\E720" !important;
}

.glyphicon-closed:before {
  content: "\E721" !important;
}

.glyphicon-mouse:before {
  content: "\E722" !important;
}

.glyphicon-mouse-double-click:before {
  content: "\E723" !important;
}

.glyphicon-mouse-left-click:before {
  content: "\E724" !important;
}

.glyphicon-mouse-right-click:before {
  content: "\E725" !important;
}

.glyphicon-mouse-middle-click:before {
  content: "\E726" !important;
}

.glyphicon-mouse-scroll:before {
  content: "\E727" !important;
}

.glyphicon-resize-vertical:before {
  content: "\E728" !important;
}

.glyphicon-resize-horizontal:before {
  content: "\E729" !important;
}

.glyphicon-temperature:before {
  content: "\E730" !important;
}

.glyphicon-puzzle:before {
  content: "\E731" !important;
}

.glyphicon-puzzle-2:before {
  content: "\E732" !important;
}

.glyphicon-puzzle-3:before {
  content: "\E733" !important;
}

.glyphicon-nearby-square:before {
  content: "\E734" !important;
}

.glyphicon-nearby-circle:before {
  content: "\E735" !important;
}

.glyphicon-rotate-right:before {
  content: "\E736" !important;
}

.glyphicon-rotate-left:before {
  content: "\E737" !important;
}

.glyphicon-pictures:before {
  content: "\E738" !important;
}

.glyphicon-photo-album:before {
  content: "\E739" !important;
}

.glyphicon-cadastral-map:before {
  content: "\E740" !important;
}

.glyphicon-fingerprint-scan:before {
  content: "\E741" !important;
}

.glyphicon-fingerprint:before {
  content: "\E742" !important;
}

.glyphicon-fingerprint-lock:before {
  content: "\E743" !important;
}

.glyphicon-fingerprint-ok:before {
  content: "\E744" !important;
}

.glyphicon-fingerprint-remove:before {
  content: "\E745" !important;
}

.glyphicon-fingerprint-reload:before {
  content: "\E746" !important;
}

.glyphicon-pending-notifications:before {
  content: "\E747" !important;
}

.glyphicon-synchronization:before {
  content: "\E748" !important;
}

.glyphicon-synchronization-ban:before {
  content: "\E749" !important;
}

.glyphicon-hash:before {
  content: "\E750" !important;
}

.glyphicon-gender-male:before {
  content: "\E751" !important;
}

.glyphicon-gender-female:before {
  content: "\E752" !important;
}

.glyphicon-gender-virgin-female:before {
  content: "\E753" !important;
}

.glyphicon-gender-intersex:before {
  content: "\E754" !important;
}

.glyphicon-gender-transgender:before {
  content: "\E755" !important;
}

.glyphicon-gender-ori-lesbian:before {
  content: "\E756" !important;
}

.glyphicon-gender-ori-gay:before {
  content: "\E757" !important;
}

.glyphicon-gender-ori-hetero:before {
  content: "\E758" !important;
}

.glyphicon-gender-other:before {
  content: "\E759" !important;
}

.glyphicon-gender-unknown:before {
  content: "\E760" !important;
}

.glyphicon-scanner:before {
  content: "\E761" !important;
}

.glyphicon-multifunction-printer:before {
  content: "\E762" !important;
}

.glyphicon-lasso:before {
  content: "\E763" !important;
}

.glyphicon-view-360:before {
  content: "\E764" !important;
}

.glyphicon-battery-charging:before {
  content: "\E765" !important;
}

.glyphicon-battery-full:before {
  content: "\E766" !important;
}

.glyphicon-battery-75:before {
  content: "\E767" !important;
}

.glyphicon-battery-50:before {
  content: "\E768" !important;
}

.glyphicon-battery-25:before {
  content: "\E769" !important;
}

.glyphicon-battery-10:before {
  content: "\E770" !important;
}

.glyphicon-satellite:before {
  content: "\E771" !important;
}

.glyphicon-satellite-dish:before {
  content: "\E772" !important;
}

.glyphicon-satellite-dish-alt:before {
  content: "\E773" !important;
}

.glyphicon-auction:before {
  content: "\E774" !important;
}

.glyphicon-directions:before {
  content: "\E775" !important;
}

.glyphicon-race:before {
  content: "\E776" !important;
}

.glyphicon-robot:before {
  content: "\E777" !important;
}

.glyphicon-ruler-alt:before {
  content: "\E778" !important;
}

.glyphicon-cube-empty:before {
  content: "\E779" !important;
}

.glyphicon-cube-black:before {
  content: "\E780" !important;
}

.glyphicon-move-square:before {
  content: "\E781" !important;
}

.glyphicon-drop:before {
  content: "\E782" !important;
}

.glyphicon-vr-headset:before {
  content: "\E783" !important;
}

.glyphicon-vr-charging:before {
  content: "\E784" !important;
}

.glyphicon-vr-low-battery:before {
  content: "\E785" !important;
}

.glyphicon-vr-paired:before {
  content: "\E786" !important;
}

.glyphicon-vr-settings:before {
  content: "\E787" !important;
}

.glyphicon-vr-maintenance:before {
  content: "\E788" !important;
}

.glyphicon-filter-remove:before {
  content: "\E789" !important;
}

.glyphicon-filter-applied:before {
  content: "\E790" !important;
}

.glyphicon-one-day:before {
  content: "\E791" !important;
}

.glyphicon-user-vr:before {
  content: "\E792" !important;
}

.glyphicon-user-vr-add:before {
  content: "\E793" !important;
}

.glyphicon-user-vr-remove:before {
  content: "\E794" !important;
}

.glyphicon-dice-1:before {
  content: "\E795" !important;
}

.glyphicon-dice-2:before {
  content: "\E796" !important;
}

.glyphicon-dice-3:before {
  content: "\E797" !important;
}

.glyphicon-dice-4:before {
  content: "\E798" !important;
}

.glyphicon-dice-5:before {
  content: "\E799" !important;
}

.glyphicon-dice-6:before {
  content: "\E800" !important;
}