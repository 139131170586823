@import "colors";

.driver-groups-panel {

  .role {
    margin-right: 2px;
  }

  .delete-driver-group-icon {
    color: @graybluelabel;
  }

  .delete-driver-group-icon:hover {
    color: @watermelon;
  }

  .driver-group-search {
    width: 50%;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .hover-to-show:hover .hover-content {
    visibility: visible;
    transition: none;
  }

  .hover-content {
    visibility: hidden;
    transition: none;
  }

  .darker-hover:hover {
    background-color: darken(@lightgray, 10%);
  }

  .lighter-background {
    background-color: darken(@lightgray, 4%);
  }

}

.driver-group-modal {

  .label {
    margin-right: 2px;

    sub {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-top: 5px;
      height: 15px;
      max-width: 100px;
      white-space: nowrap;
      float: left;
    }
  }

  .scrollable-modal-container > div, .scrollable-label-container {
    max-height: 120px;
    overflow-y: auto;
  }

  .block-label {
    display: inline-block;
  }

  .editable-wrap {
    display: inline-block;
    width: 100%;
    max-height: 120px;
  }

  .editable-input > div {
    max-height: 118px;
    overflow-y: auto;
    position: relative;
    bottom: 3px;
  }

  .matches-container {
    display: inline-block;
    width: 100%;
    white-space: normal;

    sub {
      text-overflow: ellipsis;
      overflow: hidden;
      padding-top: 5px;
      height: 15px;
      max-width: 100px;
      white-space: nowrap;
      float: left;
    }
  }

  .matches-container > span {
    display: inline-block;
  }
}

