table.sortable {
  // Add .sortable to a table to add sort arrows.
  // Add .up or .down to the span of a th to change the direction of the arrows.

  th {

    padding: 8px 4px;

    > span {
      cursor: pointer;
      position: relative;
      margin-right: 15px;
      display: inline-block;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      // Normally, display both sort arrows
      &:before, &:after {
        border: 4px solid transparent;
        content: "";
        display: inline-block;
        height: 0;
        right: -15px;
        top: 50%;
        position: absolute;
        width: 0;
      }
      &:before {
        border-bottom-color: #666;
        margin-top: -9px;
      }
      &:after {
        border-top-color: #666;
        margin-top: 1px;
      }

      // In the presence of .up or .down classes, hide one of the sort arrows and tweak the other
      &.down {
        &:before {
          content: none;
        }
        &:after {
          margin-top: -2px
        }
      }
      &.up {
        &:before {
          margin-top: -6px
        }
        &:after {
          content: none;
        }
      }
    }
  }
  root {
    width: 100%;
    overflowX: "auto";
  }
  paper {
  }
  fileTable {
    minWidth: 650
  }
}
