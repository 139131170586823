.segmented-control {

  &.pagination {
    margin-top: 0;
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
  }

}