@good-light-color: #80E27E;
@good-main-color: #4CAF50;
@good-dark-color: #087F23;

@neutral-light-color: #E0E0E0;
@neutral-main-color: #9E9E9E;
@neutral-dark-color: #424242;

@bad-light-color: #FF7961;
@bad-main-color: #F44336;
@bad-dark-color: #BA000D;

@background-color-default: #FFF;
@text-primary-color: black;

@delivery-success-main: #00B155;
@delivery-failed-main: #ED4957;
@delivery-visit_later-main: #F7AF00;
@delivery-mixed-main: #00B155;
@delivery-authorize_reattempt-main: #718290;

@primary-main: #060526;
@primary-dark: #002984;

@icon-color-main: #0000008a;



.logoLink {
  display: flex;
  align-items: center;
  margin-right: 16px;
}
.grow {
  flex: 1;
  white-space: nowrap;
}
.undecoratedLink {
  text-decoration: none !important;
  color: #1976d2;
}
.topLevelItemButton {
  margin-left: 8px !important;
}
.settingTab {
  min-width: 160px !important;
  text-decoration: none !important;
}
.dcSettingsTimePicker{
  & > div {
    width: -webkit-fill-available;
  }
}
.accountButton {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.87) !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}
.accountButtonInfo {
  display: flex;
  flex-direction: column;
  text-align: right;
  max-width: 250px;
  overflow-x: hidden;
}
.accountButtonText {
  line-height: 1.25 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dcSettingsHeader {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-family: "Simplon";
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}
.accountButtonIcon {
  margin-left: 8px;
}
.menuItem {
  overflow: visible;
  color: rgba(0, 0, 0, 0.87) !important;
}
.menuItem:hover {
  text-decoration: none;
}
.menuBadge {
  padding-right: 8px;
  color: black;
}
.superscript {
  text-transform: uppercase;
}
.sidebarList {
  min-width: 350px;
}
.stopList {
  padding-top: 8px;
  padding-bottom: 8px;
  overflow-y: auto;
  background-color: #F4F4F4;
}
.stopListHeader {
  padding-left: 8px;
  margin-top: 8px;
  background-color: #e0e0e0;
}
.chip {
  margin-left: 8px;
}
.multiLineListItem {
  flex: 1 1 auto;
  flex-wrap: wrap;
  min-width: 0;
  align-items: center;
  justify-content: flex-start;
}
.multiLineText {
  width: 100%;
}
.contentRoot {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.body {
  flex: 1;
  overflow-y: auto;
}
.buttonWrapper {
  margin-top: 16px;
  margin-bottom: 16px;
}
.pickerWrapper {
  margin: 32px 0;
}
.progressWrapper {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.chipWrapper {
  display: flex;
  justify-content: center;
}
.drawer {
  margin-bottom: 100px;
  width: 100%;
  max-width: 500px;
}
.drawerHeading {
  margin-bottom: 15px;
}
.faq {
  margin-top: 25px;
}
.appBar {
  position: relative;
}
.flex {
  flex: 1;
}
.fileTabRoot {
  margin-top: 24px;
  margin-bottom: 80px;
}
.wrapper {
  margin-bottom: 32px;
}
.heading {
  margin: 32px 0;
}
.buttonContainer {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;
}
.infoIcon {
  text-align: right;
}
.columnDefLabel {
  padding-right: 8px;
  color: rgba(0, 0, 0, 0.87);
}

.alerts-container {
  align-self: stretch;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .alert {
    border: 1px solid transparent;
    border-radius: 4px;
    padding-left: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
  .alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
  }
  .alert-warning {
  }
}

.pickerRoot {
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 24px;
  padding-right: 24px;
}
.monthLabel {
  margin-bottom: 8px !important;
  margin-top: 8px !important;
  display: flex;
  justify-content: center;
}
.calendarHeading {
  display: flex;
  justify-content: space-between;
}
.weekWrapper {
  display: flex;
  justify-content: center;
  font-size: .75rem;
}
.day {
  width: 36px;
  height: 36px;
  color: inherit !important;
  display: flex;
  flex-direction: column;
  font-size: .75rem !important;
}
.dayOfWeek {
  text-align: center;
}
.nonCurrentMonthDay {
  color: rgba(0, 0, 0, 0.38) !important;
}
.dayIsOutsideOfRange {
}
.highlightNonCurrentMonthDay {
  color: #676767;
}
.selectedPeriodWrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 2px;
}
.inSelectedPeriod {
  color: white;
  background-color: #3f51b5;
}
.firstInSelectedPeriod {
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}
.lastInSelectedPeriod {
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}
.routeListDateHeader {
  padding: 24px;
  background-color: #3f51b5;
  color: white;
}
.titleText {
}
.dateLabelText {
  font-size: 1.25rem;
  font-weight: 600;
}
.doubleToggleContainer {
  min-width: max-content
}
.paper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 32px 32px;
}
.caption {
  line-height: 1.2;
  text-shadow: 2px 2px 0 white -1px -1px 0 white 1px -1px 0 white  -1px  1px 0 white 1px  1px 0 white;
}
.textField {
  margin-left: 8px;
  margin-right: 8px;
  width: 350px;
}
.fullWidth {
  flex: 1;
}
.internalRouteRoot {
  position: absolute;
  width: 400px;
  top: 75px;
  left: 8px;
  z-index: 1000
}
.routeAnalyzerRoot {
  position: absolute;
  top: 75px;
  left: 8px;
  z-index: 1000;
}
.internalRoutePaper {
  padding-top: 16px;
  padding-bottom: 16px;
}
.routeAnalyzerPaper {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
}
.loginAsPaper {
  margin: 32px 0px;
  margin-top: 16px;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;
}
.titleBadge {
  padding-right: 16px;
}
.routeImageViewerTable {
  margin-top: 15px;
}
.finalizedCell {
  display: flex;
  justify-content: space-evenly;
}
.numberIconCell {
  display: inline-block;
  width: 100%;
}
.numberIconCellCount {
  float: left;
}
.numberIconEnabled {
  float: right;
}
.numberIconDisabled {
  float: right;
  color: grey;
}
.updateRouteCaption {
  text-align: center
}
.routeConflictText {
  color: #d32f2f;
}
.dialogContent {
  padding: 0;
}
.analyticsTypes {
  .multiselectRoot {
    flex-grow: 1;
    .MuiInputBase-input {
      display:flex;
      justify-content: space-between;
      & > div {
        display: flex;
        position: relative;
        & > div {
          position: relative;
        }
      }
    }
  }
}

.multiselectRoot {
  flex-grow: 1;
  .multiselect-input {
    display: flex;
    max-height: 33vh;
    .react-select__control {
      flex: 1 1 auto;
      display: flex;
      align-items: stretch;
      flex-wrap: nowrap;
      & > div:first-child {
        overflow: auto;
      }
    }
  }
  .MuiChip-root {
    margin: 5px 0;
  }
}
.MuiChip-label {
  font-weight: 400;
}
.input {
  display: flex;
  padding: 0;
  height: 100%;
}
.valueContainer {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  overflow: hidden;
}
.multiselectChip {
  margin: 4px 2px;
}
.chipFocused {
}
.noOptionsMessage {
  padding: 8px 16px;
}
.singleValue {
  font-size: 16px;
}
.placeholder {
  position: absolute;
  font-size: 16px;
}
.multislectPaper {
  position: absolute;
  z-index: 1;
  margin-top: 8px;
  left: 0;
  right: 0;
}
.divider {
  height: 16px;
}
.orderWrapper {
  z-index: 10;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #eeeeee !important;
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  margin-left: 8px;
  margin-right: 8px;
}
.orderBody {
  max-height: 90%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.headerSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
}
.routeAndDriver {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow-y: auto;
}
.section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.orderPaper {
  margin: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.routeCard {
  flex: 1;
  margin: 8px;
}
.driverCard {
  flex: 1;
  margin: 8px;
}
.orderStatus {
  margin: 8px;
}
.orderStatusSection {}
.deliveryInfo {
  max-height: 100%;
  overflow: hidden;
}
.dialogContent {
  padding: 8px;
  margin: 8px;
}
.lineContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lineItem {
  margin-left: 8px;
  margin-right: 8px;
}
.mapWrapper {
  margin-top: 32px;
  margin-bottom: 32px;
}
.mapPaper {
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button {
  margin: 8px;
}
.leftIcon{
  margin-right: 8px;
}
.rightIcon {
  margin-left: 8px;
}
.mapInformation {
  background: #f0f0f0;
  width: 100%;
  padding-top:35px;
  padding-bottom:50px;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.extraPadding {
  padding-top: 35px;
  padding-bottom: 35px;
}
.form {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  .chip {
    background: #36648B;
    margin-bottom: 0;
    line-height: 15px
  }
}
.column-assignments {
  background:#f0f0f0;
  padding-top:35px;
  padding-bottom:50px;
  display: grid;
  grid: auto-flow / 1fr 3fr;
  & > div {
    margin-right: 16px;
  }
}
.importAssignments {
  padding-top:35px;
  padding-bottom:35px;
}
.columnLabelText{
  margin-bottom:15px;
  font-size: 18px;
  width:100%;
  font-weight: 600;
  letter-spacing: 1px;
}
.fullWidth{
  width:100%;
  margin-top: 16px;
}
@single-column-max-width: 800px;
.column-assignments-container {
  display: grid;
  @media only screen and (max-width: @single-column-max-width) {
    grid: auto-flow / 1fr;  
  }
  grid: auto-flow / 1fr 1fr;
  grid-gap: 15px 10px;
  margin-top: 40px;
  & .columnLabelText {
    @media only screen and (max-width: @single-column-max-width) {
      grid-column: span 1;
    }
    grid-column: span 2;
    text-align: center;
  }
}
.column-assignment-input-container {
  display: flex;
  padding-top: 10px;
  & > div {
    display: flex;
    flex: 1 1 auto;
  }
}
.multiselect-container {
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  border-radius: 0 4px 4px 0!important;

  & .searchWrapper {
    border-radius: 0 4px 4px 0 !important;
    background-color:white;
    flex: 1 1 auto;
    & input {
      width: 100%;
    }
  }
  @error-font-color: #a94442;
  &.error .searchWrapper{
    background-color: #f2dede;
    border-color: @error-font-color;
    color: @error-font-color;
    input::-webkit-input-placeholder {
      color: @error-font-color;
    }

    & > input {
      width: 100% !important;
    }

    
    input::-moz-placeholder {
        color: @error-font-color;
    }
    
    input::-ms-placeholder {
        color: @error-font-color;
    }
    
    input::placeholder {
        color: @error-font-color;
    }
  }
  & .optionListContainer {
    top: 100%;
  }
}
.headerText{
  //margin-top:35px !important;
  font-size: 24px;
  //margin-left:15px;
  padding-left: 15px;
  font-weight: normal !important;
  letter-spacing: 1px;
}
.zindex1 {
  z-index: 1;
}
.fullWidthCenter{
  width:100%;
  margin-top: 16px;
  padding-right: 16px;
  text-align: center;
  padding-top: 10px;
}
.chips{
  background:#36648B !important;
}
.chip{
  background: #36648B !important;
}
.columnLabel{
  padding: 12px 12px;
  font-size: 14px;
  line-height: 1;
  color: #555;
  background-color: #eee;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  &.error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #a94442;
  }
}
.gridContainer{
  display:flex;
  &>div {
    flex: 1 1 auto;
  }
}
.loginButton {
  background-color: #060525 !important;
}
.loginButton:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26);
}
.MuiSwitch-switchBase.Mui-checked {
  color: #060525 !important;
}
.switch {
  width: 50px;
  .MuiSwitch-thumb{
    color: inherit;
  }
  .MuiSwitch-track {
    background-color:#36648B!important;
    .checked {
      // Controls checked color for the track
      opacity: 0.7;
      background-color: #36648B
    }
  }
}

.routeUpdateCaption {
  text-align: center
}
.routeConflictText {
  color: red
}
.dialogContent {
  padding: 0
}
.tabs {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  & .MuiTabs-flexContainer .Mui-selected {
    color: black !important;
  }
}

.userRouteDialogContent {
  padding: 8px !important;
  margin: 8px;
}
.passwordRoot {
  display: flex;
  justify-content: center;
}
.btn-success {
  color: #fff !important;
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;
}
.passwordPaper {
  margin-top: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center
}
.form {
  width: 100%
}
.passwordStrength {
  margin: 16px 0;
}
.forgotPassword {
  margin-top: 16px
}
.timestamp { padding: 0 16px }
.deliveryListColumnHeaders {
  display: flex;
  justify-content: space-between;
  padding: 8px 24px 8px 56px
}
.visitsTitle { padding-left: 16px }
.noVisitsTitle{
  text-align: center;
  padding-bottom: 8px
}

.notFoundPageWrapper {
  margin-top: 32px;
  margin-bottom: 32px;
}



.loginPageRoot {
  display: flex;
  justify-content: center;

  .paper {
    margin-top: 16px;
    padding-top: 32px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form {
    width: 100%;
  }

  .forgotPassword {
    margin-top: 16px;
  }
}

.driverTraceBreakdownRoot {
  .topBar {
    height: 2px;
  }
  .passed {
    background-color: @good-main-color;
  }
  .failed {
    background-color: @bad-main-color;
  }
  .unknown {
    background-color: @neutral-main-color;
  }
  .mainContent {
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }
  .textHolder {
    flex: 1;
    padding: 8px;
  }
}

.driverTraceBreakdownWidgetRoot {
  margin-bottom: 16px;
  .mainCard {
    padding: 16px;
    height: 240px;
    background-color: @background-color-default;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.expansionPanelRoot {
  margin-bottom: 16px;

  .expansionHeader {
    width: 100%;
    display: flex;
    padding: 16px;
    min-height: 80px;
  }

  .titleWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }

  .expand {
    transform: rotate(0deg);
    margin-left: auto;
    max-width: 24px;
    //transition: rotate 150;
  }




  .expandOpen {
    rotate: 180deg
  }
}



.resetPasswordPageRoot {
  .paper {
    margin-top: 16px;
    padding-top: 32px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form {
    width: 100%;
  }

  .passwordStrength {
    margin: 16px 0;
  }

  .forgotPassword {
    margin-top: 16px;
  }
}

.driverCardRoot {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  margin-left: 8px;
  margin-right: 8px;

  .content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center
  }

  .driverIcon {
    height: 100%;
    padding: 16px;
    //padding-right: 32px;
  }

  .driverInfo {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .driverStatus {}
}

.RouteCardRoot {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  margin-left: 8px;
  margin-right: 8px;
  .topContent {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .mapIcon {
    height: 100%;
    padding: 16px;
    //padding-right: 32px;
  }
  .routeInfo {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .linkSection {
    margin-left: 16px;
    margin-right: 16px;
  }
  .routeButtonLink {
    text-decoration: none;
  }
}

.overallHelpDialog {
  height: 80vh;
  .stepper {
    margin: 8px;
  }
  .dialogContent {
    display: flex;
    flex-direction: column;
  }
  .fillContent {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .feedbackBlock{
    order: 1;
    margin-top: 64px;
  }
  .detailsWrapper{
    display: flex;
    flex-direction: column;
    flex: 1
  }
  .detailsContent{
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .imgContainer{
    flex: 1;
    height: 100%;
    position: relative;
  }
  .dcmImage{
    height: 0;
    min-height: 100%;
  }
}

.orderDetailsMobileRoot {
  z-index: 10;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  //backgroundColor: theme.palette.grey[200];
  width: 100%;
  height: 45%;
  .orderBody{};
  &.headerSection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .paper{
    margin: 8px;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .routeCard{
    margin: 8px;
  }
  .driverCard{
    margin: 8px;
  }
  .waypointTabsCard{
    margin: 8px;
  }
  .deliveryInfo{
    max-height: 100%;
    overflow: hidden;
  }
}

.completedPanelRoot {
  .orderStatusWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin: 8px;
  }
  .completedTimeWrapper{
    margin-left: 8px;
    display: flex;
    flex-direction: column;
  }
}

.MuiTypography-alignLeft {
  text-align: left !important;
}

.defaultPanelRoot {
  .contentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin: 8px;
  }
  .orderStatusIcon {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    background-color: red;
    border-radius: 50%;
  }
  .orderStatusIconOnline{
    //backgroundColor: theme.palette.visit.active.main;
  }
  .orderStatusIconOffline{
    //backgroundColor: theme.palette.bad.main;
  }
}

.etaSpanRoot {
  display: flex;
  flex-direction: row;
  //align-items: left;
  justify-content: left;
  margin-left: 8px;
  flex-wrap: wrap;
  .etaStackedLabelContainer {
    flex-grow: 1;
    text-align: left;
    width: 100%;
  }
  .timeColor{
    color: grey;
  }
  .labelColor{
    color: grey;
  }
}

.noAssignedDriverPanelRoot .notActivePanelRoot {
  .contentWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin: 8px;
  }
}

.readyToStartPanelRoot {
  .orderStatusWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 8px;
  }
  .orderStatusIcon {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    background-color: red;
    border-radius: 50%;
  }
  .orderStatusIconOnline{
    //backgroundColor: theme.palette.visit.active.main
  }
  .orderStatusIconOffline{
    //backgroundColor: theme.palette.bad.main
  }
}

.routeStartedPanelRoot {
  .driverStatusContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-left: 8px;
    margin-top: 8px;
  }
  .contentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-left: 8px;
  }
  .stopsAheadContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-left: 8px;
    margin-top: 8px;
  }
  .orderStatusIcon{
    width: 15px;
    height: 15px;
    margin-right: 8px;
    background-color: red;
    border-radius: 50%;
  }
  .orderStatusIconOnline{
    //backgroundColor: theme.palette.visit.active.main
  }
  .orderStatusIconOffline{
    //backgroundColor: theme.palette.bad.main
  }
}

.statusChipRoot {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 16px;
  margin-top: 8px;

  .chipShape {
    border-radius: 4px;
    height: 32px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .chipLabel {
    display: flex;
    font-weight: bold;
  }
  .syncProblemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .syncProblemIcon {
    padding-left: 8px;
    padding-right: 8px;
  }
  .badChip {
    background-color: #f44336;
    color: white;
  }
  .goodChip {
    background-color: #4caf50;
    color: white;
  }
  .warningChip {
    //backgroundColor: theme.palette.warning.main;
    color: white;
  }
}

.orderListCardRoot {
  display: flex;
  flex-direction: row;
  margin: 0 16px 16px;
  .leftSide {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    box-shadow: 13px 0px 1px -10px #eeeeee;
    margin-right: 8px;
  }
  .rightSide {
    width: 40%;
  }
  .labelWithIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 40px);
  }
  .icon {
    font-size: 42px;
    padding: 8px;
    color: grey;
  }
  .label {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
  }
  .orderStatusWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin: 8px;
  }
  .etaWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 8px;
    flex-wrap: wrap;
  }
  .etaStackedLabelContainer{
    flex-grow: 12;
    text-align: center;
    width: 100%;
  }
  .orderStatusIcon{
    width: 15px;
    height: 15px;
    margin-right: 8px;
    background-color: red;
    border-radius: 50%;
  }
  .orderStatusIconOnline{
    background-color: #56E9C8;
  }
  .orderStatusIconOffline{
    background-color: #f44336;
  }
}

.searchBoxRoot {
  margin: 32px 16px;
  .searchInputWrapper{
    padding: 4px 8px 4px 16px;
    display: flex;
  }
  .searchInput{
    flex: 1;
    display: flex;
    align-items: center;
  }
  .iconButton{
    padding: 10px;
  }
  .buttonDividerWrapper{
    display: flex;
    align-items: center;
    padding: 4px;
  }
  .buttonDivider{
    height: 24px;
  }
  .backLink{
    cursor: pointer;
    display: block;
    padding: 8px 16px;
  }
}

.orderListRouteName {
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchResultListRoot {
  overflow-y: auto;
  // max-height: 100vh;
  .searchResultListItem{
    overflow-x: hidden;
    display: list-item;
    padding: 8px 32px;
    &:hover{
      background-color: #eeeeee;
      cursor: pointer;
    }
  }
  .loadingListItem{
    display: flex;
    justify-content: center;
  }
  .dcFacilityInfo{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dcFacilityName{
    flex: 1;
  }
  .dcFacilityId{
    margin-left: 8px;
    justify-content: flex-end;
  }
  .dcFacilityValue{
    margin: 0 8px 0 8px;
  }
  .locationIcon{
    margin-left: -3px;
    margin-right: 3px;
    //color: theme.palette.grey[600]
  }
  .addressInfo{
    display: flex;
    align-items: center;
  }
  .noSearchResults{
    margin: 0 32px;
  }
}

.singleCustomerInfoRoot {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
  //[theme.breakpoints.down(sm)]{
  //  overflow-y: auto
  //}
  .backLink{
    margin: 0 16px 16px;
  }
  .dcInfo{
    display: flex;
    margin: 8px 16px 8px 16px;
  }
  .dcExtId{
    flex: 1;
  }
  .dcName{
    flex: 1;
  }
  .pocValue{
    margin: 0 0 0 16px;
  }
  .customerInfo{
    margin: 16px 16px;
  }
  .customerId{}
  .locationIcon{
    margin-left: -3px;
    margin-right: 3px;
  }
  .addressInfo{
    display: flex;
    align-items: center;
  }
  .orderHistory{
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: hidden;
  }
  .orderHistoryLabelContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .orders{
    flex: 1;
    overflow-y: auto;
    //[theme.breakpoints.down(sm)]{
    //  overflow-y: visible
    //}
  }
  .orderHistoryLabel{
    margin: 16px;
    //margin-right: 8px;
  }
  .spinnerContainer{
    text-align: center;
  }
  .noOrdersText{
    margin: 16px;
  }
}

.dcmHeight {
  max-height: calc(100vh - 64px);
}
.digitalCustomerManagerRoot {
  height: 100vh;
  .sticky{
    z-index: 2;
  }
  .fullscreenContent{
    flex: 1 1 auto;
    justify-content: space-between;
    display: flex;
    max-width: 100vw;
  }
  .map-container {
    flex: 1 1 auto;
    & > div {
      width: 100%;
      height: 100%;
    }
  }
  .helpButton{
    position: absolute;
    right: 0;
    padding: 10px;
  }
  .dcm-sidebar{
    width: 480px;
    //[theme.breakpoints.down(sm)]{
    //  width: 100%
    //}
    z-index: 1;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    position: absolute;
    & > ul {
      flex: 1 1 10px;
    }
  }
  .sidebarBackground{
    background-color: #eeeeee;
    position: unset;
  }
  .sidebarMaxHeight{
    height: 100%;
  }
}

.dcSettingPageStep {
  font-size: 0.875rem !important;
  margin-top: 1em;
}

.circularChartRoot {
  display: flex;
  height: 240px;
  position: relative;
  backgroundColor: #3f51b5;
  .circularChartLabelContainer{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .circularChartContainer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .circularChartLabel{
    font-size: 2.125rem;
    font-weight: 400;
  }
  .circularChart{
    display: flex;
    align-items: center;
    max-width: 80%;
    height: 80%;
  }
}

.routeNameAndLinkFormatterRoot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.goodValueFormatter {
  color: @text-primary-color;
}

.badValueFormatter {
  color: @bad-main-color;
}

.neutralValueFormatter {
  color: @neutral-main-color;
}

.successfulVisitStatusFormatter {
  color: @delivery-success-main;
}

.failedVisitStatusFormatter {
  color: @delivery-failed-main;
}

.visitLaterVisitStatusFormatter {
  color: @delivery-visit_later-main;
}

.mixedVisitStatusFormatter {
  color: @delivery-mixed-main;
}

.authorizeReattemptVisitStatusFormatter {
  color: @delivery-authorize_reattempt-main;
}

.detailModalHeaderRoot {
  margin-bottom: 16px;
}

.detailModalRoot {
  .appBar{
    position: relative;
  }
  .flex{
    flex: 1;
  }
  .contentRoot{
    margin-top: 24px;
    margin-bottom: 80px;
  }
}

.driverPerformanceTableRoot {
  .rowRoot {
    cursor: pointer;
    &:hover{
      //backgroundColor: theme.palette.action.hover
    }
  }
}

.driverPerformancePageRoot {
  .tableContainer{
    //[theme.breakpoints.down(sm)]{
    //  overflow-x: auto
    //}
    margin-bottom: 80px;
  }

  .alertContainer{
    background-color: #fcf8e3;
    border: 1px solid transparent;
    border-radius: 4px;
    //border-color: #faebcc;
    padding: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

.kpiDefinitionDrawerRoot {
  margin-bottom: 100px;
  width: 100%;
  max-width: 400px;
  .drawerHeading{
    margin-bottom: 8px;
  }
}

.kpiDefinitionExampleCellRoot {
  display: flex;
  justify-content: center;
  .outerCell{
    display: flex;
    border-style: solid;
    border-width: 1px;
    width: 50%;
    align-items: center;
    justify-content: center;
  }
  .innerCell{
    display: flex;
    flex-direction: column;
  }
  .value{
    font-weight: bold;
  }
  .historicalValue{
    font-weight: 200;
  }
  .change{
    padding-left: 15px;
  }
}

@dayButtonSize: 30; // size of each day button
@verticalSpaceBetweenWeeks: 2; // size between stripes of the comparison period
@comparisonPeriodPadding: 3; // size between comparison period and selected period

.dailyCalendarRoot {
  display: flex;
  margin-top: 8px;
  align-items: flex-start;
  padding-left: 100px;
  padding-right: 100px;
  justify-content: space-between;
  .monthWrapper{}
  .monthLabel{
    margin-bottom: 8px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
  }
  .calendarHeading{
    display: flex;
    justify-content: space-between;
  }
  .weekWrapper{
    display: flex;
    justify-content: center;
  }
  .day{
    width: 30px;
    height: 30px;
    font-size: .75rem;
    color: inherit;
    display: flex;
    flex-direction: column;
  }
  .dayOfWeek{
    text-align: center;
    margin-left: 3px;
    margin-right: 3px;
    //font-weight: theme.typography.font-weightLight
    color: grey;
  }
  .nonCurrentMonthDay{
    color: grey;
  }
  .dayIsOutsideOfRange{
    color: grey;
  }
  .highlightNonCurrentMonthDay{
    color: #676767;
  }
  .comparisonPeriodWrapper{
    position: relative;
    overflow: hidden;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-bottom: 2px;
  }
  .inComparisonPeriod{
    background: #3f51b5;
    color: white;
  }
  .firstInComparisonPeriod{
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
  }
  .lastInComparisonPeriod{
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
  }
  .selectedPeriodWrapper{
    padding-left: 3px;
    padding-right: 3px;
  }
  .inSelectedPeriod{
    background: #f50057;
    color: white;
  }
  .firstInSelectedPeriod{
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    margin-left: 3px;
    padding-left: 0;
  }
  .lastInSelectedPeriod{
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-right: 3px;
    padding-right: 0;
  }

}

.headingRoot {
  //display: flex;
  //flex-direction: column;
  //align-items: flex-start;
  //justify-content: center;
  //height: 100px;
  //background-color: @primary-main;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: #3f51b5;
  color: white;
  & > .yearLabel{
    cursor: pointer;
    color: white;
  }
  &> .dateLabel{
    color: white;
  }
}

.fleetDatePicker {
  color:white !important;
}



.monthPickerRoot {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 107px;
  padding-right: 107px;
  .monthGroup{
    display: flex;
    justify-content: center;
  }
  .comparisonPeriodWrapper {
    position: relative;
    overflow: hidden;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-bottom: 4px;

  }
  .inComparisonPeriod{
    background: #3f51b5;
  }
  .firstInComparisonPeriod{
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
  }
  .lastInComparisonPeriod{
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
  }
  .selectedPeriodWrapper{
    padding-left: 3px;
    padding-right: 3px;
  }
  .inSelectedPeriod{
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 0;
    padding-right: 0;
    background: #f50057;
    border-radius: 15px;
  }
  .monthButton{
    width: 100px;
    text-align: center;
    color: #3f51b5;
  }
  .monthButtonInComparisonPeriod{
    color: white;
  }
  .monthButtonDisabled{
    color: grey;
  }
}

.yearSelectorRoot {
  overflow-y: auto;
  .year{
    cursor: pointer;
    color: #002984;
    //font-size: theme.typography.h5.font-size
  }
  .selectedYear{
    color: #3f51b5;
    //font-size: theme.typography.h4.font-size
  }
}

.fleetPerformanceToolbarDatePicker {
  flex-grow: 1;
}

.fleetPerformanceToolbarPageHeader {
  flex-grow: 2;
}

.fleetPerformanceToolbarinfoIcon {
  color: @icon-color-main;
}

.authorizeReattemptModalRoot {
  .MuiDialog-container{
    align-items: start;
  }
  .modal-title{
    font-weight: 600;
    font-size: 1.125rem;
    text-align: center;
  }
  .section-label{
    color: #363e45;
    margin: 6px 0;
    font-weight: 600;
    font-size: 0.875rem;
  }
  .section-label  .modal-title{
    color: #363e45;
    margin: 6px 0;
  }
  .modal-header{
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    & > .MuiTypography-root{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .modal-title{
            font-weight: bolder;
        }
    }
  }
  .double-label-toggle-switch{
    color: #000000DE;
    font-size: 1rem;
  }
  .auth-modal-deliveries-container{
    border: 1px solid #DDD;
    border-radius: 8px;
    overflow-y: auto;
    & > *{
      border-bottom: 1px solid #D9D9D9;
    }
    & > *:last-child{
      border: none;
    }
    .auth-modal-switch-row{
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 8px;
      font-size: 1rem;
      color: #909090;
    }
  }
  .modal-body form{
    display: flex;
    flex-direction: column;
    gap: 14px;
    .auth-modal-deliveries-container{
      flex: 1 1 auto;
      max-height: 30vh;
    }
    .date-time-range-picker{
      display: flex;
      gap: 30px;
    }
  }
  .modal-footer{
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    .MuiButton-root{
      border-radius: 2px;
      text-transform: uppercase;
      background-color: #FFF;
      border: 1px solid #CCC;
      font-size: 0.875rem;
      color: #333;
    }
    .MuiButton-textPrimary{
      background-color: #5cb85c;
      border-color: #4cae4c;
      color: #FFF;
    }
  }
}

.forgotPasswordPageRoot {
  .paper{
    margin-top: 16px;
    padding-top: 32px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form{
    width: 100%;
  }
  .forgotPassword{
    margin-top: 16px;
  }
}

.bulkCreateDriversPageRoot {
  .paper{
    padding-top: 32px;
    padding-bottom: 32px;
    margin: 32px 0;
  }
}

.bulkCreateDriverPageDetectCollisionsButtonBar {
  margin-top: 8px;
}



.editUserDCAccessModalRoot {
  .appBar{
    position: relative;
  }
  .title{
    margin-left: 16px;
    flex: 1;
  }
  .wrapper{
    margin-bottom: 32px;
  }
  .heading{
    margin: 32px 0;
  }
}

.editUserDCAccessPageRoot {
  .wrapper{
    margin-bottom: 32px;
  }
  .heading{
    margin: 32px 0;
  }
}

.creatingStepRoot {
  .spinnerContainer {
    text-align: center;
  }
}

.loginRoot {
  display: flex;
  justify-content: center;
}
.loginPaper {
  margin-top: 16px;
  padding: 32px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.loginForm {
  width: 100%;
}
.forgotPassword {
  margin-top: 16px !important;
}
html {
  font-size: 16px !important;
  -webkit-font-smoothing: antialiased;
}

.MuiAppBar-root {
  z-index: 1;
}

.waypointRoot {
  margin: 0 16px 16px 16px;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
}
.waypointTabs {
  .MuiTabs-scroller {
    .MuiTabs-indicator {
      color: rgb(63, 81, 181);
      background-color: rgb(63, 81, 181) !important;
    }
  }
}
.active {
  border-color: #56E9C8 !important;
  box-shadow: 3px 4px rgba(0,0,0,0.18);
  border-radius: 10px !important;
}
.activeStop {
  border-color: #56E9C8 !important;
  border-radius: 10px !important;
  background-color: #56e9c8!important;
  color:white;
}
.finished {
  border-color: #060525;
  border-radius: 0;
  box-shadow: none;
}
.unfinished {
  border-color: #adadad;
  box-shadow: 3px 4px rgba(0000.18);
  border-radius: 10px;
}
.expand {
  transform: rotate(0deg);
  margin-left: auto;
  //transition: theme.transitions.create(transform {
  //  duration: theme.transitions.duration.shortest
  //})
}
.expandOpen {
  transform: rotate(180deg)
}

.container {}
.lineGraphBox {
  border-style: solid;
  border-color: whitesmoke;
  border-width: 1px;
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
  width: 100%;
}
.statsContainer {
  padding-top: 10px;
  text-align: center
}
.legend {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 8px;
}
.legendStat {
  margin: 0 8px 0 8px;
}
.success {
  background-color: #00B155;
  font-weight: 600;
  //margin-right: 5px;
}
.attemptSuccess {
  background-color: #cbffe4;
}
.successText {
  color: #00B155;
  margin-right: 5px;
}
.failed {
  background-color: #ED4957;
  font-weight: 600;
  //margin-right: 5px;
}
.failedText {
  color: #ED4957;
  margin-right: 5px;
}
.visitLater {
  background-color: #F7AF00;
  font-weight: 600;
  //margin-right: 5px;
}
.visitLaterText {
  color: #F7AF00;
  margin-right: 5px;
}
.authorizeReattempt {
  background-color: #7F21FF;
  font-weight: 600;
  //margin-right: 5px;
}
.authorizeReattemptText {
  color: #7F21FF;
  margin-right: 5px;
}
.notAttempted {
  background-color: #adadad;
  font-weight: 600;
  //margin-right: 5px;
}
.notAttemptedText {
  color: #adadad;
  margin-right: 5px;
}
.successStatusIcon {
  background-color: #00B155;
}
.failedStatusIcon {
  background-color: #ED4957;
}
.visitLaterStatusIcon {
  background-color: #F7AF00;
}
.authorizeReattemptStatusIcon {
  background-color: #7F21FF;
}
.notAttemptedStatusIcon {
  background-color: #adadad
}
.deliveryListContainer {
  display: flex;
  flex-direction: column;
  padding: 0 24px 16px 24px;
}
.deliveryListColumnHeaders {
  display: flex;
  padding: 8px 24px 8px 32px;
}
.productRow {
  display: flex;
  align-items: stretch;
}
.deliveryItem {
  display: flex;
  align-items: center;
}
.productCodeMsg {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 4px 8px 4px 8px;
}
.border-bottom {
  border-bottom: 1px solid lightgrey;
  padding-left: 8px;
}
.quantity {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.statusIcon {
  width: 4px;
}
.barAndStats {
  padding: 8px;
}
.deliveryCodeAndMessage {
  font-size: 10px
}
.hideDiv {
  display: none
}
.codeAndMsg {
  margin-left: 16px;
}
.notesIcon {
  width: 16px;
  height: 16px;
  //color: theme.palette.neutral.main
}
.notesContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end
}
.notesSpan {
  display: flex;
  align-items: center ;
}
.border {
  border-style: solid;
  display: flex;
  flex-direction: row;
  border-color: gray;
  border-width: 1px;
  border-radius: 5px;
}
.statusIcon {
  width: 4px;
}
.deliveryListContainer {
  display: flex;
  flex-direction: column;
  padding: 0 24px 16px 24px;
}
.deliveryListColumnHeaders {
  display: flex;
  padding: 8px 24px 8px 32px;
}
.productRow {
  display: flex;
  align-items: stretch;
}
.deliveryItem {
  display: flex;
  align-items: center;
}
.productCodeMsg {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 4px 8px 4px 8px;
}
.border-bottom {
  border-bottom: 1px solid lightgrey;
  padding-left: 8px;
}
.quantity {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.deliveryCodeAndMessage {
  font-size: 10px
}
.hideDiv {
  display: none
}
.codeAndMsg {
  margin-left: 16px;
}
.notesIcon {
  width: 16px;
  height: 16px;
  //color: theme.palette.neutral.main
}
.notesContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end
}
.notesSpan {
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  margin: 8px 0;
  width: 100%;
  padding: 0 8px;
}

.stop-icon-waypoint {
  display: flex;
  justify-content: center;
  align-items: center;
}
.stop-icon-home {
  width: 42px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  border-radius: 50% !important;
  border: 1px solid black;
  margin: auto;
  padding-top: 6px;
}
.stop-icon-waypoint {
  width: 42px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  border-radius: 50% !important;
  border: 1px solid black;
  margin: auto;
}

.stopNumber {
  width: 50px;
}
.active {
  background-color: #56E9C8;
  color: #FFF;
}
.finished {
  background-color: #060525;
  color: #FFF;
}
.unfinished {
  background-color: #adadad;
  color: #FFF;
}
.unfinishedStop {
  background-color: #adadad;
  color: #FFF;
  border: unset;
}
.waypointInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding: 0 8px;
}
.waypointInfoText {
  flex: 1;
  display: flex;
}
.waypointNameAddressAndExtId {
  display: flex;
  flex-direction: column;
  width: 70%;
  text-align: left;
}
.waypointName {
  font-size: 14px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}
.waypointAddress {
  font-size: 12px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}
.visits {
  font-size: 12px
}
.etaAndVisits {
  text-align: right;
  display: flex;
  flex: 1;
  flex-direction: column
}
.eta { font-style: italic }
.visitTimestamp { font-weight: 600 }
.noEta { font-style: italic }
.progressBar {
  margin-top: 8px
}
.progressBarHidden {
  visibility: hidden
}
.expand {
  transform: rotate(0deg);
  margin-left: auto;
  //transition: theme.transitions.create(transform {
  //  duration: theme.transitions.duration.shortest
  //})
}
.expandOpen {
  transform: rotate(180deg)
}
.etaWithWaitingTime {
  //color: theme.palette.grey[700]
}

.wrapper {
  overflow-y: auto
}
.stickyWrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%
}
.tabContents {}
.stickyContents {
  overflow-y: auto;
  max-height: 100%;
  flex: 1
}

.waypointHeader {
  display: flex;
  align-items: center;
  margin: 0 16px 16px 16px;
  padding: 8px;
  width: 100%;
}

.waypointInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 8px;
}
.waypointInfoText {
  flex: 1;
  display: flex
}
.waypointNameAddressAndExtId {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 70%
}
.waypointName {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}
.waypointAddress {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}
.timestamp {
  font-weight: 600 !important;
}
.etaAndVisits {
  text-align: right;
  //margin-right: 24px
}

.dataContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0 0 8px
}
.waitTimeIcon {
  width: 16px;
  height: 16px
}
.waitTimeIconColor {
  //color: theme.palette.grey[700]
}
.timeIcon {
  width: 16px;
  height: 16px
}
.confidenceIcon {
  width: 16px;
  height: 16px
}
.iconContainer {
  display: flex;
  align-items: center;
  margin-right: 5px
}
.confident {
  color: #00B155;
}
.unconfident {
  color: #ED4957;
}
.unknown {
  color: #adadad;
}
.dataLabel {
  margin-right: 5px;
  color: #9e9e9e;
}
.waypointInfo {
  font-size: 14px;
}

.waypointInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 8px;
}
.waypointInfoText {
  flex: 1;
  display: flex
}
.waypointNameAddressAndExtId {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 70%
}
.waypointName {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}
.waypointAddress {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}
.timestamp {
  font-size: 14px;
}
.etaAndVisits {
  text-align: right
}

.route-list-day-root {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin: 32px 0;

  & .MuiCollapse-wrapperInner {
    overflow: auto;
  }

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
  }
  & .headerChip { margin-right: 8px }
  & .expand {
    transform: rotate(0deg);
    margin-left: auto;
  }
  & .expanded {
    transform: rotate(180deg)
  }
  & .collapse {
    padding: 0 16px 16px 16px
  }

  & .links {
    text-align: center;
    max-width: max-content;
    & > div {
      display: flex; 
      align-items: center; 
      justify-content: space-evenly;
      gap: 5px;
    }
  }

  & .start-time, & .import-time, & .finalized, & .totalClients {
    text-align: right;
  }
}

.caption {
  margin-bottom: 0
}
.routeConflictText {
  //color: theme.palette.bad.main
}

.runRoutePaper {
  //...theme.mixins.gutters()
  padding-top: 16px;
  padding-bottom: 16px;
  margin: 32px 0;
}

.dialogContent {
  padding: 8px;
  margin: 8px;
}
.lineContainer {
  display: flex;
  justify-content: space-between;
  align-items: center
}
.lineItem {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.finishRoutePaper {
  //...theme.mixins.gutters(),
  //margin-top: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
  margin: 32px 0;
}

.customerSearchWrapper {
  margin-top: 32px;
  margin-bottom: 32px;
}

.mapDatePicker {
  padding-right: 20px;
}

.wrapper {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.maxWidthNone {
  max-width: none !important;
}
.expand {
  position: relative;
  flex: 1
}
.messageCloseButton {
  padding:  8px;
}


.goodValue {
  color: #087f23;
  display: flex;
  flex-direction: column;
  justify-content: center
}
.badValue {
  color: #ba000d;
  display: flex;
  flex-direction: column;
  justify-content: center
}
.neutralValue {
  color: #9e9e9e;
  display: flex;
  flex-direction: column;
  justify-content: center
}
.outerCell {
  display: flex;
  justify-content: space-evenly
}
.innerCell {
  display: flex;
  flex-direction: column
}
.value {
  font-weight: bold
}
.historicalValue {
  font-weight: 200
}

.spinning {
  text-align: center;
  vertical-align: middle;
}

.cell {
  text-align: center;
}

.routeAnalyzer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column
}
.mapAndVisitList {
  flex: 1;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid
}
.borderBottom {
  padding-left: 8px;
  border-bottom: 1px solid lightgrey;
}
.editableText {
  display: flex;
  align-items: center;
  &:hover > span {
    filter: opacity(100)
  }
}
.editableTextRoute {
  display: flex;
  align-items: center;
  & > a {
    color: #060525;
    text-decoration: none;
  }
  &:hover > span {
    filter: opacity(100)
  }
}
.editableTextValue {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}
.editableTextValueRoute {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  & > a {
    color: #060525 !important;
    text-decoration: none !important;
  }
}
.editableTextValueLeft {
  text-align: left
}
.editableTextIcon {
  filter: opacity(0);
  cursor: pointer;
  padding-left: 10px;
}
.controls {
  color: rgb(138, 149, 158);
  //background-color: theme.palette.background.default,
  display: flex;
  padding: 5px 0;
}

.playPauseAndTime {
  text-align: center;
  padding: 5px 15px;
  min-width: 125px;
  border-right: 1px solid rgb(138, 149, 158);
}
.currentTime {
  font-size: 20px
}

.timeline {
  flex: 1;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
}

.warehouseRoot {
  position: absolute;
  width: 400px;
  right: 16px;
  margin-top: 16px;
}
.warehousePaper {
  //...theme.mixins.gutters(),
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
}
.spinnerContainer {
  display: flex;
  justify-content: center;
}
.buttonHolder {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.contents {
  display: flex;
}
.leftPanel {
  padding: 16px;
}
//.secretCode {
//  background-color: #fff !important;
//  color: black;
//}
.driversSettingsPanel {
  flex: 1;
  margin: 32px;
  .MuiButton-root {
    background-color: #060525;
    color: white !important;
  }
}
.secretCodePaper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 16px;
}

.dcSettingsPanel {
  flex: 1;
  margin: 32px;
}

.chips {
  display: flex;
  flex-wrap: wrap;
}
.chip {
  margin: 2px;
}

.usersSettingsPanel {
  flex: 1;
  margin: 32px;
}

.driverGroupsSettingsPanel {
  flex: 1;
  margin: 32px;
}
.loginBackground {
  margin: 0 auto;
  padding: 8px;
  background-color: #375065;
  max-width: 80%;
  border-radius: 8px;
  text-align: center;
}
.MuiMenuItem-root.Mui-selected {
  background-color: #428bca !important;
  color: white !important;
}
.MuiOutlinedInput-input.MuiSelect-select {
  min-height: auto !important;
}

.colorButton {
  background-color: #060525 !important;
}

.uploadButton {
  //margin-top: 10px !important;
  //margin-left: 5px !important;
  height: 34px;
}
.uploadField {
  width: 70%;
  height: 34px;
  color: #ccc !important;
  padding: 6px 12px;
  border: 1px solid #ccc;
  font-family: "Simplon";
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.mapName {
  height: 34px;
  color: #555;
  width: calc(100% - 10px);
  margin-right: 10px;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Simplon";
}
.mapImporterLabel {
  font-weight: 500;
  font-family: "Simplon";
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-family: "Simplon";
}
.marginAuto {
  margin: auto;
}
.importerDateInput {
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc !important;
  border-radius: 4px;
  .MuiInput-input {
    padding: 5px 10px;
    border-right: 1px solid #ccc !important;
    color: #555;
    font-size: 14px;
  }
  .MuiInputBase-root{
    border-bottom: unset;
  }
  .MuiInput-root::before{
    border-bottom: unset;
  }
  .MuiInputAdornment-root{
    .MuiButtonBase-root{
      border-radius: 2px;
      text-transform: uppercase;
      font-family: "Simplon";
      font-weight: 300;
      padding:0;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      padding-right: 20px;
      & > svg {

      }
    }
  }
}
.realtimeDatePicker {
  padding-top: 16px !important;
  width: 100%;
}
.MuiInputLabel-root {
  z-index: 1;
  background-color: inherit;
}
.backgroundLabel-white {
  z-index: 1;
  background-color: white;
}
.mapClass {
  overflow: visible;
}
.MuiButton-textPrimary:hover{
  background-color: #060525 !important;
  color: white !important;
}
.MuiButton-textSecondary:hover{
  background-color: #7D7D8E !important;
}
.inlineFlex {
  display: inline-flex;
}

.attemptListHeader {
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  padding: 2px;
  border-bottom: 1px solid rgb(138, 149, 158)
}
.attemptList {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgb(138, 149, 158);
}
.attemptsWrapper {
  flex: 1;
  overflow-y: auto;
  flex-direction: column;
  position: relative;
}
.attempts {
  position: absolute;
  width: 100%;
}

.attempt {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  border-bottom: 1px solid rgb(138, 149, 158)
}
.attemptFailed {
  background-color: #fbd4d8
}
.attemptVisitLater {
  background-color: #ffdf91
}
.attemptAuthorizeReattempt {
  background-color: #d7baff
}
.attemptcurrent {
  background-color: #f1f8fe
}
.numberBadge {
  position: relative;
  width: 28px;
  height: 28px;
  line-height: 24px;
  margin: 5px 5px 5px 0;
  padding: 5px;
  text-align: center;
  background-color: rgb(138, 149, 158);
  border-radius: 50%;
  box-shadow: 0 0 1px black;
  border: 2px solid white;
}
.actualNumber {
  position: absolute;
  color: white;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.waypointInfo {
  //min-width: 400px;
  flex: 1;
  font-size: inherit !important;
}
.name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
}
.extIdAndServiceTime {
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.route-list-heading {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  & > * {
    max-width: 33%;
  }

  & > div {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.marker-label {
  position: absolute;
  overflow: hidden;
  opacity: 0.01;
  margin-left: 16px;
  margin-top: -32px;
  left: -116px;
  top: -100px;
  z-index: -99;
  display: block;
  cursor: pointer;
}
body {
  font-weight: 300;
  font-size: 14px;
  font-family: "Simplon" !important;
}
.underlineDecoration {
  text-decoration: underline !important;
}
.MuiStepper-root {
  display: flex;
  padding: 24px;
}
@font-face {
  font-family: 'Simplon';
  src: url('../../app/fonts/simplonbp-regular-webfont.woff2') format('woff');
}
.MuiContainer-maxWidthMd {
  max-width: 960px !important;
}
.MuiContainer-maxWidthLg {
  max-width: 1280px !important;
}
.MuiTooltip-arrow {
  pointer-events: none !important;
}
.MuiTooltip-popper {
  pointer-events: none !important;
}
.modal-map {
  flex: 1;
  position: relative;
  width: 600px;
  height: 500px;
}
.displayFlex {
  display: flex;
}

.searchWrapper{
  & > input{
    width: inherit !important;
  }
}



.MuiTabs-indicator {
  background-color: #f50057 !important;
}

.autoWidth {
  width: auto;
  padding: unset;
  margin: unset;
}

.addDeliveryCode {
  .MuiTabs-indicator {
    background-color: #48e9c8 !important;
  }
}




.warehouseExpand {
  flex: 1 1 auto;
  display: flex;
  .map-container {
    flex: 1 1 auto;
    & > div {
      width: 100%;
      height: 100%;
    }
  }
}

.MuiDialog-container {
  .map-container {
    width: 552px;
    height: 500px;
    & > div {
      width: 100%;
      height: 100%;
    }
  }
}

.json-view {
  & > span{
    & > svg {
      height: 20px;
      width: 20px;
    }
  }
  & > svg {
    height: 20px;
    width: 20px;
  }

}
.json-view--pair {
  & > span{
    & > svg {
      height: 20px;
      width: 20px;
    }
  }
  & > svg {
    height: 20px;
    width: 20px;
  }

}

.manageUserButton:hover {
  color:white;
}

.rightSideContainer {
  left: 480px;
}

.hasSidebar {
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-end;
  justify-content: center;
}