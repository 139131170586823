@import "colors";

// Login screen
.app-login-background {
  background-color: @app-login-background;
}

.app-login-input-field-icon-container {
  background-color: @app-light-red;
  width: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-login-input-field{
  background-color: @light;
  width: 100%;
  height: 30px;
}

.app-login-input-field-half{
  background-color: @light;
  width: 50%;
  height: 30px;
}

.app-login-hyphen-text{
  color: @light;
  width: 20px;
  height: 100%;
  margin-right: 5px;
  margin-left: 5px;
}

.input-info-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1;
}

.h2 {
  margin-top: 0;
  margin-bottom: 0;
}