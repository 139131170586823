@import "colors";

.date-picker-input {

  &.block {
    display: flex;

    .dropdown-toggle {
      flex: 1;
    }

  }

  .dropdown-menu {
    padding: 0;
  }

  .date-picker-buttons {
    display: flex;
    justify-content: space-between;

    .btn {
      margin: 0 5px 5px;
    }
  }

}