@import "colors";

.driver-profile-modal {

  .driver-profile-section-header {
    padding-top: 30px;
    padding-left: 15px;
    margin-bottom: 5px;
  }

  .setting-description {
    margin-left: 25px;
    font-size: 12px;
  }

  .settings-field {
    margin-left: 15px;
  }

  .settings-header {
    margin-top: 10px;
  }

  .section-divider {
    border-bottom: 1px solid darken(@lightgray, 15%);
    margin: 25px 30px 30px 20px;
  }

  .disabled-editable {
    color: @dark;
    border-bottom: 1px solid lighten(@darkgray, 15%);
    padding-bottom: 3px;
    cursor: not-allowed;
  }

  .editable-input {
    height: 30px;
    padding: 6px 6px;
    border: 1px solid #ccc;
    border-radius: 2px;
  }

  .editable-buttons button {
    padding: 2px 4px;
    margin-top: 5px;
    font-size: 11px;
    line-height: 14px;
    border-radius: 2px;
    border: 1px solid @steelblue;
    color: @light;
    background-color: @steelblue;
  }

  .editable-buttons button:hover,
  .editable-buttons button:focus {
    border: 1px solid darken(@steelblue, 11%);
    outline: none;
    color: @light;
    background-color: darken(@steelblue, 11%);
  }

  .editable-empty, a.editable-empty {
    font-style: normal;
    color: @watermelon;
    border-bottom: 1px solid @watermelon;
  }

  .editable-click, .a.editable-click {
    color: @dark;
    border-bottom: 1px solid lighten(@darkgray, 15%);
    padding-bottom: 3px;
  }

}


