@media only screen {

  .access-denied {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100vh;
  }

}
