@import 'colors';

@handle-radius: 10px;
@track-height: 10px;

.switch {
  border-radius: @handle-radius;
  position: relative;
  display: inline-block;
  width: 5 * @handle-radius;

  .switch-track {
    border: 1px solid @gray;
    height: @track-height;
    width: 3 * @handle-radius;
    margin-left: @handle-radius;
    margin-right: @handle-radius;
    border-radius: @track-height / 2;
    cursor: pointer;
    display: inline-block;

    .switch-toggle {
      border: 1px solid @gray;
      background-color: @light;
      width: 2 * @handle-radius;
      height: 2 * @handle-radius;
      border-radius: @handle-radius;
      top: 0;
      left: 0;
      cursor: pointer;
      position: absolute;
    }

    &.on {
      border-color: @delivery-success;
      background-color: @delivery-success;

      .switch-toggle {
        left: unset;
        right: 0;
      }

    }

    &.disabled, &.disabled:hover {
      opacity: 0.30;
      cursor: not-allowed;

      .switch-toggle {
        cursor: not-allowed;
      }

    }

  }
}