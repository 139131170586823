@import "colors";
@import "fonts";

@media only screen {

  @media (min-width: 767px) {
    p {
      margin: 0 0 35px;
      font-size: 20px;
      line-height: 1.6;
    }

    .help-block {
      font-size: 15px;
    }

    .navbar {
      padding: 8px 0;
    }

  }

  .user-info-text {
    color: #718290;

    >strong {
      color: #57626C;
    }
  }

  .user-warning-text {
    color: @watermelon;
  }

  .navbar {
    margin-bottom: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    font-family: "Simplon";

    .navbar-nav {
      text-transform: uppercase;
    }

    .nav-active {
      font-weight: bold;
    }

    .alerts-badge {
      background-color: @watermelon;
      font-weight: 300;
      position: relative;
      top: -1px;  // Yes.
    }

    li.dropdown > a > .alerts-badge {
      margin-right: 5px;
    }

    li.dropdown > ul.dropdown-menu > li {

      a {
        font-weight: 300;
      }

      &.nav-active a {
        font-weight: 400;
      }

    }

  }

  .navbar-brand:focus {
    outline: none;
  }

  .navbar-custom a {
    color: @dark;
  }

  .navbar-default {
    border: 0;
  }

  .navbar-custom .nav li a {
    -webkit-transition: background 0.3s ease-in-out;
    -moz-transition: background 0.3s ease-in-out;
    transition: background 0.3s ease-in-out;
  }

  .navbar-custom .nav li a:hover,
  .navbar-custom .nav li a:focus,
  .navbar-custom .nav li.active {
    outline: none;
  }

  .navbar-toggle {
    padding: 4px 6px;
    font-size: 16px;
    color: @dark;
  }

  .navbar-toggle:focus,
  .navbar-toggle:active {
    outline: none;
  }

  @media (min-width: 767px) {
    .navbar {
      padding: 8px 0;
      letter-spacing: 1px;
      background: transparent;
      border-bottom: 0px;
      -webkit-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
      -moz-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
      transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
    }

    .top-nav-collapse {
      padding: 10px;
      background-color: @light;
      border-bottom: 1px solid rgba(192, 192, 192, 0.3);
    }
  }

  @media (min-width: 767px) {
    .brand-heading {
      font-size: 50px;
      padding-top: 10%;
    }
  }

  @media (max-width: 767px) {
    .navbar {
      background-color: @light;
      padding: 8px 0;
    }

    .navbar .navbar-brand {
      content: url(../img/lightlogo.png);
    }

  }
  ::-moz-selection {
    text-shadow: none;
    background: @lightblue;
    color: @light;
  }

  ::selection {
    text-shadow: none;
    background: @lightblue;
    color: @light;
  }

  img::selection {
    background: transparent;
  }

  img::-moz-selection {
    background: transparent;
  }

  body {
    webkit-tap-highlight-color: rgba(255, 255, 255, 0.2);
    font-weight: 400;
  }

  code {
    white-space: normal;
  }

  .container-full {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .full-width {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  input:focus::-moz-placeholder {
    color: transparent;
  }

  input:focus.ng-invalid {
    background-color: #FFF0F0;
  }

  .logo-color {
    color: @watermelon !important;
  }

  .vertical-align {
    vertical-align: middle !important;
  }

  .hand-cursor:hover {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -ms-grab;
    cursor: -o-grab;
  }

  .hand-cursor:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -ms-grabbing;
    cursor: -o-grabbing;
  }

  .pointer {
    cursor: pointer;
  }

  .hover-border:hover {
    border: 1px solid #718290;
  }

  .hover-border {
    border-radius: 3px;
    padding: 3px;
    margin-left: 2px;
    margin-top: 4px;
  }

  .attempt-success {
    background-color: @delivery-success;
  }

  .attempt-failure {
    background-color: @delivery-failed;
  }

  .attempt-visit-later {
    background-color: @delivery-visit-later;
  }

  .attempt-authorize-reattempt {
    background-color: @delivery-authorize-reattempt;
  }

  .user-delivery-code-container {
    margin-left: 7px;
  }

  .user-delivery-code {
    background-color: @watermelon;
    color: @light;
    border-radius: 3px;
    padding: 2px 2px 2px 3px;
    font-size: 75%;
    margin-right: 3px;
  }

  .user-delivery-code-message {
    font-style: italic;
    color: darken(@darkgray, 15%);
    vertical-align: middle;
  }

}


.loading-animate {
  animation: loading-animate 1250ms infinite linear;
}

@keyframes loading-animate {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
