@import "colors";
@import "fonts";

@media only screen {

  .btn-foxtrot {
    border-radius: 2px;
    border: 1px solid @watermelon;
    color: @light;
    background-color: @watermelon;
  }

  .btn-foxtrot:hover,
  .btn-foxtrot:focus {
    border: 1px solid darken(@watermelon, 11%);
    outline: none;
    color: @light;
    background-color: darken(@watermelon, 11%);
  }

  .btn {
    border-radius: 2px;
    text-transform: uppercase;
    font-family: "Simplon";
    font-weight: 300;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .btn-default:hover,
  .btn-default:focus {
    outline: none;
    color: @dark;
  }

  .btn-huge {
    padding: 25px;
    font-size: 26px;
  }

  .btn-center {
    padding-top: 15px;
    padding-bottom: 25px;
    text-align: center !important;
  }

  .btn-signup {
    width: 150px;
  }

  .export-button {
    padding: 9px;
  }

  .large-height-btn {
    height: 50px;
  }

  .overview-btn {
    height: 40px;
    width: 100%;
  }

  .btn-white {
    background-color: @light;
  }

  .btn-white:hover {
    background-color: @lightgray;
  }

  .btn-info[disabled] {
    background-color: @disabled_watermelon;
    border-color: @disabled_watermelon;
  }

  .btn-primary[disabled] {
    background-color: @disabled_watermelon;
    border-color: @disabled_watermelon;
  }

  .btn-blue {
    background-color: @steelblue;
    border-color: @steelblue;
    color: @light !important; // make sure the text does not go to black
    &:hover {
      background-color: @steel_hover;
      border-color: @steel_hover;
      color: @light;
    }
  }

  .btn-gray {
    background-color: @graybluelabel;
    border-color: @graybluelabel;
    color: @light;
    &:hover {
      background-color: darken(@graybluelabel, 11%);
      border-color: darken(@graybluelabel, 11%);
      color: @light;
    }
  }

  .icon-btn-cta {
    padding-left: 4px;
    color: @graphiteGray;
    &:hover {
      color: @graybluelabel;
    }
  }
}
