.warehouse-polygon-editor {

  .warehouse-polygon-editor-title {
    display: flex;
    align-items: center;

    .reset-button-holder, .save-button-holder {
      flex: 1;
    }

    .title {
      text-align: center;
      flex: 3;

      h1, h3 {
        margin-bottom: 5px;
      }
    }

    .reset-button-holder {
      text-align: left;
    }

    .save-button-holder {
      text-align: right;
    }

  }

  .warehouse-polygon-editor-body {
    display: flex;
    height: 400px;

    .editor-wrapper {
      flex: 2;
      position: relative;
    }

    .warehouse-location-editor {
      flex: 1;
      position: relative;
    }

  }

}