@import 'colors';

@handle-radius: 10px;
@track-height: 10px;

.slider {
  position: relative;
  padding: 5px 0;

  .slider-track {
    border: 1px solid @gray;
    background-color: @light;
    height: @track-height;
    border-radius: @track-height / 2;
  }

  .slider-handle-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 @handle-radius 0 -@handle-radius;
  }

  .slider-handle {
    border: 1px solid @gray;
    background-color: @light;
    width: 2 * @handle-radius;
    height: 2 * @handle-radius;
    border-radius: @handle-radius;
    position: absolute;
    top: 0;
    cursor: pointer;
  }
}
