@import "colors";

.realtime-dashboard {
  flex: 1 1 auto;
  padding: 5px 30px 10px;
  display: flex;
  flex-direction: column;

  .has-tooltip {
    text-decoration: underline;
    text-decoration-style: dotted;
  }

  .dashboard-header {
    margin: 10px 50px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .driver-group-picker, .dashboard-title, .date-picker {
      display: flex;
      flex-basis: 0;
      flex-grow: 1;
      flex-wrap: nowrap;
      align-items: center;

      .picker-label {
        margin-right: 10px;
        font-weight: 600;
      }

    }

    .dashboard-title h1 {
      width: 100%;
      margin: 0;
      text-align: center;
    }

    .date-picker {
      text-align: right;

      .date-picker-input {
        margin: auto 0 auto auto;

        button:last-child {
          min-width: 200px;
        }
      }

    }

  }

  .dashboard-summary-bar {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    align-items: stretch;
    align-content: flex-end;
    justify-content: space-between;

    .summary-bar-section {
      background-color: @ice-blue-color;
      margin: 10px 5px;
      text-align: center;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .summary-bar-title {
        display: block;
        align-self: flex-start;
        width: 100%;
        text-align: center;
        margin: 5px 0;
      }

      .bottom {
        align-items: flex-end;
        display: flex;
        width: 100%;
        align-content: stretch;
        text-align: center;
        flex-grow: 1;

        .subsection-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: flex-end;
        }

      }

      .subtitle {
        margin: 0;
      }

      .summary-bar-fraction {
        width: 100%;
      }

      .metric {
        font-size: 50px;
        font-weight: bold;
        padding: 0;
        margin: 0 auto 5px 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        height: 60px;

        &.delivery-success {
          color: @delivery-success;
        }

        &.delivery-failed {
          color: @delivery-failed
        }

        &.delivery-visit-later {
          color: @delivery-visit-later
        }

        &.delivery-authorize-reattempt {
          color: @delivery-authorize-reattempt;
        }

        &.delivery-return {
          color: @delivery-failed;
        }

      }

      .pie-chart {

        height: 80px;

        > svg {
          width: 80px;
          height: 80px;
          margin: 0 auto;
        }
      }

    }
  }

  .dashboard-body {
    flex: 1 1 1px;
    display: flex;
    gap: 15px;
    overflow-y: hidden;

    .foxtrot-table-container {
      flex: 2;
      width: 100%;
      overflow-y: auto;

      .pending-optimization-icon {
        color: @pending-optimization-color;
      }

      .route-issues-icon {
        color: @unroutable-route-color;
      }

      .driver-status-online {
        background-color: @delivery-success;
      }

      .driver-status-offline-now {
        background-color: @delivery-visit-later;
      }

      .driver-status-offline-today {
        background-color: @delivery-failed;
      }

      .progress-success {
        height: 20px;
        display: inline-block;
        background-color: @delivery-success;
      }

      .progress-failed {
        height: 20px;
        display: inline-block;
        background-color: @delivery-failed;
      }

      .success-color {
        color: @delivery-success
      }

    }

    .dashboard-map {
      flex: 1;
      position: relative;
    }

    .map-info-pop-up {
      display: block;
      text-align: center;
    }

  }
}
