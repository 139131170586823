@import 'colors';

@route-inspector-gray-color: rgb(250, 250, 250);
@route-inspector-dark-gray-color: rgb(138, 149, 158);

.internal-route-analyzer-route-finder {
  padding-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header h1 {
    width: 100%;
    margin: 0 0 10px;
    text-align: center;
  }

  .body {
    flex: 1;
    display: flex;
    overflow-y: hidden;
    padding: 0 30px;
  }

  .sidebar {
    width: 350px;
    overflow-y: auto;

    .filter-label {
      font-size: 1.5rem;
      margin: 10px 0 5px;
    }

    .submit-button {
      margin: 15px 0;
    }

  }

  .result-list {
    flex: 1;
    margin-left: 10px;
    overflow-y: auto;

    .analyze-route-button-container {
      text-align: center;
    }
  }

}

.user-route-analyzer-route-finder {

  .header {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .date, .page-title, .filter {
      flex: 1;
    }

    .page-title h1 {
      width: 100%;
      text-align: center;
      margin: 0;
    }

  }

  .analyze-route-button-container {
    text-align: center;
  }

}
