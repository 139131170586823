@media only screen {

  .center-block {
    float: none;
  }

  .no-margin {
    margin: 0;
  }

  .marg-med-left {
    margin-left: 15px;
  }

  .marg-tiny-left {
    margin-left: 3px;
  }

  .marg-small-left {
    margin-left: 7px !important;
  }

  .marg-small-right {
    margin-right: 7px;
  }

  .marg-top-md {
    margin-top: 30px;
  }

  .marg-med-bottom {
    margin-bottom: 15px;
  }

  .marg-bottom-20 {
    margin-bottom: 20px;
  }

  .smaller-margin {
    margin: 0 0 25px;
  }

  .smallest-margin {
    margin: 0 0 15px;
  }

  .padd-top {
    padding-top: 15px;
  }

  .padd-top-md {
    padding-top: 25px;
  }

  .no-padd-bottom {
    padding-bottom: 0px;
  }

  .xs-padding-bottom {
    padding-bottom: 10px;
  }

  .large-padd-bottom {
    padding-bottom: 50px;
  }

  .md-padd-bottom {
    padding-bottom: 20px;
  }

  .padding-bottom-35 {
    padding-bottom: 35px;
  }

  .large-margin-bottom {
    margin-bottom: 50px;
  }

  .sm-margin-bottom {
    margin-bottom: 10px;
  }

  .padd-bottom {
    padding-bottom: 15px;
  }

  .extra-padding-top {
    padding-top: 35px;
  }

  .extra-margin-top {
    margin-top: 35px;
  }

  .no-padding-top {
    padding-top: 0px;
  }

  .padding-top-8 {
    padding-top: 8px;
  }

  .padding-top-9 {
    padding-top: 9px;
  }

  .margin-top-8 {
    margin-top: 8px;
  }

  .xs-padding-top {
    padding-top: 10px;
  }

  .padding-top-6 {
    padding-top: 6px;
  }

  .padding-top-11 {
    padding-top: 11px;
  }

  .padding-top-35 {
    padding-top: 35px;
  }
  
  .padding-right-25 {
    padding-right: 25px;
  }

  .padding-right-2 {
    padding-right: 3px;
  }

  .padding-right-3 {
    padding-right: 3px;
  }

  .padding-right-4 {
    padding-right: 4px;
  }

  .padding-right-5 {
    padding-right: 5px;
  }

  .padding-right-7 {
    padding-right: 7px;
  }

  .padding-left-9 {
    padding-left: 9px;
  }

  .padding-left-10 {
    padding-left: 10px;
  }

  .padding-left-11 {
    padding-left: 11px;
  }

  .padding-left-19 {
    padding-left: 19px;
  }

  .small-padding-top {
    padding-top: 15px;
  }

  .extra-padding {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .no-padding-left {
    padding-left: 0;
  }

  .no-padding-right {
    padding-right: 0;
  }

  .padding-top-5 {
    padding-top: 5px;
  }

  .padding-top-15 {
    padding-top: 15px;
  }

  .padding-left-15 {
    padding-left: 15px;
  }

  .padding-left-25 {
    padding-left: 25px;
  }

  .small-marg {
    margin: 10px !important;
  }

  .padding-right-15 {
    padding-right: 15px;
  }

  .padding-top-5 {
    padding-top: 5px;
  }

  .padding-top-8 {
    padding-top: 8px;
  }

  .no-margin-bottom {
    margin-bottom: 0 !important;
  }

  .width-25 {
    width: 25%;
  }

  .width-50 {
    width: 50%;
  }

  .width-85 {
    width: 85%;
  }

  .width-95 {
    width: 95%;
  }
  
  .float-right {
    float: right;
  }

  .auto-margins-horizontal-center {
    margin-right: auto;
    margin-left: auto;
  }

}
