@import "colors";
@import "mixins";


@clock-background-color: #e0e0e0;
@selection-background-color: #005caf;

.time-picker-input {

  display: flex;
  align-items: stretch;
  align-content: stretch;

  .main-button {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1;
    text-align: center;
  }

  .dropdown-menu {
    padding: 0;

    .time-picker-buttons {
      display: flex;
      justify-content: space-between;

      .btn {
        margin: 0 5px 5px;
      }
    }

  }

}

.time-picker {
  display: inline-block;

  .time-display {
    display: flex;
    height: 80px;
    margin-bottom: 10px;
    background-color: @selection-background-color;
    align-items: center;
    color: @light;

    .time-label {
      font-size: 48px;
      flex: 1;
      text-align: right;

      .hours, .minutes {
        opacity: 0.7;

        &.selected {
          opacity: 1;
        }
      }
    }

    .am-pm-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 0 20px 0;

      .am, .pm {
        .no-select;
        font-size: 16px;
        font-weight: 400;
        opacity: 0.5;
        cursor: pointer;

        &.selected {
          opacity: 1;
        }
      }
    }
  }
}

.time-picker-clock {
  display: inline-block;

  .time-picker-clock-face {
    position: relative;
    background-color: @clock-background-color;
    border-radius: 50%;

    .number-label-circle {
      position: absolute;
      width: 100%;
      height: 100%;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 350ms, opacity 350ms ease;

      &.visible {
        opacity: 1;
        visibility: visible;
        transition: opacity 350ms 175ms ease;
      }

      .number-label {
        .no-select;
        position: absolute;
        border-radius: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        background-color: transparent;
        cursor: pointer;
        color: @dark;

        > div {
          font-size: 16px;
          width: 100%;
          text-align: center;
        }

        &.selected {
          background-color: @selection-background-color;
          color: @light;
        }
      }
    }
  }
}