@import "colors";

.dc-delivery-codes {
  margin-top: 15px;

  .header {
    display: flex;
    align-items: center;


    .delivery-code-filter {
      flex: 1;
    }

    .page-title {
      flex: 1;
      text-align: center;
      font-size: 35px;

      h1 {  // Our default for this element is ridiculous
        margin: 0;
      }
    }

    .buttons {
      flex: 1;
      text-align: right;

      .btn {
        margin-left: 10px;
      }

    }

  }

  .delivery-code-table {

    margin-top: 15px;

    .edit-delivery-code-button {
      text-align: center;
    }

    .delete-delivery-code-button {
      text-align: center;
    }

  }

}