@import "colors";
@import "fonts";

.MuiTypography-root.MuiTypography-h6, .MuiTypography-root.MuiTypography-button, h6.MuiTypography-root {
  font-weight: 600;
}

.MuiButton-root, .MuiTab-root {
  font-weight: 600 !important;
  & > span {
    font-weight: 600;
  }
}

.MuiTooltip-tooltip {
  font-weight: 600 !important;
}