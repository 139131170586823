.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-13 {
  font-size: 13px;
}

.font-16 {
  font-size: 16px;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-26 {
  font-size: 26px;
}

.weight-400 {
  font-weight: 400;
}

.bold {
  font-weight: bold;
}
