@import "colors";
@import "fonts";

@media only screen {


    .ui-select-match .btn {
      text-transform: none;
    }

    .ui-select-container {
      text-align: left;
    }

    .form-control {
      position: static;
    }

    .datetime-picker-dropdown > li.date-picker-menu div > table .btn-default {
      border: 0;
    }

    .file-name {
      background-color: @light;
    }

    .file-upload-btn {
      border-radius: 0 2px 2px 0;
    }

    .file-valid {
      background-color: @faded-green !important;
    }

    .file-invalid {
      background-color: @faded-red !important;
    }

    lightgray-background {
      background-color: @lightgray;
    }

    //.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
    //.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary{
    //  color: @light;
    //  background-color: @steelblue;
    //  border-color: @steelblue;
    //}

  .foxtrot-tip, .foxtrot-tip-wide {
    font-family: Simplon !important;
    font-size: 16px;
    padding: 6px 8px;
    min-width: 50px;
  }

  .foxtrot-tip-wide {
    width: 300px;
  }

}
