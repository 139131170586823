@dark: #000;
@light: #fff;
@gray: #ccc;
@placeholder-gray: #B2B2B2;
@watermelon: #DB3B4B;
@darkblue: #213C65;
@vlightgray: #F5F5F5;
@lightgray: #F0F0F0;
@unavailable: #A9A9A9;
@ice-blue-color: #F7FAFA;
@steelblue: #36648B;
@graphiteGray: #363e45;
@steel_hover: #203C53;
@disabled_watermelon: #ED9DA5;
@light_watermelon: #F8D8DB;
@lightblue: #4D94DB;
@burntorange: #D66715;
@jam-red: #FF2E01;
@electric-yellow: #FFB706;
@faded-green: #C5E3BF;
@faded-red: #f2dede;
@red: #D14;
@error: #a94442;
@darkgray: darken(@unavailable, 10%);
@darkergray: #6f6f6f;
@graybluelabel: #7D7D8E;
@green: #00CC00;
@yellow: #dfdd24;

// route status colors
@route-finalized: lighten(@lightblue, 30%);

// delivery status colors
@delivery-success: #00B155;
@delivery-failed: #ED4957;
@delivery-attempted: #718290;
@delivery-current: #62ABF4;
@delivery-visit-later: #F7AF00;
@delivery-authorize-reattempt: #7F21FF;

// location confidence labels
@location-confidence-confident: #00B155;
@location-confidence-not-confident: #ED4957;

// Mobile App Colors
@app-login-background: #375065;
@app-light-red: #FD6659;

// User Confidence Labels
@user-confidence-confident: #39cc79;
@user-confidence-almost-confident: #dfdd24;
@user-confidence-not-confident: #cc5342;

// Route Warning Colors
@unroutable-route-color: @burntorange;
@pending-optimization-color: @darkergray;

// route viewer
@route-viewer-active: #47E9C9;
@route-viewer-bad: @jam-red;

.white {
  color: @light;
}

.green {
  color: @green;
}

.watermelon {
  color: @watermelon;
}

.darkgray {
  color: @darkgray;
}

.darkergray {
  color: darken(@darkgray, 15%);
}

.gray {
  color: gray;
}

.graybluelabel {
  color: @graybluelabel;
}

.optionalgray {
  color: darken(@lightgray, 5%);
}
