@media only screen {
  .ui-select-match .btn {
    text-transform: none;
  }
  .ui-select-container {
    text-align: left;
  }
  .form-control {
    position: static;
  }
  .datetime-picker-dropdown > li.date-picker-menu div > table .btn-default {
    border: 0;
  }
  .file-name {
    background-color: #fff;
  }
  .file-upload-btn {
    border-radius: 0 2px 2px 0;
  }
  .file-valid {
    background-color: #C5E3BF !important;
  }
  .file-invalid {
    background-color: #f2dede !important;
  }
  lightgray-background {
    background-color: #F0F0F0;
  }
  .foxtrot-tip,
  .foxtrot-tip-wide {
    font-family: Simplon !important;
    font-size: 16px;
    padding: 6px 8px;
    min-width: 50px;
  }
  .foxtrot-tip-wide {
    width: 300px;
  }
}
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-13 {
  font-size: 13px;
}
.font-16 {
  font-size: 16px;
}
.font-17 {
  font-size: 17px;
}
.font-18 {
  font-size: 18px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}
.font-26 {
  font-size: 26px;
}
.weight-400 {
  font-weight: 400;
}
.bold {
  font-weight: bold;
}
@font-face {
  font-family: 'Glyphicons Regular';
  src: url('../fonts/glyphicons-regular.eot');
  src: url('../fonts/glyphicons-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-regular.woff2') format('woff2'), url('../fonts/glyphicons-regular.woff') format('woff'), url('../fonts/glyphicons-regular.ttf') format('truetype'), url('../fonts/glyphicons-regular.svg#glyphiconsregular') format('svg');
}
.glyphicon {
  font-family: 'Glyphicons Regular' !important;
}
.glyphicon-glass:before {
  content: "\E001" !important;
}
.glyphicon-leaf:before {
  content: "\E002" !important;
}
.glyphicon-dog:before {
  content: "\E003" !important;
}
.glyphicon-user:before {
  content: "\E004" !important;
}
.glyphicon-girl:before {
  content: "\E005" !important;
}
.glyphicon-car:before {
  content: "\E006" !important;
}
.glyphicon-user-add:before {
  content: "\E007" !important;
}
.glyphicon-user-remove:before {
  content: "\E008" !important;
}
.glyphicon-film:before {
  content: "\E009" !important;
}
.glyphicon-magic:before {
  content: "\E010" !important;
}
.glyphicon-envelope:before {
  content: "\E011" !important;
}
.glyphicon-camera:before {
  content: "\E012" !important;
}
.glyphicon-heart:before {
  content: "\E013" !important;
}
.glyphicon-beach-umbrella:before {
  content: "\E014" !important;
}
.glyphicon-train:before {
  content: "\E015" !important;
}
.glyphicon-print:before {
  content: "\E016" !important;
}
.glyphicon-bin:before {
  content: "\E017" !important;
}
.glyphicon-music:before {
  content: "\E018" !important;
}
.glyphicon-music-alt:before {
  content: "\E019" !important;
}
.glyphicon-heart-empty:before {
  content: "\E020" !important;
}
.glyphicon-home:before {
  content: "\E021" !important;
}
.glyphicon-snowflake:before {
  content: "\E022" !important;
}
.glyphicon-fire:before {
  content: "\E023" !important;
}
.glyphicon-magnet:before {
  content: "\E024" !important;
}
.glyphicon-parents:before {
  content: "\E025" !important;
}
.glyphicon-binoculars:before {
  content: "\E026" !important;
}
.glyphicon-road:before {
  content: "\E027" !important;
}
.glyphicon-search:before {
  content: "\E028" !important;
}
.glyphicon-cars:before {
  content: "\E029" !important;
}
.glyphicon-notes-2:before {
  content: "\E030" !important;
}
.glyphicon-pencil:before {
  content: "\E031" !important;
}
.glyphicon-bus:before {
  content: "\E032" !important;
}
.glyphicon-wifi-alt:before {
  content: "\E033" !important;
}
.glyphicon-luggage:before {
  content: "\E034" !important;
}
.glyphicon-old-man:before {
  content: "\E035" !important;
}
.glyphicon-woman:before {
  content: "\E036" !important;
}
.glyphicon-file:before {
  content: "\E037" !important;
}
.glyphicon-coins:before {
  content: "\E038" !important;
}
.glyphicon-plane:before {
  content: "\E039" !important;
}
.glyphicon-notes:before {
  content: "\E040" !important;
}
.glyphicon-stats:before {
  content: "\E041" !important;
}
.glyphicon-charts:before {
  content: "\E042" !important;
}
.glyphicon-pie-chart:before {
  content: "\E043" !important;
}
.glyphicon-group:before {
  content: "\E044" !important;
}
.glyphicon-keys:before {
  content: "\E045" !important;
}
.glyphicon-calendar:before {
  content: "\E046" !important;
}
.glyphicon-router:before {
  content: "\E047" !important;
}
.glyphicon-camera-small:before {
  content: "\E048" !important;
}
.glyphicon-star-empty:before {
  content: "\E049" !important;
}
.glyphicon-star:before {
  content: "\E050" !important;
}
.glyphicon-link:before {
  content: "\E051" !important;
}
.glyphicon-eye-open:before {
  content: "\E052" !important;
}
.glyphicon-eye-close:before {
  content: "\E053" !important;
}
.glyphicon-alarm:before {
  content: "\E054" !important;
}
.glyphicon-clock:before {
  content: "\E055" !important;
}
.glyphicon-stopwatch:before {
  content: "\E056" !important;
}
.glyphicon-projector:before {
  content: "\E057" !important;
}
.glyphicon-history:before {
  content: "\E058" !important;
}
.glyphicon-truck:before {
  content: "\E059" !important;
}
.glyphicon-cargo:before {
  content: "\E060" !important;
}
.glyphicon-compass:before {
  content: "\E061" !important;
}
.glyphicon-keynote:before {
  content: "\E062" !important;
}
.glyphicon-paperclip:before {
  content: "\E063" !important;
}
.glyphicon-power:before {
  content: "\E064" !important;
}
.glyphicon-lightbulb:before {
  content: "\E065" !important;
}
.glyphicon-tag:before {
  content: "\E066" !important;
}
.glyphicon-tags:before {
  content: "\E067" !important;
}
.glyphicon-cleaning:before {
  content: "\E068" !important;
}
.glyphicon-ruler:before {
  content: "\E069" !important;
}
.glyphicon-gift:before {
  content: "\E070" !important;
}
.glyphicon-umbrella:before {
  content: "\E071" !important;
}
.glyphicon-book:before {
  content: "\E072" !important;
}
.glyphicon-bookmark:before {
  content: "\E073" !important;
}
.glyphicon-wifi:before {
  content: "\E074" !important;
}
.glyphicon-cup:before {
  content: "\E075" !important;
}
.glyphicon-stroller:before {
  content: "\E076" !important;
}
.glyphicon-headphones:before {
  content: "\E077" !important;
}
.glyphicon-headset:before {
  content: "\E078" !important;
}
.glyphicon-warning-sign:before {
  content: "\E079" !important;
}
.glyphicon-signal:before {
  content: "\E080" !important;
}
.glyphicon-retweet:before {
  content: "\E081" !important;
}
.glyphicon-refresh:before {
  content: "\E082" !important;
}
.glyphicon-roundabout:before {
  content: "\E083" !important;
}
.glyphicon-random:before {
  content: "\E084" !important;
}
.glyphicon-heat:before {
  content: "\E085" !important;
}
.glyphicon-repeat:before {
  content: "\E086" !important;
}
.glyphicon-display:before {
  content: "\E087" !important;
}
.glyphicon-log-book:before {
  content: "\E088" !important;
}
.glyphicon-address-book:before {
  content: "\E089" !important;
}
.glyphicon-building:before {
  content: "\E090" !important;
}
.glyphicon-eyedropper:before {
  content: "\E091" !important;
}
.glyphicon-adjust:before {
  content: "\E092" !important;
}
.glyphicon-tint:before {
  content: "\E093" !important;
}
.glyphicon-crop:before {
  content: "\E094" !important;
}
.glyphicon-vector-path-square:before {
  content: "\E095" !important;
}
.glyphicon-vector-path-circle:before {
  content: "\E096" !important;
}
.glyphicon-vector-path-polygon:before {
  content: "\E097" !important;
}
.glyphicon-vector-path-line:before {
  content: "\E098" !important;
}
.glyphicon-vector-path-curve:before {
  content: "\E099" !important;
}
.glyphicon-vector-path-all:before {
  content: "\E100" !important;
}
.glyphicon-font:before {
  content: "\E101" !important;
}
.glyphicon-italic:before {
  content: "\E102" !important;
}
.glyphicon-bold:before {
  content: "\E103" !important;
}
.glyphicon-text-underline:before {
  content: "\E104" !important;
}
.glyphicon-text-strike:before {
  content: "\E105" !important;
}
.glyphicon-text-height:before {
  content: "\E106" !important;
}
.glyphicon-text-width:before {
  content: "\E107" !important;
}
.glyphicon-text-resize:before {
  content: "\E108" !important;
}
.glyphicon-left-indent:before {
  content: "\E109" !important;
}
.glyphicon-right-indent:before {
  content: "\E110" !important;
}
.glyphicon-align-left:before {
  content: "\E111" !important;
}
.glyphicon-align-center:before {
  content: "\E112" !important;
}
.glyphicon-align-right:before {
  content: "\E113" !important;
}
.glyphicon-justify:before {
  content: "\E114" !important;
}
.glyphicon-list:before {
  content: "\E115" !important;
}
.glyphicon-text-smaller:before {
  content: "\E116" !important;
}
.glyphicon-text-bigger:before {
  content: "\E117" !important;
}
.glyphicon-embed:before {
  content: "\E118" !important;
}
.glyphicon-embed-close:before {
  content: "\E119" !important;
}
.glyphicon-table:before {
  content: "\E120" !important;
}
.glyphicon-message-full:before {
  content: "\E121" !important;
}
.glyphicon-message-empty:before {
  content: "\E122" !important;
}
.glyphicon-message-in:before {
  content: "\E123" !important;
}
.glyphicon-message-out:before {
  content: "\E124" !important;
}
.glyphicon-message-plus:before {
  content: "\E125" !important;
}
.glyphicon-message-minus:before {
  content: "\E126" !important;
}
.glyphicon-message-ban:before {
  content: "\E127" !important;
}
.glyphicon-message-flag:before {
  content: "\E128" !important;
}
.glyphicon-message-lock:before {
  content: "\E129" !important;
}
.glyphicon-message-new:before {
  content: "\E130" !important;
}
.glyphicon-inbox:before {
  content: "\E131" !important;
}
.glyphicon-inbox-plus:before {
  content: "\E132" !important;
}
.glyphicon-inbox-minus:before {
  content: "\E133" !important;
}
.glyphicon-inbox-lock:before {
  content: "\E134" !important;
}
.glyphicon-inbox-in:before {
  content: "\E135" !important;
}
.glyphicon-inbox-out:before {
  content: "\E136" !important;
}
.glyphicon-cogwheel:before {
  content: "\E137" !important;
}
.glyphicon-cogwheels:before {
  content: "\E138" !important;
}
.glyphicon-picture:before {
  content: "\E139" !important;
}
.glyphicon-adjust-alt:before {
  content: "\E140" !important;
}
.glyphicon-database-lock:before {
  content: "\E141" !important;
}
.glyphicon-database-plus:before {
  content: "\E142" !important;
}
.glyphicon-database-minus:before {
  content: "\E143" !important;
}
.glyphicon-database-ban:before {
  content: "\E144" !important;
}
.glyphicon-folder-open:before {
  content: "\E145" !important;
}
.glyphicon-folder-plus:before {
  content: "\E146" !important;
}
.glyphicon-folder-minus:before {
  content: "\E147" !important;
}
.glyphicon-folder-lock:before {
  content: "\E148" !important;
}
.glyphicon-folder-flag:before {
  content: "\E149" !important;
}
.glyphicon-folder-new:before {
  content: "\E150" !important;
}
.glyphicon-edit:before {
  content: "\E151" !important;
}
.glyphicon-new-window:before {
  content: "\E152" !important;
}
.glyphicon-check:before {
  content: "\E153" !important;
}
.glyphicon-unchecked:before {
  content: "\E154" !important;
}
.glyphicon-more-windows:before {
  content: "\E155" !important;
}
.glyphicon-show-big-thumbnails:before {
  content: "\E156" !important;
}
.glyphicon-show-thumbnails:before {
  content: "\E157" !important;
}
.glyphicon-show-thumbnails-with-lines:before {
  content: "\E158" !important;
}
.glyphicon-show-lines:before {
  content: "\E159" !important;
}
.glyphicon-playlist:before {
  content: "\E160" !important;
}
.glyphicon-imac:before {
  content: "\E161" !important;
}
.glyphicon-macbook:before {
  content: "\E162" !important;
}
.glyphicon-ipad:before {
  content: "\E163" !important;
}
.glyphicon-iphone:before {
  content: "\E164" !important;
}
.glyphicon-iphone-transfer:before {
  content: "\E165" !important;
}
.glyphicon-iphone-exchange:before {
  content: "\E166" !important;
}
.glyphicon-ipod:before {
  content: "\E167" !important;
}
.glyphicon-ipod-shuffle:before {
  content: "\E168" !important;
}
.glyphicon-ear-plugs:before {
  content: "\E169" !important;
}
.glyphicon-record:before {
  content: "\E170" !important;
}
.glyphicon-step-backward:before {
  content: "\E171" !important;
}
.glyphicon-fast-backward:before {
  content: "\E172" !important;
}
.glyphicon-rewind:before {
  content: "\E173" !important;
}
.glyphicon-play:before {
  content: "\E174" !important;
}
.glyphicon-pause:before {
  content: "\E175" !important;
}
.glyphicon-stop:before {
  content: "\E176" !important;
}
.glyphicon-forward:before {
  content: "\E177" !important;
}
.glyphicon-fast-forward:before {
  content: "\E178" !important;
}
.glyphicon-step-forward:before {
  content: "\E179" !important;
}
.glyphicon-eject:before {
  content: "\E180" !important;
}
.glyphicon-facetime-video:before {
  content: "\E181" !important;
}
.glyphicon-download-alt:before {
  content: "\E182" !important;
}
.glyphicon-mute:before {
  content: "\E183" !important;
}
.glyphicon-volume-down:before {
  content: "\E184" !important;
}
.glyphicon-volume-up:before {
  content: "\E185" !important;
}
.glyphicon-screenshot:before {
  content: "\E186" !important;
}
.glyphicon-move:before {
  content: "\E187" !important;
}
.glyphicon-more:before {
  content: "\E188" !important;
}
.glyphicon-brightness-reduce:before {
  content: "\E189" !important;
}
.glyphicon-brightness-increase:before {
  content: "\E190" !important;
}
.glyphicon-plus-sign:before {
  content: "\E191" !important;
}
.glyphicon-minus-sign:before {
  content: "\E192" !important;
}
.glyphicon-remove-sign:before {
  content: "\E193" !important;
}
.glyphicon-ok-sign:before {
  content: "\E194" !important;
}
.glyphicon-question-sign:before {
  content: "\E195" !important;
}
.glyphicon-info-sign:before {
  content: "\E196" !important;
}
.glyphicon-exclamation-sign:before {
  content: "\E197" !important;
}
.glyphicon-remove-circle:before {
  content: "\E198" !important;
}
.glyphicon-ok-circle:before {
  content: "\E199" !important;
}
.glyphicon-ban-circle:before {
  content: "\E200" !important;
}
.glyphicon-download:before {
  content: "\E201" !important;
}
.glyphicon-upload:before {
  content: "\E202" !important;
}
.glyphicon-shopping-cart:before {
  content: "\E203" !important;
}
.glyphicon-lock:before {
  content: "\E204" !important;
}
.glyphicon-unlock:before {
  content: "\E205" !important;
}
.glyphicon-electricity:before {
  content: "\E206" !important;
}
.glyphicon-ok:before {
  content: "\E207" !important;
}
.glyphicon-remove:before {
  content: "\E208" !important;
}
.glyphicon-cart-in:before {
  content: "\E209" !important;
}
.glyphicon-cart-out:before {
  content: "\E210" !important;
}
.glyphicon-arrow-left:before {
  content: "\E211" !important;
}
.glyphicon-arrow-right:before {
  content: "\E212" !important;
}
.glyphicon-arrow-down:before {
  content: "\E213" !important;
}
.glyphicon-arrow-up:before {
  content: "\E214" !important;
}
.glyphicon-resize-small:before {
  content: "\E215" !important;
}
.glyphicon-resize-full:before {
  content: "\E216" !important;
}
.glyphicon-circle-arrow-left:before {
  content: "\E217" !important;
}
.glyphicon-circle-arrow-right:before {
  content: "\E218" !important;
}
.glyphicon-circle-arrow-top:before {
  content: "\E219" !important;
}
.glyphicon-circle-arrow-down:before {
  content: "\E220" !important;
}
.glyphicon-play-button:before {
  content: "\E221" !important;
}
.glyphicon-unshare:before {
  content: "\E222" !important;
}
.glyphicon-share:before {
  content: "\E223" !important;
}
.glyphicon-chevron-right:before {
  content: "\E224" !important;
}
.glyphicon-chevron-left:before {
  content: "\E225" !important;
}
.glyphicon-bluetooth:before {
  content: "\E226" !important;
}
.glyphicon-euro:before {
  content: "\E227" !important;
}
.glyphicon-usd:before {
  content: "\E228" !important;
}
.glyphicon-gbp:before {
  content: "\E229" !important;
}
.glyphicon-retweet-2:before {
  content: "\E230" !important;
}
.glyphicon-moon:before {
  content: "\E231" !important;
}
.glyphicon-sun:before {
  content: "\E232" !important;
}
.glyphicon-cloud:before {
  content: "\E233" !important;
}
.glyphicon-direction:before {
  content: "\E234" !important;
}
.glyphicon-brush:before {
  content: "\E235" !important;
}
.glyphicon-pen:before {
  content: "\E236" !important;
}
.glyphicon-zoom-in:before {
  content: "\E237" !important;
}
.glyphicon-zoom-out:before {
  content: "\E238" !important;
}
.glyphicon-pin:before {
  content: "\E239" !important;
}
.glyphicon-albums:before {
  content: "\E240" !important;
}
.glyphicon-rotation-lock:before {
  content: "\E241" !important;
}
.glyphicon-flash:before {
  content: "\E242" !important;
}
.glyphicon-map-marker:before {
  content: "\E243" !important;
}
.glyphicon-anchor:before {
  content: "\E244" !important;
}
.glyphicon-conversation:before {
  content: "\E245" !important;
}
.glyphicon-chat:before {
  content: "\E246" !important;
}
.glyphicon-note-empty:before {
  content: "\E247" !important;
}
.glyphicon-note:before {
  content: "\E248" !important;
}
.glyphicon-asterisk:before {
  content: "\E249" !important;
}
.glyphicon-divide:before {
  content: "\E250" !important;
}
.glyphicon-snorkel-diving:before {
  content: "\E251" !important;
}
.glyphicon-scuba-diving:before {
  content: "\E252" !important;
}
.glyphicon-oxygen-bottle:before {
  content: "\E253" !important;
}
.glyphicon-fins:before {
  content: "\E254" !important;
}
.glyphicon-fishes:before {
  content: "\E255" !important;
}
.glyphicon-boat:before {
  content: "\E256" !important;
}
.glyphicon-delete:before {
  content: "\E257" !important;
}
.glyphicon-sheriffs-star:before {
  content: "\E258" !important;
}
.glyphicon-qrcode:before {
  content: "\E259" !important;
}
.glyphicon-barcode:before {
  content: "\E260" !important;
}
.glyphicon-pool:before {
  content: "\E261" !important;
}
.glyphicon-buoy:before {
  content: "\E262" !important;
}
.glyphicon-spade:before {
  content: "\E263" !important;
}
.glyphicon-bank:before {
  content: "\E264" !important;
}
.glyphicon-vcard:before {
  content: "\E265" !important;
}
.glyphicon-electrical-plug:before {
  content: "\E266" !important;
}
.glyphicon-flag:before {
  content: "\E267" !important;
}
.glyphicon-credit-card:before {
  content: "\E268" !important;
}
.glyphicon-keyboard-wireless:before {
  content: "\E269" !important;
}
.glyphicon-keyboard-wired:before {
  content: "\E270" !important;
}
.glyphicon-shield:before {
  content: "\E271" !important;
}
.glyphicon-ring:before {
  content: "\E272" !important;
}
.glyphicon-cake:before {
  content: "\E273" !important;
}
.glyphicon-drink:before {
  content: "\E274" !important;
}
.glyphicon-beer:before {
  content: "\E275" !important;
}
.glyphicon-fast-food:before {
  content: "\E276" !important;
}
.glyphicon-cutlery:before {
  content: "\E277" !important;
}
.glyphicon-pizza:before {
  content: "\E278" !important;
}
.glyphicon-birthday-cake:before {
  content: "\E279" !important;
}
.glyphicon-tablet:before {
  content: "\E280" !important;
}
.glyphicon-settings:before {
  content: "\E281" !important;
}
.glyphicon-bullets:before {
  content: "\E282" !important;
}
.glyphicon-cardio:before {
  content: "\E283" !important;
}
.glyphicon-t-shirt:before {
  content: "\E284" !important;
}
.glyphicon-pants:before {
  content: "\E285" !important;
}
.glyphicon-sweater:before {
  content: "\E286" !important;
}
.glyphicon-fabric:before {
  content: "\E287" !important;
}
.glyphicon-leather:before {
  content: "\E288" !important;
}
.glyphicon-scissors:before {
  content: "\E289" !important;
}
.glyphicon-bomb:before {
  content: "\E290" !important;
}
.glyphicon-skull:before {
  content: "\E291" !important;
}
.glyphicon-celebration:before {
  content: "\E292" !important;
}
.glyphicon-tea-kettle:before {
  content: "\E293" !important;
}
.glyphicon-french-press:before {
  content: "\E294" !important;
}
.glyphicon-coffee-cup:before {
  content: "\E295" !important;
}
.glyphicon-pot:before {
  content: "\E296" !important;
}
.glyphicon-grater:before {
  content: "\E297" !important;
}
.glyphicon-kettle:before {
  content: "\E298" !important;
}
.glyphicon-hospital:before {
  content: "\E299" !important;
}
.glyphicon-hospital-h:before {
  content: "\E300" !important;
}
.glyphicon-microphone:before {
  content: "\E301" !important;
}
.glyphicon-webcam:before {
  content: "\E302" !important;
}
.glyphicon-temple-christianity-church:before {
  content: "\E303" !important;
}
.glyphicon-temple-islam:before {
  content: "\E304" !important;
}
.glyphicon-temple-hindu:before {
  content: "\E305" !important;
}
.glyphicon-temple-buddhist:before {
  content: "\E306" !important;
}
.glyphicon-bicycle:before {
  content: "\E307" !important;
}
.glyphicon-life-preserver:before {
  content: "\E308" !important;
}
.glyphicon-share-alt:before {
  content: "\E309" !important;
}
.glyphicon-comments:before {
  content: "\E310" !important;
}
.glyphicon-flower:before {
  content: "\E311" !important;
}
.glyphicon-baseball:before {
  content: "\E312" !important;
}
.glyphicon-rugby:before {
  content: "\E313" !important;
}
.glyphicon-ax:before {
  content: "\E314" !important;
}
.glyphicon-table-tennis:before {
  content: "\E315" !important;
}
.glyphicon-bowling:before {
  content: "\E316" !important;
}
.glyphicon-tree-conifer:before {
  content: "\E317" !important;
}
.glyphicon-tree-deciduous:before {
  content: "\E318" !important;
}
.glyphicon-more-items:before {
  content: "\E319" !important;
}
.glyphicon-sort:before {
  content: "\E320" !important;
}
.glyphicon-filter:before {
  content: "\E321" !important;
}
.glyphicon-gamepad:before {
  content: "\E322" !important;
}
.glyphicon-playing-dices:before {
  content: "\E323" !important;
}
.glyphicon-calculator:before {
  content: "\E324" !important;
}
.glyphicon-tie:before {
  content: "\E325" !important;
}
.glyphicon-wallet:before {
  content: "\E326" !important;
}
.glyphicon-piano:before {
  content: "\E327" !important;
}
.glyphicon-sampler:before {
  content: "\E328" !important;
}
.glyphicon-podium:before {
  content: "\E329" !important;
}
.glyphicon-soccer-ball:before {
  content: "\E330" !important;
}
.glyphicon-blog:before {
  content: "\E331" !important;
}
.glyphicon-dashboard:before {
  content: "\E332" !important;
}
.glyphicon-certificate:before {
  content: "\E333" !important;
}
.glyphicon-bell:before {
  content: "\E334" !important;
}
.glyphicon-candle:before {
  content: "\E335" !important;
}
.glyphicon-pushpin:before {
  content: "\E336" !important;
}
.glyphicon-iphone-shake:before {
  content: "\E337" !important;
}
.glyphicon-pin-flag:before {
  content: "\E338" !important;
}
.glyphicon-turtle:before {
  content: "\E339" !important;
}
.glyphicon-rabbit:before {
  content: "\E340" !important;
}
.glyphicon-globe:before {
  content: "\E341" !important;
}
.glyphicon-briefcase:before {
  content: "\E342" !important;
}
.glyphicon-hdd:before {
  content: "\E343" !important;
}
.glyphicon-thumbs-up:before {
  content: "\E344" !important;
}
.glyphicon-thumbs-down:before {
  content: "\E345" !important;
}
.glyphicon-hand-right:before {
  content: "\E346" !important;
}
.glyphicon-hand-left:before {
  content: "\E347" !important;
}
.glyphicon-hand-up:before {
  content: "\E348" !important;
}
.glyphicon-hand-down:before {
  content: "\E349" !important;
}
.glyphicon-fullscreen:before {
  content: "\E350" !important;
}
.glyphicon-shopping-bag:before {
  content: "\E351" !important;
}
.glyphicon-book-open:before {
  content: "\E352" !important;
}
.glyphicon-nameplate:before {
  content: "\E353" !important;
}
.glyphicon-nameplate-alt:before {
  content: "\E354" !important;
}
.glyphicon-vases:before {
  content: "\E355" !important;
}
.glyphicon-bullhorn:before {
  content: "\E356" !important;
}
.glyphicon-dumbbell:before {
  content: "\E357" !important;
}
.glyphicon-suitcase:before {
  content: "\E358" !important;
}
.glyphicon-file-import:before {
  content: "\E359" !important;
}
.glyphicon-file-export:before {
  content: "\E360" !important;
}
.glyphicon-bug:before {
  content: "\E361" !important;
}
.glyphicon-crown:before {
  content: "\E362" !important;
}
.glyphicon-smoking:before {
  content: "\E363" !important;
}
.glyphicon-cloud-upload:before {
  content: "\E364" !important;
}
.glyphicon-cloud-download:before {
  content: "\E365" !important;
}
.glyphicon-restart:before {
  content: "\E366" !important;
}
.glyphicon-security-camera:before {
  content: "\E367" !important;
}
.glyphicon-expand:before {
  content: "\E368" !important;
}
.glyphicon-collapse:before {
  content: "\E369" !important;
}
.glyphicon-collapse-top:before {
  content: "\E370" !important;
}
.glyphicon-globe-af:before {
  content: "\E371" !important;
}
.glyphicon-global:before {
  content: "\E372" !important;
}
.glyphicon-spray:before {
  content: "\E373" !important;
}
.glyphicon-nails:before {
  content: "\E374" !important;
}
.glyphicon-claw-hammer:before {
  content: "\E375" !important;
}
.glyphicon-classic-hammer:before {
  content: "\E376" !important;
}
.glyphicon-hand-saw:before {
  content: "\E377" !important;
}
.glyphicon-riflescope:before {
  content: "\E378" !important;
}
.glyphicon-electrical-socket-eu:before {
  content: "\E379" !important;
}
.glyphicon-electrical-socket-us:before {
  content: "\E380" !important;
}
.glyphicon-message-forward:before {
  content: "\E381" !important;
}
.glyphicon-coat-hanger:before {
  content: "\E382" !important;
}
.glyphicon-dress:before {
  content: "\E383" !important;
}
.glyphicon-bathrobe:before {
  content: "\E384" !important;
}
.glyphicon-shirt:before {
  content: "\E385" !important;
}
.glyphicon-underwear:before {
  content: "\E386" !important;
}
.glyphicon-log-in:before {
  content: "\E387" !important;
}
.glyphicon-log-out:before {
  content: "\E388" !important;
}
.glyphicon-exit:before {
  content: "\E389" !important;
}
.glyphicon-new-window-alt:before {
  content: "\E390" !important;
}
.glyphicon-video-sd:before {
  content: "\E391" !important;
}
.glyphicon-video-hd:before {
  content: "\E392" !important;
}
.glyphicon-subtitles:before {
  content: "\E393" !important;
}
.glyphicon-sound-stereo:before {
  content: "\E394" !important;
}
.glyphicon-sound-dolby:before {
  content: "\E395" !important;
}
.glyphicon-sound-5-1:before {
  content: "\E396" !important;
}
.glyphicon-sound-6-1:before {
  content: "\E397" !important;
}
.glyphicon-sound-7-1:before {
  content: "\E398" !important;
}
.glyphicon-copyright-mark:before {
  content: "\E399" !important;
}
.glyphicon-registration-mark:before {
  content: "\E400" !important;
}
.glyphicon-radar:before {
  content: "\E401" !important;
}
.glyphicon-skateboard:before {
  content: "\E402" !important;
}
.glyphicon-golf-course:before {
  content: "\E403" !important;
}
.glyphicon-sorting:before {
  content: "\E404" !important;
}
.glyphicon-sort-by-alphabet:before {
  content: "\E405" !important;
}
.glyphicon-sort-by-alphabet-alt:before {
  content: "\E406" !important;
}
.glyphicon-sort-by-order:before {
  content: "\E407" !important;
}
.glyphicon-sort-by-order-alt:before {
  content: "\E408" !important;
}
.glyphicon-sort-by-attributes:before {
  content: "\E409" !important;
}
.glyphicon-sort-by-attributes-alt:before {
  content: "\E410" !important;
}
.glyphicon-compressed:before {
  content: "\E411" !important;
}
.glyphicon-package:before {
  content: "\E412" !important;
}
.glyphicon-cloud-plus:before {
  content: "\E413" !important;
}
.glyphicon-cloud-minus:before {
  content: "\E414" !important;
}
.glyphicon-disk-save:before {
  content: "\E415" !important;
}
.glyphicon-disk-open:before {
  content: "\E416" !important;
}
.glyphicon-disk-saved:before {
  content: "\E417" !important;
}
.glyphicon-disk-remove:before {
  content: "\E418" !important;
}
.glyphicon-disk-import:before {
  content: "\E419" !important;
}
.glyphicon-disk-export:before {
  content: "\E420" !important;
}
.glyphicon-tower:before {
  content: "\E421" !important;
}
.glyphicon-send:before {
  content: "\E422" !important;
}
.glyphicon-git-branch:before {
  content: "\E423" !important;
}
.glyphicon-git-create:before {
  content: "\E424" !important;
}
.glyphicon-git-private:before {
  content: "\E425" !important;
}
.glyphicon-git-delete:before {
  content: "\E426" !important;
}
.glyphicon-git-merge:before {
  content: "\E427" !important;
}
.glyphicon-git-pull-request:before {
  content: "\E428" !important;
}
.glyphicon-git-compare:before {
  content: "\E429" !important;
}
.glyphicon-git-commit:before {
  content: "\E430" !important;
}
.glyphicon-construction-cone:before {
  content: "\E431" !important;
}
.glyphicon-shoe-steps:before {
  content: "\E432" !important;
}
.glyphicon-plus:before {
  content: "\E433" !important;
}
.glyphicon-minus:before {
  content: "\E434" !important;
}
.glyphicon-redo:before {
  content: "\E435" !important;
}
.glyphicon-undo:before {
  content: "\E436" !important;
}
.glyphicon-golf:before {
  content: "\E437" !important;
}
.glyphicon-hockey:before {
  content: "\E438" !important;
}
.glyphicon-pipe:before {
  content: "\E439" !important;
}
.glyphicon-wrench:before {
  content: "\E440" !important;
}
.glyphicon-folder-closed:before {
  content: "\E441" !important;
}
.glyphicon-phone-alt:before {
  content: "\E442" !important;
}
.glyphicon-earphone:before {
  content: "\E443" !important;
}
.glyphicon-floppy-disk:before {
  content: "\E444" !important;
}
.glyphicon-floppy-saved:before {
  content: "\E445" !important;
}
.glyphicon-floppy-remove:before {
  content: "\E446" !important;
}
.glyphicon-floppy-save:before {
  content: "\E447" !important;
}
.glyphicon-floppy-open:before {
  content: "\E448" !important;
}
.glyphicon-translate:before {
  content: "\E449" !important;
}
.glyphicon-fax:before {
  content: "\E450" !important;
}
.glyphicon-factory:before {
  content: "\E451" !important;
}
.glyphicon-shop-window:before {
  content: "\E452" !important;
}
.glyphicon-shop:before {
  content: "\E453" !important;
}
.glyphicon-kiosk:before {
  content: "\E454" !important;
}
.glyphicon-kiosk-wheels:before {
  content: "\E455" !important;
}
.glyphicon-kiosk-light:before {
  content: "\E456" !important;
}
.glyphicon-kiosk-food:before {
  content: "\E457" !important;
}
.glyphicon-transfer:before {
  content: "\E458" !important;
}
.glyphicon-money:before {
  content: "\E459" !important;
}
.glyphicon-header:before {
  content: "\E460" !important;
}
.glyphicon-blacksmith:before {
  content: "\E461" !important;
}
.glyphicon-saw-blade:before {
  content: "\E462" !important;
}
.glyphicon-basketball:before {
  content: "\E463" !important;
}
.glyphicon-server:before {
  content: "\E464" !important;
}
.glyphicon-server-plus:before {
  content: "\E465" !important;
}
.glyphicon-server-minus:before {
  content: "\E466" !important;
}
.glyphicon-server-ban:before {
  content: "\E467" !important;
}
.glyphicon-server-flag:before {
  content: "\E468" !important;
}
.glyphicon-server-lock:before {
  content: "\E469" !important;
}
.glyphicon-server-new:before {
  content: "\E470" !important;
}
.glyphicon-charging-station:before {
  content: "\E471" !important;
}
.glyphicon-gas-station:before {
  content: "\E472" !important;
}
.glyphicon-target:before {
  content: "\E473" !important;
}
.glyphicon-bed:before {
  content: "\E474" !important;
}
.glyphicon-mosquito:before {
  content: "\E475" !important;
}
.glyphicon-dining-set:before {
  content: "\E476" !important;
}
.glyphicon-plate-of-food:before {
  content: "\E477" !important;
}
.glyphicon-hygiene-kit:before {
  content: "\E478" !important;
}
.glyphicon-blackboard:before {
  content: "\E479" !important;
}
.glyphicon-marriage:before {
  content: "\E480" !important;
}
.glyphicon-bucket:before {
  content: "\E481" !important;
}
.glyphicon-none-color-swatch:before {
  content: "\E482" !important;
}
.glyphicon-bring-forward:before {
  content: "\E483" !important;
}
.glyphicon-bring-to-front:before {
  content: "\E484" !important;
}
.glyphicon-send-backward:before {
  content: "\E485" !important;
}
.glyphicon-send-to-back:before {
  content: "\E486" !important;
}
.glyphicon-fit-frame-to-image:before {
  content: "\E487" !important;
}
.glyphicon-fit-image-to-frame:before {
  content: "\E488" !important;
}
.glyphicon-multiple-displays:before {
  content: "\E489" !important;
}
.glyphicon-handshake:before {
  content: "\E490" !important;
}
.glyphicon-child:before {
  content: "\E491" !important;
}
.glyphicon-baby-formula:before {
  content: "\E492" !important;
}
.glyphicon-medicine:before {
  content: "\E493" !important;
}
.glyphicon-atv-vehicle:before {
  content: "\E494" !important;
}
.glyphicon-motorcycle:before {
  content: "\E495" !important;
}
.glyphicon-bed-alt:before {
  content: "\E496" !important;
}
.glyphicon-tent:before {
  content: "\E497" !important;
}
.glyphicon-glasses:before {
  content: "\E498" !important;
}
.glyphicon-sunglasses:before {
  content: "\E499" !important;
}
.glyphicon-family:before {
  content: "\E500" !important;
}
.glyphicon-education:before {
  content: "\E501" !important;
}
.glyphicon-shoes:before {
  content: "\E502" !important;
}
.glyphicon-map:before {
  content: "\E503" !important;
}
.glyphicon-cd:before {
  content: "\E504" !important;
}
.glyphicon-alert:before {
  content: "\E505" !important;
}
.glyphicon-piggy-bank:before {
  content: "\E506" !important;
}
.glyphicon-star-half:before {
  content: "\E507" !important;
}
.glyphicon-cluster:before {
  content: "\E508" !important;
}
.glyphicon-flowchart:before {
  content: "\E509" !important;
}
.glyphicon-commodities:before {
  content: "\E510" !important;
}
.glyphicon-duplicate:before {
  content: "\E511" !important;
}
.glyphicon-copy:before {
  content: "\E512" !important;
}
.glyphicon-paste:before {
  content: "\E513" !important;
}
.glyphicon-bath-bathtub:before {
  content: "\E514" !important;
}
.glyphicon-bath-shower:before {
  content: "\E515" !important;
}
.glyphicon-shower:before {
  content: "\E516" !important;
}
.glyphicon-menu-hamburger:before {
  content: "\E517" !important;
}
.glyphicon-option-vertical:before {
  content: "\E518" !important;
}
.glyphicon-option-horizontal:before {
  content: "\E519" !important;
}
.glyphicon-currency-conversion:before {
  content: "\E520" !important;
}
.glyphicon-user-ban:before {
  content: "\E521" !important;
}
.glyphicon-user-lock:before {
  content: "\E522" !important;
}
.glyphicon-user-flag:before {
  content: "\E523" !important;
}
.glyphicon-user-asterisk:before {
  content: "\E524" !important;
}
.glyphicon-user-alert:before {
  content: "\E525" !important;
}
.glyphicon-user-key:before {
  content: "\E526" !important;
}
.glyphicon-user-conversation:before {
  content: "\E527" !important;
}
.glyphicon-database:before {
  content: "\E528" !important;
}
.glyphicon-database-search:before {
  content: "\E529" !important;
}
.glyphicon-list-alt:before {
  content: "\E530" !important;
}
.glyphicon-hazard-sign:before {
  content: "\E531" !important;
}
.glyphicon-hazard:before {
  content: "\E532" !important;
}
.glyphicon-stop-sign:before {
  content: "\E533" !important;
}
.glyphicon-lab:before {
  content: "\E534" !important;
}
.glyphicon-lab-alt:before {
  content: "\E535" !important;
}
.glyphicon-ice-cream:before {
  content: "\E536" !important;
}
.glyphicon-ice-lolly:before {
  content: "\E537" !important;
}
.glyphicon-ice-lolly-tasted:before {
  content: "\E538" !important;
}
.glyphicon-invoice:before {
  content: "\E539" !important;
}
.glyphicon-cart-tick:before {
  content: "\E540" !important;
}
.glyphicon-hourglass:before {
  content: "\E541" !important;
}
.glyphicon-cat:before {
  content: "\E542" !important;
}
.glyphicon-lamp:before {
  content: "\E543" !important;
}
.glyphicon-scale-classic:before {
  content: "\E544" !important;
}
.glyphicon-eye-plus:before {
  content: "\E545" !important;
}
.glyphicon-eye-minus:before {
  content: "\E546" !important;
}
.glyphicon-quote:before {
  content: "\E547" !important;
}
.glyphicon-bitcoin:before {
  content: "\E548" !important;
}
.glyphicon-yen:before {
  content: "\E549" !important;
}
.glyphicon-ruble:before {
  content: "\E550" !important;
}
.glyphicon-erase:before {
  content: "\E551" !important;
}
.glyphicon-podcast:before {
  content: "\E552" !important;
}
.glyphicon-firework:before {
  content: "\E553" !important;
}
.glyphicon-scale:before {
  content: "\E554" !important;
}
.glyphicon-king:before {
  content: "\E555" !important;
}
.glyphicon-queen:before {
  content: "\E556" !important;
}
.glyphicon-pawn:before {
  content: "\E557" !important;
}
.glyphicon-bishop:before {
  content: "\E558" !important;
}
.glyphicon-knight:before {
  content: "\E559" !important;
}
.glyphicon-mic-mute:before {
  content: "\E560" !important;
}
.glyphicon-voicemail:before {
  content: "\E561" !important;
}
.glyphicon-paragraph-alt:before {
  content: "\E562" !important;
}
.glyphicon-person-walking:before {
  content: "\E563" !important;
}
.glyphicon-person-wheelchair:before {
  content: "\E564" !important;
}
.glyphicon-underground:before {
  content: "\E565" !important;
}
.glyphicon-car-hov:before {
  content: "\E566" !important;
}
.glyphicon-car-rental:before {
  content: "\E567" !important;
}
.glyphicon-transport:before {
  content: "\E568" !important;
}
.glyphicon-taxi:before {
  content: "\E569" !important;
}
.glyphicon-ice-cream-no:before {
  content: "\E570" !important;
}
.glyphicon-uk-rat-u:before {
  content: "\E571" !important;
}
.glyphicon-uk-rat-pg:before {
  content: "\E572" !important;
}
.glyphicon-uk-rat-12a:before {
  content: "\E573" !important;
}
.glyphicon-uk-rat-12:before {
  content: "\E574" !important;
}
.glyphicon-uk-rat-15:before {
  content: "\E575" !important;
}
.glyphicon-uk-rat-18:before {
  content: "\E576" !important;
}
.glyphicon-uk-rat-r18:before {
  content: "\E577" !important;
}
.glyphicon-tv:before {
  content: "\E578" !important;
}
.glyphicon-sms:before {
  content: "\E579" !important;
}
.glyphicon-mms:before {
  content: "\E580" !important;
}
.glyphicon-us-rat-g:before {
  content: "\E581" !important;
}
.glyphicon-us-rat-pg:before {
  content: "\E582" !important;
}
.glyphicon-us-rat-pg-13:before {
  content: "\E583" !important;
}
.glyphicon-us-rat-restricted:before {
  content: "\E584" !important;
}
.glyphicon-us-rat-no-one-17:before {
  content: "\E585" !important;
}
.glyphicon-equalizer:before {
  content: "\E586" !important;
}
.glyphicon-speakers:before {
  content: "\E587" !important;
}
.glyphicon-remote-control:before {
  content: "\E588" !important;
}
.glyphicon-remote-control-tv:before {
  content: "\E589" !important;
}
.glyphicon-shredder:before {
  content: "\E590" !important;
}
.glyphicon-folder-heart:before {
  content: "\E591" !important;
}
.glyphicon-person-running:before {
  content: "\E592" !important;
}
.glyphicon-person:before {
  content: "\E593" !important;
}
.glyphicon-voice:before {
  content: "\E594" !important;
}
.glyphicon-stethoscope:before {
  content: "\E595" !important;
}
.glyphicon-paired:before {
  content: "\E596" !important;
}
.glyphicon-activity:before {
  content: "\E597" !important;
}
.glyphicon-watch:before {
  content: "\E598" !important;
}
.glyphicon-scissors-alt:before {
  content: "\E599" !important;
}
.glyphicon-car-wheel:before {
  content: "\E600" !important;
}
.glyphicon-chevron-up:before {
  content: "\E601" !important;
}
.glyphicon-chevron-down:before {
  content: "\E602" !important;
}
.glyphicon-superscript:before {
  content: "\E603" !important;
}
.glyphicon-subscript:before {
  content: "\E604" !important;
}
.glyphicon-text-size:before {
  content: "\E605" !important;
}
.glyphicon-text-color:before {
  content: "\E606" !important;
}
.glyphicon-text-background:before {
  content: "\E607" !important;
}
.glyphicon-modal-window:before {
  content: "\E608" !important;
}
.glyphicon-newspaper:before {
  content: "\E609" !important;
}
.glyphicon-tractor:before {
  content: "\E610" !important;
}
.glyphicon-camping:before {
  content: "\E611" !important;
}
.glyphicon-camping-benches:before {
  content: "\E612" !important;
}
.glyphicon-forest:before {
  content: "\E613" !important;
}
.glyphicon-park:before {
  content: "\E614" !important;
}
.glyphicon-field:before {
  content: "\E615" !important;
}
.glyphicon-mountains:before {
  content: "\E616" !important;
}
.glyphicon-fees-payments:before {
  content: "\E617" !important;
}
.glyphicon-small-payments:before {
  content: "\E618" !important;
}
.glyphicon-mixed-buildings:before {
  content: "\E619" !important;
}
.glyphicon-industrial-zone:before {
  content: "\E620" !important;
}
.glyphicon-visitor-tag:before {
  content: "\E621" !important;
}
.glyphicon-businessman:before {
  content: "\E622" !important;
}
.glyphicon-meditation:before {
  content: "\E623" !important;
}
.glyphicon-bath:before {
  content: "\E624" !important;
}
.glyphicon-donate:before {
  content: "\E625" !important;
}
.glyphicon-sauna:before {
  content: "\E626" !important;
}
.glyphicon-bedroom-nightstand:before {
  content: "\E627" !important;
}
.glyphicon-bedroom-lamp:before {
  content: "\E628" !important;
}
.glyphicon-doctor:before {
  content: "\E629" !important;
}
.glyphicon-engineering-networks:before {
  content: "\E630" !important;
}
.glyphicon-washing-machine:before {
  content: "\E631" !important;
}
.glyphicon-dryer:before {
  content: "\E632" !important;
}
.glyphicon-dishwasher:before {
  content: "\E633" !important;
}
.glyphicon-luggage-group:before {
  content: "\E634" !important;
}
.glyphicon-teenager:before {
  content: "\E635" !important;
}
.glyphicon-person-stick:before {
  content: "\E636" !important;
}
.glyphicon-person-stick-old:before {
  content: "\E637" !important;
}
.glyphicon-traveler:before {
  content: "\E638" !important;
}
.glyphicon-veteran:before {
  content: "\E639" !important;
}
.glyphicon-group-chat:before {
  content: "\E640" !important;
}
.glyphicon-elections:before {
  content: "\E641" !important;
}
.glyphicon-law-justice:before {
  content: "\E642" !important;
}
.glyphicon-judiciary:before {
  content: "\E643" !important;
}
.glyphicon-house-fire:before {
  content: "\E644" !important;
}
.glyphicon-firefighters:before {
  content: "\E645" !important;
}
.glyphicon-police:before {
  content: "\E646" !important;
}
.glyphicon-ambulance:before {
  content: "\E647" !important;
}
.glyphicon-light-beacon:before {
  content: "\E648" !important;
}
.glyphicon-important-day:before {
  content: "\E649" !important;
}
.glyphicon-bike-park:before {
  content: "\E650" !important;
}
.glyphicon-object-align-top:before {
  content: "\E651" !important;
}
.glyphicon-object-align-bottom:before {
  content: "\E652" !important;
}
.glyphicon-object-align-horizontal:before {
  content: "\E653" !important;
}
.glyphicon-object-align-left:before {
  content: "\E654" !important;
}
.glyphicon-object-align-vertical:before {
  content: "\E655" !important;
}
.glyphicon-object-align-right:before {
  content: "\E656" !important;
}
.glyphicon-reflect-y:before {
  content: "\E657" !important;
}
.glyphicon-reflect-x:before {
  content: "\E658" !important;
}
.glyphicon-tick:before {
  content: "\E659" !important;
}
.glyphicon-lawnmower:before {
  content: "\E660" !important;
}
.glyphicon-call-redirect:before {
  content: "\E661" !important;
}
.glyphicon-call-ip:before {
  content: "\E662" !important;
}
.glyphicon-call-record:before {
  content: "\E663" !important;
}
.glyphicon-call-ringtone:before {
  content: "\E664" !important;
}
.glyphicon-call-traffic:before {
  content: "\E665" !important;
}
.glyphicon-call-hold:before {
  content: "\E666" !important;
}
.glyphicon-call-incoming:before {
  content: "\E667" !important;
}
.glyphicon-call-outgoing:before {
  content: "\E668" !important;
}
.glyphicon-call-video:before {
  content: "\E669" !important;
}
.glyphicon-call-missed:before {
  content: "\E670" !important;
}
.glyphicon-theater:before {
  content: "\E671" !important;
}
.glyphicon-heartbeat:before {
  content: "\E672" !important;
}
.glyphicon-kettlebell:before {
  content: "\E673" !important;
}
.glyphicon-fireplace:before {
  content: "\E674" !important;
}
.glyphicon-street-lights:before {
  content: "\E675" !important;
}
.glyphicon-pedestrian:before {
  content: "\E676" !important;
}
.glyphicon-flood:before {
  content: "\E677" !important;
}
.glyphicon-open-water:before {
  content: "\E678" !important;
}
.glyphicon-for-sale:before {
  content: "\E679" !important;
}
.glyphicon-dustbin:before {
  content: "\E680" !important;
}
.glyphicon-door:before {
  content: "\E681" !important;
}
.glyphicon-camp-fire:before {
  content: "\E682" !important;
}
.glyphicon-fleur-de-lis:before {
  content: "\E683" !important;
}
.glyphicon-temperature-settings:before {
  content: "\E684" !important;
}
.glyphicon-article:before {
  content: "\E685" !important;
}
.glyphicon-sunbath:before {
  content: "\E686" !important;
}
.glyphicon-balanced-diet:before {
  content: "\E687" !important;
}
.glyphicon-ticket:before {
  content: "\E688" !important;
}
.glyphicon-parking-ticket:before {
  content: "\E689" !important;
}
.glyphicon-parking-meter:before {
  content: "\E690" !important;
}
.glyphicon-laptop:before {
  content: "\E691" !important;
}
.glyphicon-tree-structure:before {
  content: "\E692" !important;
}
.glyphicon-weather-warning:before {
  content: "\E693" !important;
}
.glyphicon-temperature-low:before {
  content: "\E694" !important;
}
.glyphicon-temperature-high:before {
  content: "\E695" !important;
}
.glyphicon-temperature-low-warning:before {
  content: "\E696" !important;
}
.glyphicon-temperature-high-warning:before {
  content: "\E697" !important;
}
.glyphicon-hurricane:before {
  content: "\E698" !important;
}
.glyphicon-storm:before {
  content: "\E699" !important;
}
.glyphicon-sorted-waste:before {
  content: "\E700" !important;
}
.glyphicon-ear:before {
  content: "\E701" !important;
}
.glyphicon-ear-deaf:before {
  content: "\E702" !important;
}
.glyphicon-file-plus:before {
  content: "\E703" !important;
}
.glyphicon-file-minus:before {
  content: "\E704" !important;
}
.glyphicon-file-lock:before {
  content: "\E705" !important;
}
.glyphicon-file-cloud:before {
  content: "\E706" !important;
}
.glyphicon-file-cloud-download:before {
  content: "\E707" !important;
}
.glyphicon-file-cloud-upload:before {
  content: "\E708" !important;
}
.glyphicon-paragraph:before {
  content: "\E709" !important;
}
.glyphicon-list-numbered:before {
  content: "\E710" !important;
}
.glyphicon-donate-heart:before {
  content: "\E711" !important;
}
.glyphicon-government:before {
  content: "\E712" !important;
}
.glyphicon-maze:before {
  content: "\E713" !important;
}
.glyphicon-chronicle:before {
  content: "\E714" !important;
}
.glyphicon-user-structure:before {
  content: "\E715" !important;
}
.glyphicon-recycle:before {
  content: "\E716" !important;
}
.glyphicon-gas:before {
  content: "\E717" !important;
}
.glyphicon-waste-pipe:before {
  content: "\E718" !important;
}
.glyphicon-water-pipe:before {
  content: "\E719" !important;
}
.glyphicon-parking:before {
  content: "\E720" !important;
}
.glyphicon-closed:before {
  content: "\E721" !important;
}
.glyphicon-mouse:before {
  content: "\E722" !important;
}
.glyphicon-mouse-double-click:before {
  content: "\E723" !important;
}
.glyphicon-mouse-left-click:before {
  content: "\E724" !important;
}
.glyphicon-mouse-right-click:before {
  content: "\E725" !important;
}
.glyphicon-mouse-middle-click:before {
  content: "\E726" !important;
}
.glyphicon-mouse-scroll:before {
  content: "\E727" !important;
}
.glyphicon-resize-vertical:before {
  content: "\E728" !important;
}
.glyphicon-resize-horizontal:before {
  content: "\E729" !important;
}
.glyphicon-temperature:before {
  content: "\E730" !important;
}
.glyphicon-puzzle:before {
  content: "\E731" !important;
}
.glyphicon-puzzle-2:before {
  content: "\E732" !important;
}
.glyphicon-puzzle-3:before {
  content: "\E733" !important;
}
.glyphicon-nearby-square:before {
  content: "\E734" !important;
}
.glyphicon-nearby-circle:before {
  content: "\E735" !important;
}
.glyphicon-rotate-right:before {
  content: "\E736" !important;
}
.glyphicon-rotate-left:before {
  content: "\E737" !important;
}
.glyphicon-pictures:before {
  content: "\E738" !important;
}
.glyphicon-photo-album:before {
  content: "\E739" !important;
}
.glyphicon-cadastral-map:before {
  content: "\E740" !important;
}
.glyphicon-fingerprint-scan:before {
  content: "\E741" !important;
}
.glyphicon-fingerprint:before {
  content: "\E742" !important;
}
.glyphicon-fingerprint-lock:before {
  content: "\E743" !important;
}
.glyphicon-fingerprint-ok:before {
  content: "\E744" !important;
}
.glyphicon-fingerprint-remove:before {
  content: "\E745" !important;
}
.glyphicon-fingerprint-reload:before {
  content: "\E746" !important;
}
.glyphicon-pending-notifications:before {
  content: "\E747" !important;
}
.glyphicon-synchronization:before {
  content: "\E748" !important;
}
.glyphicon-synchronization-ban:before {
  content: "\E749" !important;
}
.glyphicon-hash:before {
  content: "\E750" !important;
}
.glyphicon-gender-male:before {
  content: "\E751" !important;
}
.glyphicon-gender-female:before {
  content: "\E752" !important;
}
.glyphicon-gender-virgin-female:before {
  content: "\E753" !important;
}
.glyphicon-gender-intersex:before {
  content: "\E754" !important;
}
.glyphicon-gender-transgender:before {
  content: "\E755" !important;
}
.glyphicon-gender-ori-lesbian:before {
  content: "\E756" !important;
}
.glyphicon-gender-ori-gay:before {
  content: "\E757" !important;
}
.glyphicon-gender-ori-hetero:before {
  content: "\E758" !important;
}
.glyphicon-gender-other:before {
  content: "\E759" !important;
}
.glyphicon-gender-unknown:before {
  content: "\E760" !important;
}
.glyphicon-scanner:before {
  content: "\E761" !important;
}
.glyphicon-multifunction-printer:before {
  content: "\E762" !important;
}
.glyphicon-lasso:before {
  content: "\E763" !important;
}
.glyphicon-view-360:before {
  content: "\E764" !important;
}
.glyphicon-battery-charging:before {
  content: "\E765" !important;
}
.glyphicon-battery-full:before {
  content: "\E766" !important;
}
.glyphicon-battery-75:before {
  content: "\E767" !important;
}
.glyphicon-battery-50:before {
  content: "\E768" !important;
}
.glyphicon-battery-25:before {
  content: "\E769" !important;
}
.glyphicon-battery-10:before {
  content: "\E770" !important;
}
.glyphicon-satellite:before {
  content: "\E771" !important;
}
.glyphicon-satellite-dish:before {
  content: "\E772" !important;
}
.glyphicon-satellite-dish-alt:before {
  content: "\E773" !important;
}
.glyphicon-auction:before {
  content: "\E774" !important;
}
.glyphicon-directions:before {
  content: "\E775" !important;
}
.glyphicon-race:before {
  content: "\E776" !important;
}
.glyphicon-robot:before {
  content: "\E777" !important;
}
.glyphicon-ruler-alt:before {
  content: "\E778" !important;
}
.glyphicon-cube-empty:before {
  content: "\E779" !important;
}
.glyphicon-cube-black:before {
  content: "\E780" !important;
}
.glyphicon-move-square:before {
  content: "\E781" !important;
}
.glyphicon-drop:before {
  content: "\E782" !important;
}
.glyphicon-vr-headset:before {
  content: "\E783" !important;
}
.glyphicon-vr-charging:before {
  content: "\E784" !important;
}
.glyphicon-vr-low-battery:before {
  content: "\E785" !important;
}
.glyphicon-vr-paired:before {
  content: "\E786" !important;
}
.glyphicon-vr-settings:before {
  content: "\E787" !important;
}
.glyphicon-vr-maintenance:before {
  content: "\E788" !important;
}
.glyphicon-filter-remove:before {
  content: "\E789" !important;
}
.glyphicon-filter-applied:before {
  content: "\E790" !important;
}
.glyphicon-one-day:before {
  content: "\E791" !important;
}
.glyphicon-user-vr:before {
  content: "\E792" !important;
}
.glyphicon-user-vr-add:before {
  content: "\E793" !important;
}
.glyphicon-user-vr-remove:before {
  content: "\E794" !important;
}
.glyphicon-dice-1:before {
  content: "\E795" !important;
}
.glyphicon-dice-2:before {
  content: "\E796" !important;
}
.glyphicon-dice-3:before {
  content: "\E797" !important;
}
.glyphicon-dice-4:before {
  content: "\E798" !important;
}
.glyphicon-dice-5:before {
  content: "\E799" !important;
}
.glyphicon-dice-6:before {
  content: "\E800" !important;
}
@media only screen {
  .btn-foxtrot {
    border-radius: 2px;
    border: 1px solid #DB3B4B;
    color: #fff;
    background-color: #DB3B4B;
  }
  .btn-foxtrot:hover,
  .btn-foxtrot:focus {
    border: 1px solid #bb2232;
    outline: none;
    color: #fff;
    background-color: #bb2232;
  }
  .btn {
    border-radius: 2px;
    text-transform: uppercase;
    font-family: "Simplon";
    font-weight: 300;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .btn-default:hover,
  .btn-default:focus {
    outline: none;
    color: #000;
  }
  .btn-huge {
    padding: 25px;
    font-size: 26px;
  }
  .btn-center {
    padding-top: 15px;
    padding-bottom: 25px;
    text-align: center !important;
  }
  .btn-signup {
    width: 150px;
  }
  .export-button {
    padding: 9px;
  }
  .large-height-btn {
    height: 50px;
  }
  .overview-btn {
    height: 40px;
    width: 100%;
  }
  .btn-white {
    background-color: #fff;
  }
  .btn-white:hover {
    background-color: #F0F0F0;
  }
  .btn-info[disabled] {
    background-color: #ED9DA5;
    border-color: #ED9DA5;
  }
  .btn-primary[disabled] {
    background-color: #ED9DA5;
    border-color: #ED9DA5;
  }
  .btn-blue {
    background-color: #36648B;
    border-color: #36648B;
    color: #fff !important;
  }
  .btn-blue:hover {
    background-color: #203C53;
    border-color: #203C53;
    color: #fff;
  }
  .btn-gray {
    background-color: #7D7D8E;
    border-color: #7D7D8E;
    color: #fff;
  }
  .btn-gray:hover {
    background-color: #626271;
    border-color: #626271;
    color: #fff;
  }
  .icon-btn-cta {
    padding-left: 4px;
    color: #363e45;
  }
  .icon-btn-cta:hover {
    color: #7D7D8E;
  }
}
.segmented-control.pagination {
  margin-top: 0;
  margin-bottom: 0;
}
.segmented-control:hover {
  cursor: pointer;
}
.slider {
  position: relative;
  padding: 5px 0;
}
.slider .slider-track {
  border: 1px solid #ccc;
  background-color: #fff;
  height: 10px;
  border-radius: 5px;
}
.slider .slider-handle-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 10px 0 -10px;
}
.slider .slider-handle {
  border: 1px solid #ccc;
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  cursor: pointer;
}
.white {
  color: #fff;
}
.green {
  color: #00CC00;
}
.watermelon {
  color: #DB3B4B;
}
.darkgray {
  color: #909090;
}
.darkergray {
  color: #696969;
}
.gray {
  color: gray;
}
.graybluelabel {
  color: #7D7D8E;
}
.optionalgray {
  color: #e3e3e3;
}
.dropdown-menu-component {
  display: flex;
  align-items: stretch;
  align-content: stretch;
}
.dropdown-menu-component .dropdown-menu {
  width: 100%;
}
.dropdown-menu-component .dropdown-menu-loading-animate {
  animation: dropdown-menu-loading-animate 0.7s infinite linear;
}
@keyframes dropdown-menu-loading-animate {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
.dropdown-menu-component a {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dropdown-menu-component .main-button {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  text-align: left;
}
.dropdown-menu-component .clear-selection {
  font-size: 20px;
  float: left;
  padding: 6px;
  color: transparent;
}
.dropdown-menu-component .clear-selection.has-selection {
  cursor: pointer;
  color: #ccc;
}
.dropdown-menu-component .clear-selection.has-selection.disabled,
.dropdown-menu-component .clear-selection.has-selection.disabled:hover {
  color: #ccc;
  cursor: not-allowed;
  opacity: 0.65;
}
.dropdown-menu-component .clear-selection.has-selection:hover {
  color: #DB3B4B;
}
@media only screen {
  .editable-format {
    display: inline-block;
  }
}
@media only screen {
  .error-logo {
    border-bottom: 1px solid #F0F0F0;
    padding: 30px 0;
  }
  form .has-error {
    color: #a94442;
  }
  form .has-error input.ng-invalid-required:focus {
    background-color: white;
  }
}
@media only screen {
  .center-block {
    float: none;
  }
  .no-margin {
    margin: 0;
  }
  .marg-med-left {
    margin-left: 15px;
  }
  .marg-tiny-left {
    margin-left: 3px;
  }
  .marg-small-left {
    margin-left: 7px !important;
  }
  .marg-small-right {
    margin-right: 7px;
  }
  .marg-top-md {
    margin-top: 30px;
  }
  .marg-med-bottom {
    margin-bottom: 15px;
  }
  .marg-bottom-20 {
    margin-bottom: 20px;
  }
  .smaller-margin {
    margin: 0 0 25px;
  }
  .smallest-margin {
    margin: 0 0 15px;
  }
  .padd-top {
    padding-top: 15px;
  }
  .padd-top-md {
    padding-top: 25px;
  }
  .no-padd-bottom {
    padding-bottom: 0px;
  }
  .xs-padding-bottom {
    padding-bottom: 10px;
  }
  .large-padd-bottom {
    padding-bottom: 50px;
  }
  .md-padd-bottom {
    padding-bottom: 20px;
  }
  .padding-bottom-35 {
    padding-bottom: 35px;
  }
  .large-margin-bottom {
    margin-bottom: 50px;
  }
  .sm-margin-bottom {
    margin-bottom: 10px;
  }
  .padd-bottom {
    padding-bottom: 15px;
  }
  .extra-padding-top {
    padding-top: 35px;
  }
  .extra-margin-top {
    margin-top: 35px;
  }
  .no-padding-top {
    padding-top: 0px;
  }
  .padding-top-8 {
    padding-top: 8px;
  }
  .padding-top-9 {
    padding-top: 9px;
  }
  .margin-top-8 {
    margin-top: 8px;
  }
  .xs-padding-top {
    padding-top: 10px;
  }
  .padding-top-6 {
    padding-top: 6px;
  }
  .padding-top-11 {
    padding-top: 11px;
  }
  .padding-top-35 {
    padding-top: 35px;
  }
  .padding-right-25 {
    padding-right: 25px;
  }
  .padding-right-2 {
    padding-right: 3px;
  }
  .padding-right-3 {
    padding-right: 3px;
  }
  .padding-right-4 {
    padding-right: 4px;
  }
  .padding-right-5 {
    padding-right: 5px;
  }
  .padding-right-7 {
    padding-right: 7px;
  }
  .padding-left-9 {
    padding-left: 9px;
  }
  .padding-left-10 {
    padding-left: 10px;
  }
  .padding-left-11 {
    padding-left: 11px;
  }
  .padding-left-19 {
    padding-left: 19px;
  }
  .small-padding-top {
    padding-top: 15px;
  }
  .extra-padding {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .no-padding-left {
    padding-left: 0;
  }
  .no-padding-right {
    padding-right: 0;
  }
  .padding-top-5 {
    padding-top: 5px;
  }
  .padding-top-15 {
    padding-top: 15px;
  }
  .padding-left-15 {
    padding-left: 15px;
  }
  .padding-left-25 {
    padding-left: 25px;
  }
  .small-marg {
    margin: 10px !important;
  }
  .padding-right-15 {
    padding-right: 15px;
  }
  .padding-top-5 {
    padding-top: 5px;
  }
  .padding-top-8 {
    padding-top: 8px;
  }
  .no-margin-bottom {
    margin-bottom: 0 !important;
  }
  .width-25 {
    width: 25%;
  }
  .width-50 {
    width: 50%;
  }
  .width-85 {
    width: 85%;
  }
  .width-95 {
    width: 95%;
  }
  .float-right {
    float: right;
  }
  .auto-margins-horizontal-center {
    margin-right: auto;
    margin-left: auto;
  }
}
body {
  width: 100%;
  height: 100%;
  font-weight: 300;
  color: #000;
  background-color: #fff;
  font-size: 0.875rem;
}
html {
  width: 100%;
  height: 100%;
}
h1 {
  margin: 0 0 35px;
  font-family: "Simplon";
  font-weight: 300;
  letter-spacing: 1px;
}
h4,
h5 {
  margin: 0 0 35px;
  font-weight: 300;
  letter-spacing: 1px;
}
h6 {
  font-family: "Simplon";
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 15px;
}
h5 {
  font-size: 12px;
}
h2 {
  margin: 0 0 35px;
  font-family: "Simplon";
  font-weight: 300;
  letter-spacing: 1px;
}
h3 {
  margin: 0 0 35px !important;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 1.875rem !important;
}
p {
  margin: 0 0 25px;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 300;
}
label {
  font-weight: 600;
  font-family: "Simplon";
}
a {
  color: #000;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #cccccc;
}
.italic {
  font-style: italic;
}
table.sortable th {
  padding: 8px 4px;
}
table.sortable th > span {
  cursor: pointer;
  position: relative;
  margin-right: 15px;
  display: inline-block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
table.sortable th > span:before,
table.sortable th > span:after {
  border: 4px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  right: -15px;
  top: 50%;
  position: absolute;
  width: 0;
}
table.sortable th > span:before {
  border-bottom-color: #666;
  margin-top: -9px;
}
table.sortable th > span:after {
  border-top-color: #666;
  margin-top: 1px;
}
table.sortable th > span.down:before {
  content: none;
}
table.sortable th > span.down:after {
  margin-top: -2px;
}
table.sortable th > span.up:before {
  margin-top: -6px;
}
table.sortable th > span.up:after {
  content: none;
}
table.sortable root {
  width: 100%;
  overflowX: "auto";
}
table.sortable fileTable {
  minWidth: 650;
}
@media only screen {
  .tag-field {
    padding-top: 5px;
    padding-bottom: 10px;
  }
  .tag-input-label {
    background-color: #d7d7d7;
    color: #000;
  }
  .tags-input-box {
    width: 375px;
  }
  .bootstrap .host {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .bootstrap .tags {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  .bootstrap .tags.focused {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }
  .bootstrap .tags .tag-item {
    background: #428bca;
    border: 1px solid #357ebd;
    border-radius: 4px;
    color: #fff;
  }
  .bootstrap .tags .tag-item.selected {
    background: #d9534f;
    border: 1px solid #d43f3a;
    border-radius: 4px;
    color: #fff;
  }
  .bootstrap .tags .tag-item button {
    background: transparent;
    color: #000;
    opacity: 0.4;
  }
  .bootstrap .autocomplete {
    border-radius: 4px;
  }
  .bootstrap .autocomplete .suggestion-item.selected {
    color: #262626;
    background-color: #e9e9e9;
  }
  .bootstrap .autocomplete .suggestion-item em {
    font-weight: normal;
    background-color: #ffff00;
  }
  .bootstrap .autocomplete .suggestion-item.selected em {
    color: #262626;
    background-color: #ffff00;
  }
}
@media only screen {
  .user-info-text {
    color: #718290;
  }
  .user-info-text > strong {
    color: #57626C;
  }
  .user-warning-text {
    color: #DB3B4B;
  }
  .navbar {
    margin-bottom: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    font-family: "Simplon";
  }
  .navbar .navbar-nav {
    text-transform: uppercase;
  }
  .navbar .nav-active {
    font-weight: bold;
  }
  .navbar .alerts-badge {
    background-color: #DB3B4B;
    font-weight: 300;
    position: relative;
    top: -1px;
  }
  .navbar li.dropdown > a > .alerts-badge {
    margin-right: 5px;
  }
  .navbar li.dropdown > ul.dropdown-menu > li a {
    font-weight: 300;
  }
  .navbar li.dropdown > ul.dropdown-menu > li.nav-active a {
    font-weight: 400;
  }
  .navbar-brand:focus {
    outline: none;
  }
  .navbar-custom a {
    color: #000;
  }
  .navbar-default {
    border: 0;
  }
  .navbar-custom .nav li a {
    -webkit-transition: background 0.3s ease-in-out;
    -moz-transition: background 0.3s ease-in-out;
    transition: background 0.3s ease-in-out;
  }
  .navbar-custom .nav li a:hover,
  .navbar-custom .nav li a:focus,
  .navbar-custom .nav li.active {
    outline: none;
  }
  .navbar-toggle {
    padding: 4px 6px;
    font-size: 16px;
    color: #000;
  }
  .navbar-toggle:focus,
  .navbar-toggle:active {
    outline: none;
  }
  ::-moz-selection {
    text-shadow: none;
    background: #4D94DB;
    color: #fff;
  }
  ::selection {
    text-shadow: none;
    background: #4D94DB;
    color: #fff;
  }
  img::selection {
    background: transparent;
  }
  img::-moz-selection {
    background: transparent;
  }
  body {
    webkit-tap-highlight-color: rgba(255, 255, 255, 0.2);
    font-weight: 400;
  }
  code {
    white-space: normal;
  }
  .container-full {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  .form-group {
    margin-bottom: 10px;
  }
  .full-width {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  input:focus::-webkit-input-placeholder {
    color: transparent;
  }
  input:focus::-moz-placeholder {
    color: transparent;
  }
  input:focus.ng-invalid {
    background-color: #FFF0F0;
  }
  .logo-color {
    color: #DB3B4B !important;
  }
  .vertical-align {
    vertical-align: middle !important;
  }
  .hand-cursor:hover {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -ms-grab;
    cursor: -o-grab;
  }
  .hand-cursor:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -ms-grabbing;
    cursor: -o-grabbing;
  }
  .pointer {
    cursor: pointer;
  }
  .hover-border:hover {
    border: 1px solid #718290;
  }
  .hover-border {
    border-radius: 3px;
    padding: 3px;
    margin-left: 2px;
    margin-top: 4px;
  }
  .attempt-success {
    background-color: #00B155;
  }
  .attempt-failure {
    background-color: #ED4957;
  }
  .attempt-visit-later {
    background-color: #F7AF00;
  }
  .attempt-authorize-reattempt {
    background-color: #7F21FF;
  }
  .user-delivery-code-container {
    margin-left: 7px;
  }
  .user-delivery-code {
    background-color: #DB3B4B;
    color: #fff;
    border-radius: 3px;
    padding: 2px 2px 2px 3px;
    font-size: 75%;
    margin-right: 3px;
  }
  .user-delivery-code-message {
    font-style: italic;
    color: #696969;
    vertical-align: middle;
  }
}
@media only screen and (min-width: 767px) {
  p {
    margin: 0 0 35px;
    font-size: 20px;
    line-height: 1.6;
  }
  .help-block {
    font-size: 15px;
  }
  .navbar {
    padding: 8px 0;
  }
}
@media only screen and (min-width: 767px) {
  .navbar {
    padding: 8px 0;
    letter-spacing: 1px;
    background: transparent;
    border-bottom: 0px;
    -webkit-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
    -moz-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
    transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
  }
  .top-nav-collapse {
    padding: 10px;
    background-color: #fff;
    border-bottom: 1px solid rgba(192, 192, 192, 0.3);
  }
}
@media only screen and (min-width: 767px) {
  .brand-heading {
    font-size: 50px;
    padding-top: 10%;
  }
}
@media only screen and (max-width: 767px) {
  .navbar {
    background-color: #fff;
    padding: 8px 0;
  }
  .navbar .navbar-brand {
    content: url(../img/lightlogo.png);
  }
}
.loading-animate {
  animation: loading-animate 1250ms infinite linear;
}
@keyframes loading-animate {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
@media only screen {
  #register-title {
    padding-top: 100px;
    font-size: 46px;
  }
  .phone-num {
    margin-bottom: 15px;
    padding: 5px;
  }
  .phone-num-background {
    padding: 7px;
    background-color: #F0F0F0;
    border-radius: 3px;
  }
  .verification-box {
    margin: auto;
    text-align: center;
    width: 100px;
  }
  .btn-text {
    height: 50px;
    width: 250px;
  }
  #register-body {
    padding-bottom: 100px;
  }
  .terms_of_use {
    font-size: 15px;
    margin-bottom: 15px;
    padding: 15px;
  }
  .terms_in_modal {
    margin-bottom: 30px;
    overflow: auto;
    max-height: 450px;
    border-radius: 3px;
    border: 1px solid #A9A9A9;
    overflow-y: auto;
  }
  .terms_of_use > h1 {
    font-size: 20px;
  }
  .terms_of_use > h2 {
    font-size: 18px;
  }
  .terms_of_use > p {
    font-size: 14px;
  }
  .terms_of_use > * > h2 {
    font-size: 18px;
  }
  .terms_of_use > * > p {
    font-size: 14px;
  }
  .terms_of_use > * > * > p {
    font-size: 14px;
  }
  .terms_of_use > * > p {
    counter-increment: par-num;
  }
  .terms_of_use > * > h2 {
    counter-increment: hdr-num;
    counter-reset: par-num;
  }
  .terms_of_use > * > p:before {
    content: counter(hdr-num) "." counter(par-num) " ";
  }
}
.realtime-dashboard {
  flex: 1 1 auto;
  padding: 5px 30px 10px;
  display: flex;
  flex-direction: column;
}
.realtime-dashboard .has-tooltip {
  text-decoration: underline;
  text-decoration-style: dotted;
}
.realtime-dashboard .dashboard-header {
  margin: 10px 50px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.realtime-dashboard .dashboard-header .driver-group-picker,
.realtime-dashboard .dashboard-header .dashboard-title,
.realtime-dashboard .dashboard-header .date-picker {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-wrap: nowrap;
  align-items: center;
}
.realtime-dashboard .dashboard-header .driver-group-picker .picker-label,
.realtime-dashboard .dashboard-header .dashboard-title .picker-label,
.realtime-dashboard .dashboard-header .date-picker .picker-label {
  margin-right: 10px;
  font-weight: 600;
}
.realtime-dashboard .dashboard-header .dashboard-title h1 {
  width: 100%;
  margin: 0;
  text-align: center;
}
.realtime-dashboard .dashboard-header .date-picker {
  text-align: right;
}
.realtime-dashboard .dashboard-header .date-picker .date-picker-input {
  margin: auto 0 auto auto;
}
.realtime-dashboard .dashboard-header .date-picker .date-picker-input button:last-child {
  min-width: 200px;
}
.realtime-dashboard .dashboard-summary-bar {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  align-items: stretch;
  align-content: flex-end;
  justify-content: space-between;
}
.realtime-dashboard .dashboard-summary-bar .summary-bar-section {
  background-color: #F7FAFA;
  margin: 10px 5px;
  text-align: center;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.realtime-dashboard .dashboard-summary-bar .summary-bar-section .summary-bar-title {
  display: block;
  align-self: flex-start;
  width: 100%;
  text-align: center;
  margin: 5px 0;
}
.realtime-dashboard .dashboard-summary-bar .summary-bar-section .bottom {
  align-items: flex-end;
  display: flex;
  width: 100%;
  align-content: stretch;
  text-align: center;
  flex-grow: 1;
}
.realtime-dashboard .dashboard-summary-bar .summary-bar-section .bottom .subsection-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
.realtime-dashboard .dashboard-summary-bar .summary-bar-section .subtitle {
  margin: 0;
}
.realtime-dashboard .dashboard-summary-bar .summary-bar-section .summary-bar-fraction {
  width: 100%;
}
.realtime-dashboard .dashboard-summary-bar .summary-bar-section .metric {
  font-size: 50px;
  font-weight: bold;
  padding: 0;
  margin: 0 auto 5px 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 60px;
}
.realtime-dashboard .dashboard-summary-bar .summary-bar-section .metric.delivery-success {
  color: #00B155;
}
.realtime-dashboard .dashboard-summary-bar .summary-bar-section .metric.delivery-failed {
  color: #ED4957;
}
.realtime-dashboard .dashboard-summary-bar .summary-bar-section .metric.delivery-visit-later {
  color: #F7AF00;
}
.realtime-dashboard .dashboard-summary-bar .summary-bar-section .metric.delivery-authorize-reattempt {
  color: #7F21FF;
}
.realtime-dashboard .dashboard-summary-bar .summary-bar-section .metric.delivery-return {
  color: #ED4957;
}
.realtime-dashboard .dashboard-summary-bar .summary-bar-section .pie-chart {
  height: 80px;
}
.realtime-dashboard .dashboard-summary-bar .summary-bar-section .pie-chart > svg {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.realtime-dashboard .dashboard-body {
  flex: 1 1 1px;
  display: flex;
  gap: 15px;
  overflow-y: hidden;
}
.realtime-dashboard .dashboard-body .foxtrot-table-container {
  flex: 2;
  width: 100%;
  overflow-y: auto;
}
.realtime-dashboard .dashboard-body .foxtrot-table-container .pending-optimization-icon {
  color: #6f6f6f;
}
.realtime-dashboard .dashboard-body .foxtrot-table-container .route-issues-icon {
  color: #D66715;
}
.realtime-dashboard .dashboard-body .foxtrot-table-container .driver-status-online {
  background-color: #00B155;
}
.realtime-dashboard .dashboard-body .foxtrot-table-container .driver-status-offline-now {
  background-color: #F7AF00;
}
.realtime-dashboard .dashboard-body .foxtrot-table-container .driver-status-offline-today {
  background-color: #ED4957;
}
.realtime-dashboard .dashboard-body .foxtrot-table-container .progress-success {
  height: 20px;
  display: inline-block;
  background-color: #00B155;
}
.realtime-dashboard .dashboard-body .foxtrot-table-container .progress-failed {
  height: 20px;
  display: inline-block;
  background-color: #ED4957;
}
.realtime-dashboard .dashboard-body .foxtrot-table-container .success-color {
  color: #00B155;
}
.realtime-dashboard .dashboard-body .dashboard-map {
  flex: 1;
  position: relative;
}
.realtime-dashboard .dashboard-body .map-info-pop-up {
  display: block;
  text-align: center;
}
.driver-groups-panel .role {
  margin-right: 2px;
}
.driver-groups-panel .delete-driver-group-icon {
  color: #7D7D8E;
}
.driver-groups-panel .delete-driver-group-icon:hover {
  color: #DB3B4B;
}
.driver-groups-panel .driver-group-search {
  width: 50%;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}
.driver-groups-panel .hover-to-show:hover .hover-content {
  visibility: visible;
  transition: none;
}
.driver-groups-panel .hover-content {
  visibility: hidden;
  transition: none;
}
.driver-groups-panel .darker-hover:hover {
  background-color: #d7d7d7;
}
.driver-groups-panel .lighter-background {
  background-color: #e6e6e6;
}
.driver-group-modal .label {
  margin-right: 2px;
}
.driver-group-modal .label sub {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 5px;
  height: 15px;
  max-width: 100px;
  white-space: nowrap;
  float: left;
}
.driver-group-modal .scrollable-modal-container > div,
.driver-group-modal .scrollable-label-container {
  max-height: 120px;
  overflow-y: auto;
}
.driver-group-modal .block-label {
  display: inline-block;
}
.driver-group-modal .editable-wrap {
  display: inline-block;
  width: 100%;
  max-height: 120px;
}
.driver-group-modal .editable-input > div {
  max-height: 118px;
  overflow-y: auto;
  position: relative;
  bottom: 3px;
}
.driver-group-modal .matches-container {
  display: inline-block;
  width: 100%;
  white-space: normal;
}
.driver-group-modal .matches-container sub {
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 5px;
  height: 15px;
  max-width: 100px;
  white-space: nowrap;
  float: left;
}
.driver-group-modal .matches-container > span {
  display: inline-block;
}
@media only screen {
  .account-settings {
    margin-top: 32px;
  }
  .account-settings #warehouse .editable-input {
    width: 255px;
  }
  .account-settings #max_drivers .editable-input {
    width: 100px;
  }
  .account-settings #default_service_time .editable-input {
    width: 100px;
  }
  .account-settings .editable-input {
    height: 30px;
    padding: 6px 6px;
    border: 1px solid #ccc;
    border-radius: 2px;
  }
  .account-settings .editable-buttons button {
    padding: 2px 4px;
    margin-top: 5px;
    font-size: 11px;
    line-height: 14px;
    border-radius: 2px;
    border: 1px solid #36648B;
    color: #fff;
    background-color: #36648B;
  }
  .account-settings .editable-buttons button:hover,
  .account-settings .editable-buttons button:focus {
    border: 1px solid #264763;
    outline: none;
    color: #fff;
    background-color: #264763;
  }
  .account-settings .editable-empty,
  .account-settings a.editable-empty {
    font-style: normal;
    color: #DB3B4B;
    border-bottom: 1px solid #DB3B4B;
  }
  .account-settings .editable-click,
  .account-settings .a.editable-click {
    color: #000;
    border-bottom: 1px solid #b6b6b6;
    padding-bottom: 3px;
  }
  .operating-days-label {
    display: inline-block;
    min-width: 100px;
  }
  input.form-control.half-width {
    width: 40%;
  }
}
.driver-profiles-panel .delete-driver-icon {
  color: #7D7D8E;
}
.driver-profiles-panel .delete-driver-icon:hover {
  color: #DB3B4B;
}
.driver-profiles-panel .driver-search {
  width: 50%;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}
.driver-profiles-panel .driver-profiles {
  height: 200px;
  width: 85%;
  margin: auto;
  overflow-x: hidden;
  border-radius: 5px;
  border: 1px solid #cfcfcf;
}
.driver-profiles-panel .separator {
  height: 50px;
}
.driver-profiles-panel .settings-field {
  margin-left: 30px;
}
.driver-profiles-panel .default-login-secret-code-header {
  font-size: 24px;
}
.driver-profiles-panel .secret-code-setting-form-field {
  margin-top: 10px;
}
.driver-profiles-panel .secret-code-setting-form-field .default-secret-code-input {
  margin-left: 20px;
}
.driver-profiles-panel .secret-code-setting-form-field input {
  margin-left: 30px;
}
.driver-profiles-panel .settings-header {
  margin-top: 10px;
  font-size: 24px;
}
.driver-profiles-panel .setting-description {
  margin-left: 25px;
  font-size: 12px;
}
.driver-profiles-panel .hover-to-show:hover .hover-content {
  visibility: visible;
  transition: none;
}
.driver-profiles-panel .hover-content {
  visibility: hidden;
  transition: none;
}
.driver-profiles-panel .darker-hover:hover {
  background-color: #d7d7d7;
}
.driver-profiles-panel .lighter-background {
  background-color: #e6e6e6;
}
.driver-profiles-panel .no-drivers {
  color: #A9A9A9;
  font-weight: bolder;
  padding-top: 50px;
  margin-bottom: 10px;
}
.driver-profiles-panel .no-drivers-desc {
  color: #A9A9A9;
  font-weight: bolder;
}
.driver-profiles-panel #driver-settings-section {
  background-color: #F0F0F0;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
  border-radius: 5px;
}
.driver-profiles-panel #current-driver-stats-section {
  background-color: #F0F0F0;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}
.driver-profiles-panel .limited-width {
  max-width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.app-login-background {
  background-color: #375065;
}
.app-login-input-field-icon-container {
  background-color: #FD6659;
  width: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-login-input-field {
  background-color: #fff;
  width: 100%;
  height: 30px;
}
.app-login-input-field-half {
  background-color: #fff;
  width: 50%;
  height: 30px;
}
.app-login-hyphen-text {
  color: #fff;
  width: 20px;
  height: 100%;
  margin-right: 5px;
  margin-left: 5px;
}
.input-info-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1;
}
.h2 {
  margin-top: 0;
  margin-bottom: 0;
}
.driver-profile-modal .driver-profile-section-header {
  padding-top: 30px;
  padding-left: 15px;
  margin-bottom: 5px;
}
.driver-profile-modal .setting-description {
  margin-left: 25px;
  font-size: 12px;
}
.driver-profile-modal .settings-field {
  margin-left: 15px;
}
.driver-profile-modal .settings-header {
  margin-top: 10px;
}
.driver-profile-modal .section-divider {
  border-bottom: 1px solid #cacaca;
  margin: 25px 30px 30px 20px;
}
.driver-profile-modal .disabled-editable {
  color: #000;
  border-bottom: 1px solid #b6b6b6;
  padding-bottom: 3px;
  cursor: not-allowed;
}
.driver-profile-modal .editable-input {
  height: 30px;
  padding: 6px 6px;
  border: 1px solid #ccc;
  border-radius: 2px;
}
.driver-profile-modal .editable-buttons button {
  padding: 2px 4px;
  margin-top: 5px;
  font-size: 11px;
  line-height: 14px;
  border-radius: 2px;
  border: 1px solid #36648B;
  color: #fff;
  background-color: #36648B;
}
.driver-profile-modal .editable-buttons button:hover,
.driver-profile-modal .editable-buttons button:focus {
  border: 1px solid #264763;
  outline: none;
  color: #fff;
  background-color: #264763;
}
.driver-profile-modal .editable-empty,
.driver-profile-modal a.editable-empty {
  font-style: normal;
  color: #DB3B4B;
  border-bottom: 1px solid #DB3B4B;
}
.driver-profile-modal .editable-click,
.driver-profile-modal .a.editable-click {
  color: #000;
  border-bottom: 1px solid #b6b6b6;
  padding-bottom: 3px;
}
.internal-route-analyzer-route-finder {
  padding-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.internal-route-analyzer-route-finder .header h1 {
  width: 100%;
  margin: 0 0 10px;
  text-align: center;
}
.internal-route-analyzer-route-finder .body {
  flex: 1;
  display: flex;
  overflow-y: hidden;
  padding: 0 30px;
}
.internal-route-analyzer-route-finder .sidebar {
  width: 350px;
  overflow-y: auto;
}
.internal-route-analyzer-route-finder .sidebar .filter-label {
  font-size: 1.5rem;
  margin: 10px 0 5px;
}
.internal-route-analyzer-route-finder .sidebar .submit-button {
  margin: 15px 0;
}
.internal-route-analyzer-route-finder .result-list {
  flex: 1;
  margin-left: 10px;
  overflow-y: auto;
}
.internal-route-analyzer-route-finder .result-list .analyze-route-button-container {
  text-align: center;
}
.user-route-analyzer-route-finder .header {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-route-analyzer-route-finder .header .date,
.user-route-analyzer-route-finder .header .page-title,
.user-route-analyzer-route-finder .header .filter {
  flex: 1;
}
.user-route-analyzer-route-finder .header .page-title h1 {
  width: 100%;
  text-align: center;
  margin: 0;
}
.user-route-analyzer-route-finder .analyze-route-button-container {
  text-align: center;
}
.warehouse-polygon-editor .warehouse-polygon-editor-title {
  display: flex;
  align-items: center;
}
.warehouse-polygon-editor .warehouse-polygon-editor-title .reset-button-holder,
.warehouse-polygon-editor .warehouse-polygon-editor-title .save-button-holder {
  flex: 1;
}
.warehouse-polygon-editor .warehouse-polygon-editor-title .title {
  text-align: center;
  flex: 3;
}
.warehouse-polygon-editor .warehouse-polygon-editor-title .title h1,
.warehouse-polygon-editor .warehouse-polygon-editor-title .title h3 {
  margin-bottom: 5px;
}
.warehouse-polygon-editor .warehouse-polygon-editor-title .reset-button-holder {
  text-align: left;
}
.warehouse-polygon-editor .warehouse-polygon-editor-title .save-button-holder {
  text-align: right;
}
.warehouse-polygon-editor .warehouse-polygon-editor-body {
  display: flex;
  height: 400px;
}
.warehouse-polygon-editor .warehouse-polygon-editor-body .editor-wrapper {
  flex: 2;
  position: relative;
}
.warehouse-polygon-editor .warehouse-polygon-editor-body .warehouse-location-editor {
  flex: 1;
  position: relative;
}
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.time-picker-input {
  display: flex;
  align-items: stretch;
  align-content: stretch;
}
.time-picker-input .main-button {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  text-align: center;
}
.time-picker-input .dropdown-menu {
  padding: 0;
}
.time-picker-input .dropdown-menu .time-picker-buttons {
  display: flex;
  justify-content: space-between;
}
.time-picker-input .dropdown-menu .time-picker-buttons .btn {
  margin: 0 5px 5px;
}
.time-picker {
  display: inline-block;
}
.time-picker .time-display {
  display: flex;
  height: 80px;
  margin-bottom: 10px;
  background-color: #005caf;
  align-items: center;
  color: #fff;
}
.time-picker .time-display .time-label {
  font-size: 48px;
  flex: 1;
  text-align: right;
}
.time-picker .time-display .time-label .hours,
.time-picker .time-display .time-label .minutes {
  opacity: 0.7;
}
.time-picker .time-display .time-label .hours.selected,
.time-picker .time-display .time-label .minutes.selected {
  opacity: 1;
}
.time-picker .time-display .am-pm-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 20px 0;
}
.time-picker .time-display .am-pm-wrapper .am,
.time-picker .time-display .am-pm-wrapper .pm {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.5;
  cursor: pointer;
}
.time-picker .time-display .am-pm-wrapper .am.selected,
.time-picker .time-display .am-pm-wrapper .pm.selected {
  opacity: 1;
}
.time-picker-clock {
  display: inline-block;
}
.time-picker-clock .time-picker-clock-face {
  position: relative;
  background-color: #e0e0e0;
  border-radius: 50%;
}
.time-picker-clock .time-picker-clock-face .number-label-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 350ms, opacity 350ms ease;
}
.time-picker-clock .time-picker-clock-face .number-label-circle.visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 350ms 175ms ease;
}
.time-picker-clock .time-picker-clock-face .number-label-circle .number-label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  border-radius: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  color: #000;
}
.time-picker-clock .time-picker-clock-face .number-label-circle .number-label > div {
  font-size: 16px;
  width: 100%;
  text-align: center;
}
.time-picker-clock .time-picker-clock-face .number-label-circle .number-label.selected {
  background-color: #005caf;
  color: #fff;
}
.switch {
  border-radius: 10px;
  position: relative;
  display: inline-block;
  width: 50px;
}
.switch .switch-track {
  border: 1px solid #ccc;
  height: 10px;
  width: 30px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
}
.switch .switch-track .switch-toggle {
  border: 1px solid #ccc;
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  top: 0;
  left: 0;
  cursor: pointer;
  position: absolute;
}
.switch .switch-track.on {
  border-color: #00B155;
  background-color: #00B155;
}
.switch .switch-track.on .switch-toggle {
  left: unset;
  right: 0;
}
.switch .switch-track.disabled,
.switch .switch-track.disabled:hover {
  opacity: 0.3;
  cursor: not-allowed;
}
.switch .switch-track.disabled .switch-toggle,
.switch .switch-track.disabled:hover .switch-toggle {
  cursor: not-allowed;
}
.date-picker-input.block {
  display: flex;
}
.date-picker-input.block .dropdown-toggle {
  flex: 1;
}
.date-picker-input .dropdown-menu {
  padding: 0;
}
.date-picker-input .date-picker-buttons {
  display: flex;
  justify-content: space-between;
}
.date-picker-input .date-picker-buttons .btn {
  margin: 0 5px 5px;
}
.dc-delivery-codes {
  margin-top: 15px;
}
.dc-delivery-codes .header {
  display: flex;
  align-items: center;
}
.dc-delivery-codes .header .delivery-code-filter {
  flex: 1;
}
.dc-delivery-codes .header .page-title {
  flex: 1;
  text-align: center;
  font-size: 35px;
}
.dc-delivery-codes .header .page-title h1 {
  margin: 0;
}
.dc-delivery-codes .header .buttons {
  flex: 1;
  text-align: right;
}
.dc-delivery-codes .header .buttons .btn {
  margin-left: 10px;
}
.dc-delivery-codes .delivery-code-table {
  margin-top: 15px;
}
.dc-delivery-codes .delivery-code-table .edit-delivery-code-button {
  text-align: center;
}
.dc-delivery-codes .delivery-code-table .delete-delivery-code-button {
  text-align: center;
}
.mongo-diagnostic-panel h4 {
  margin: 0 0 20px 0;
}
.user-profiles-panel .role {
  margin-right: 2px;
}
.user-profiles-panel .delete-user-icon {
  color: #7D7D8E;
}
.user-profiles-panel .delete-user-icon:hover {
  color: #DB3B4B;
}
.user-profiles-panel .user-search {
  width: 50%;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}
.user-profiles-panel .hover-to-show:hover .hover-content {
  visibility: visible;
  transition: none;
}
.user-profiles-panel .hover-content {
  visibility: hidden;
  transition: none;
}
.user-profiles-panel .darker-hover:hover {
  background-color: #d7d7d7;
}
.user-profiles-panel .lighter-background {
  background-color: #e6e6e6;
}
@media only screen {
  .access-denied {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100vh;
  }
}
.dc-settings-page h4 {
  margin: 0 0 5px 0;
}
.dc-settings-page hr {
  margin: 10px 0 10px 0;
}
.dc-settings-page .buttons {
  float: right;
  margin-left: 15px;
}
.dc-settings-page .row {
  margin: 0;
}
.dc-settings-page .editable .checkbox {
  display: flex;
}
.dc-settings-page .editable .editable-weekday {
  margin-left: 25px;
}
.logoLink {
  display: flex;
  align-items: center;
  margin-right: 16px;
}
.grow {
  flex: 1;
  white-space: nowrap;
}
.undecoratedLink {
  text-decoration: none !important;
  color: #1976d2;
}
.topLevelItemButton {
  margin-left: 8px !important;
}
.settingTab {
  min-width: 160px !important;
  text-decoration: none !important;
}
.dcSettingsTimePicker > div {
  width: -webkit-fill-available;
}
.accountButton {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.87) !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}
.accountButtonInfo {
  display: flex;
  flex-direction: column;
  text-align: right;
  max-width: 250px;
  overflow-x: hidden;
}
.accountButtonText {
  line-height: 1.25 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dcSettingsHeader {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-family: "Simplon";
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}
.accountButtonIcon {
  margin-left: 8px;
}
.menuItem {
  overflow: visible;
  color: rgba(0, 0, 0, 0.87) !important;
}
.menuItem:hover {
  text-decoration: none;
}
.menuBadge {
  padding-right: 8px;
  color: black;
}
.superscript {
  text-transform: uppercase;
}
.sidebarList {
  min-width: 350px;
}
.stopList {
  padding-top: 8px;
  padding-bottom: 8px;
  overflow-y: auto;
  background-color: #F4F4F4;
}
.stopListHeader {
  padding-left: 8px;
  margin-top: 8px;
  background-color: #e0e0e0;
}
.chip {
  margin-left: 8px;
}
.multiLineListItem {
  flex: 1 1 auto;
  flex-wrap: wrap;
  min-width: 0;
  align-items: center;
  justify-content: flex-start;
}
.multiLineText {
  width: 100%;
}
.contentRoot {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.body {
  flex: 1;
  overflow-y: auto;
}
.buttonWrapper {
  margin-top: 16px;
  margin-bottom: 16px;
}
.pickerWrapper {
  margin: 32px 0;
}
.progressWrapper {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.chipWrapper {
  display: flex;
  justify-content: center;
}
.drawer {
  margin-bottom: 100px;
  width: 100%;
  max-width: 500px;
}
.drawerHeading {
  margin-bottom: 15px;
}
.faq {
  margin-top: 25px;
}
.appBar {
  position: relative;
}
.flex {
  flex: 1;
}
.fileTabRoot {
  margin-top: 24px;
  margin-bottom: 80px;
}
.wrapper {
  margin-bottom: 32px;
}
.heading {
  margin: 32px 0;
}
.buttonContainer {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;
}
.infoIcon {
  text-align: right;
}
.columnDefLabel {
  padding-right: 8px;
  color: rgba(0, 0, 0, 0.87);
}
.alerts-container {
  align-self: stretch;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.alerts-container .alert {
  border: 1px solid transparent;
  border-radius: 4px;
  padding-left: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.alerts-container .alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.alerts-container .alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.pickerRoot {
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 24px;
  padding-right: 24px;
}
.monthLabel {
  margin-bottom: 8px !important;
  margin-top: 8px !important;
  display: flex;
  justify-content: center;
}
.calendarHeading {
  display: flex;
  justify-content: space-between;
}
.weekWrapper {
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
}
.day {
  width: 36px;
  height: 36px;
  color: inherit !important;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem !important;
}
.dayOfWeek {
  text-align: center;
}
.nonCurrentMonthDay {
  color: rgba(0, 0, 0, 0.38) !important;
}
.highlightNonCurrentMonthDay {
  color: #676767;
}
.selectedPeriodWrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 2px;
}
.inSelectedPeriod {
  color: white;
  background-color: #3f51b5;
}
.firstInSelectedPeriod {
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}
.lastInSelectedPeriod {
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}
.routeListDateHeader {
  padding: 24px;
  background-color: #3f51b5;
  color: white;
}
.dateLabelText {
  font-size: 1.25rem;
  font-weight: 600;
}
.doubleToggleContainer {
  min-width: max-content;
}
.paper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 32px 32px;
}
.caption {
  line-height: 1.2;
  text-shadow: 2px 2px 0 white -1px -1px 0 white 1px -1px 0 white -1px 1px 0 white 1px 1px 0 white;
}
.textField {
  margin-left: 8px;
  margin-right: 8px;
  width: 350px;
}
.fullWidth {
  flex: 1;
}
.internalRouteRoot {
  position: absolute;
  width: 400px;
  top: 75px;
  left: 8px;
  z-index: 1000;
}
.routeAnalyzerRoot {
  position: absolute;
  top: 75px;
  left: 8px;
  z-index: 1000;
}
.internalRoutePaper {
  padding-top: 16px;
  padding-bottom: 16px;
}
.routeAnalyzerPaper {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
}
.loginAsPaper {
  margin: 32px 0px;
  margin-top: 16px;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;
}
.titleBadge {
  padding-right: 16px;
}
.routeImageViewerTable {
  margin-top: 15px;
}
.finalizedCell {
  display: flex;
  justify-content: space-evenly;
}
.numberIconCell {
  display: inline-block;
  width: 100%;
}
.numberIconCellCount {
  float: left;
}
.numberIconEnabled {
  float: right;
}
.numberIconDisabled {
  float: right;
  color: grey;
}
.updateRouteCaption {
  text-align: center;
}
.routeConflictText {
  color: #d32f2f;
}
.dialogContent {
  padding: 0;
}
.analyticsTypes .multiselectRoot {
  flex-grow: 1;
}
.analyticsTypes .multiselectRoot .MuiInputBase-input {
  display: flex;
  justify-content: space-between;
}
.analyticsTypes .multiselectRoot .MuiInputBase-input > div {
  display: flex;
  position: relative;
}
.analyticsTypes .multiselectRoot .MuiInputBase-input > div > div {
  position: relative;
}
.multiselectRoot {
  flex-grow: 1;
}
.multiselectRoot .multiselect-input {
  display: flex;
  max-height: 33vh;
}
.multiselectRoot .multiselect-input .react-select__control {
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
}
.multiselectRoot .multiselect-input .react-select__control > div:first-child {
  overflow: auto;
}
.multiselectRoot .MuiChip-root {
  margin: 5px 0;
}
.MuiChip-label {
  font-weight: 400;
}
.input {
  display: flex;
  padding: 0;
  height: 100%;
}
.valueContainer {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  overflow: hidden;
}
.multiselectChip {
  margin: 4px 2px;
}
.noOptionsMessage {
  padding: 8px 16px;
}
.singleValue {
  font-size: 16px;
}
.placeholder {
  position: absolute;
  font-size: 16px;
}
.multislectPaper {
  position: absolute;
  z-index: 1;
  margin-top: 8px;
  left: 0;
  right: 0;
}
.divider {
  height: 16px;
}
.orderWrapper {
  z-index: 10;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #eeeeee !important;
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  margin-left: 8px;
  margin-right: 8px;
}
.orderBody {
  max-height: 90%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.headerSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
}
.routeAndDriver {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow-y: auto;
}
.section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.orderPaper {
  margin: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.routeCard {
  flex: 1;
  margin: 8px;
}
.driverCard {
  flex: 1;
  margin: 8px;
}
.orderStatus {
  margin: 8px;
}
.deliveryInfo {
  max-height: 100%;
  overflow: hidden;
}
.dialogContent {
  padding: 8px;
  margin: 8px;
}
.lineContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lineItem {
  margin-left: 8px;
  margin-right: 8px;
}
.mapWrapper {
  margin-top: 32px;
  margin-bottom: 32px;
}
.mapPaper {
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button {
  margin: 8px;
}
.leftIcon {
  margin-right: 8px;
}
.rightIcon {
  margin-left: 8px;
}
.mapInformation {
  background: #f0f0f0;
  width: 100%;
  padding-top: 35px;
  padding-bottom: 50px;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.extraPadding {
  padding-top: 35px;
  padding-bottom: 35px;
}
.form {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}
.form .chip {
  background: #36648B;
  margin-bottom: 0;
  line-height: 15px;
}
.column-assignments {
  background: #f0f0f0;
  padding-top: 35px;
  padding-bottom: 50px;
  display: grid;
  grid: auto-flow / 1fr 3fr;
}
.column-assignments > div {
  margin-right: 16px;
}
.importAssignments {
  padding-top: 35px;
  padding-bottom: 35px;
}
.columnLabelText {
  margin-bottom: 15px;
  font-size: 18px;
  width: 100%;
  font-weight: 600;
  letter-spacing: 1px;
}
.fullWidth {
  width: 100%;
  margin-top: 16px;
}
.column-assignments-container {
  display: grid;
  grid: auto-flow / 1fr 1fr;
  grid-gap: 15px 10px;
  margin-top: 40px;
}
@media only screen and (max-width: 800px) {
  .column-assignments-container {
    grid: auto-flow / 1fr;
  }
}
.column-assignments-container .columnLabelText {
  grid-column: span 2;
  text-align: center;
}
@media only screen and (max-width: 800px) {
  .column-assignments-container .columnLabelText {
    grid-column: span 1;
  }
}
.column-assignment-input-container {
  display: flex;
  padding-top: 10px;
}
.column-assignment-input-container > div {
  display: flex;
  flex: 1 1 auto;
}
.multiselect-container {
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  border-radius: 0 4px 4px 0!important;
}
.multiselect-container .searchWrapper {
  border-radius: 0 4px 4px 0 !important;
  background-color: white;
  flex: 1 1 auto;
}
.multiselect-container .searchWrapper input {
  width: 100%;
}
.multiselect-container.error .searchWrapper {
  background-color: #f2dede;
  border-color: #a94442;
  color: #a94442;
}
.multiselect-container.error .searchWrapper input::-webkit-input-placeholder {
  color: #a94442;
}
.multiselect-container.error .searchWrapper > input {
  width: 100% !important;
}
.multiselect-container.error .searchWrapper input::-moz-placeholder {
  color: #a94442;
}
.multiselect-container.error .searchWrapper input::-ms-placeholder {
  color: #a94442;
}
.multiselect-container.error .searchWrapper input::placeholder {
  color: #a94442;
}
.multiselect-container .optionListContainer {
  top: 100%;
}
.headerText {
  font-size: 24px;
  padding-left: 15px;
  font-weight: normal !important;
  letter-spacing: 1px;
}
.zindex1 {
  z-index: 1;
}
.fullWidthCenter {
  width: 100%;
  margin-top: 16px;
  padding-right: 16px;
  text-align: center;
  padding-top: 10px;
}
.chips {
  background: #36648B !important;
}
.chip {
  background: #36648B !important;
}
.columnLabel {
  padding: 12px 12px;
  font-size: 14px;
  line-height: 1;
  color: #555;
  background-color: #eee;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}
.columnLabel.error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}
.gridContainer {
  display: flex;
}
.gridContainer > div {
  flex: 1 1 auto;
}
.loginButton {
  background-color: #060525 !important;
}
.loginButton:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26);
}
.MuiSwitch-switchBase.Mui-checked {
  color: #060525 !important;
}
.switch {
  width: 50px;
}
.switch .MuiSwitch-thumb {
  color: inherit;
}
.switch .MuiSwitch-track {
  background-color: #36648B !important;
}
.switch .MuiSwitch-track .checked {
  opacity: 0.7;
  background-color: #36648B;
}
.routeUpdateCaption {
  text-align: center;
}
.routeConflictText {
  color: red;
}
.dialogContent {
  padding: 0;
}
.tabs {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.tabs .MuiTabs-flexContainer .Mui-selected {
  color: black !important;
}
.userRouteDialogContent {
  padding: 8px !important;
  margin: 8px;
}
.passwordRoot {
  display: flex;
  justify-content: center;
}
.btn-success {
  color: #fff !important;
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;
}
.passwordPaper {
  margin-top: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form {
  width: 100%;
}
.passwordStrength {
  margin: 16px 0;
}
.forgotPassword {
  margin-top: 16px;
}
.timestamp {
  padding: 0 16px;
}
.deliveryListColumnHeaders {
  display: flex;
  justify-content: space-between;
  padding: 8px 24px 8px 56px;
}
.visitsTitle {
  padding-left: 16px;
}
.noVisitsTitle {
  text-align: center;
  padding-bottom: 8px;
}
.notFoundPageWrapper {
  margin-top: 32px;
  margin-bottom: 32px;
}
.loginPageRoot {
  display: flex;
  justify-content: center;
}
.loginPageRoot .paper {
  margin-top: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loginPageRoot .form {
  width: 100%;
}
.loginPageRoot .forgotPassword {
  margin-top: 16px;
}
.driverTraceBreakdownRoot .topBar {
  height: 2px;
}
.driverTraceBreakdownRoot .passed {
  background-color: #4CAF50;
}
.driverTraceBreakdownRoot .failed {
  background-color: #F44336;
}
.driverTraceBreakdownRoot .unknown {
  background-color: #9E9E9E;
}
.driverTraceBreakdownRoot .mainContent {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}
.driverTraceBreakdownRoot .textHolder {
  flex: 1;
  padding: 8px;
}
.driverTraceBreakdownWidgetRoot {
  margin-bottom: 16px;
}
.driverTraceBreakdownWidgetRoot .mainCard {
  padding: 16px;
  height: 240px;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.expansionPanelRoot {
  margin-bottom: 16px;
}
.expansionPanelRoot .expansionHeader {
  width: 100%;
  display: flex;
  padding: 16px;
  min-height: 80px;
}
.expansionPanelRoot .titleWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
}
.expansionPanelRoot .expand {
  transform: rotate(0deg);
  margin-left: auto;
  max-width: 24px;
}
.expansionPanelRoot .expandOpen {
  rotate: 180deg;
}
.resetPasswordPageRoot .paper {
  margin-top: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resetPasswordPageRoot .form {
  width: 100%;
}
.resetPasswordPageRoot .passwordStrength {
  margin: 16px 0;
}
.resetPasswordPageRoot .forgotPassword {
  margin-top: 16px;
}
.driverCardRoot {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  margin-left: 8px;
  margin-right: 8px;
}
.driverCardRoot .content {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.driverCardRoot .driverIcon {
  height: 100%;
  padding: 16px;
}
.driverCardRoot .driverInfo {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.RouteCardRoot {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  margin-left: 8px;
  margin-right: 8px;
}
.RouteCardRoot .topContent {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.RouteCardRoot .mapIcon {
  height: 100%;
  padding: 16px;
}
.RouteCardRoot .routeInfo {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.RouteCardRoot .linkSection {
  margin-left: 16px;
  margin-right: 16px;
}
.RouteCardRoot .routeButtonLink {
  text-decoration: none;
}
.overallHelpDialog {
  height: 80vh;
}
.overallHelpDialog .stepper {
  margin: 8px;
}
.overallHelpDialog .dialogContent {
  display: flex;
  flex-direction: column;
}
.overallHelpDialog .fillContent {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.overallHelpDialog .feedbackBlock {
  order: 1;
  margin-top: 64px;
}
.overallHelpDialog .detailsWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.overallHelpDialog .detailsContent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.overallHelpDialog .imgContainer {
  flex: 1;
  height: 100%;
  position: relative;
}
.overallHelpDialog .dcmImage {
  height: 0;
  min-height: 100%;
}
.orderDetailsMobileRoot {
  z-index: 10;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  height: 45%;
}
.orderDetailsMobileRoot.headerSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
}
.orderDetailsMobileRoot .paper {
  margin: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.orderDetailsMobileRoot .routeCard {
  margin: 8px;
}
.orderDetailsMobileRoot .driverCard {
  margin: 8px;
}
.orderDetailsMobileRoot .waypointTabsCard {
  margin: 8px;
}
.orderDetailsMobileRoot .deliveryInfo {
  max-height: 100%;
  overflow: hidden;
}
.completedPanelRoot .orderStatusWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin: 8px;
}
.completedPanelRoot .completedTimeWrapper {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
}
.MuiTypography-alignLeft {
  text-align: left !important;
}
.defaultPanelRoot .contentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin: 8px;
}
.defaultPanelRoot .orderStatusIcon {
  width: 15px;
  height: 15px;
  margin-right: 8px;
  background-color: red;
  border-radius: 50%;
}
.etaSpanRoot {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-left: 8px;
  flex-wrap: wrap;
}
.etaSpanRoot .etaStackedLabelContainer {
  flex-grow: 1;
  text-align: left;
  width: 100%;
}
.etaSpanRoot .timeColor {
  color: grey;
}
.etaSpanRoot .labelColor {
  color: grey;
}
.noAssignedDriverPanelRoot .notActivePanelRoot .contentWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin: 8px;
}
.readyToStartPanelRoot .orderStatusWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 8px;
}
.readyToStartPanelRoot .orderStatusIcon {
  width: 15px;
  height: 15px;
  margin-right: 8px;
  background-color: red;
  border-radius: 50%;
}
.routeStartedPanelRoot .driverStatusContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-left: 8px;
  margin-top: 8px;
}
.routeStartedPanelRoot .contentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-left: 8px;
}
.routeStartedPanelRoot .stopsAheadContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-left: 8px;
  margin-top: 8px;
}
.routeStartedPanelRoot .orderStatusIcon {
  width: 15px;
  height: 15px;
  margin-right: 8px;
  background-color: red;
  border-radius: 50%;
}
.statusChipRoot {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 16px;
  margin-top: 8px;
}
.statusChipRoot .chipShape {
  border-radius: 4px;
  height: 32px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.statusChipRoot .chipLabel {
  display: flex;
  font-weight: bold;
}
.statusChipRoot .syncProblemContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.statusChipRoot .syncProblemIcon {
  padding-left: 8px;
  padding-right: 8px;
}
.statusChipRoot .badChip {
  background-color: #f44336;
  color: white;
}
.statusChipRoot .goodChip {
  background-color: #4caf50;
  color: white;
}
.statusChipRoot .warningChip {
  color: white;
}
.orderListCardRoot {
  display: flex;
  flex-direction: row;
  margin: 0 16px 16px;
}
.orderListCardRoot .leftSide {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  box-shadow: 13px 0px 1px -10px #eeeeee;
  margin-right: 8px;
}
.orderListCardRoot .rightSide {
  width: 40%;
}
.orderListCardRoot .labelWithIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 40px);
}
.orderListCardRoot .icon {
  font-size: 42px;
  padding: 8px;
  color: grey;
}
.orderListCardRoot .label {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}
.orderListCardRoot .orderStatusWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin: 8px;
}
.orderListCardRoot .etaWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 8px;
  flex-wrap: wrap;
}
.orderListCardRoot .etaStackedLabelContainer {
  flex-grow: 12;
  text-align: center;
  width: 100%;
}
.orderListCardRoot .orderStatusIcon {
  width: 15px;
  height: 15px;
  margin-right: 8px;
  background-color: red;
  border-radius: 50%;
}
.orderListCardRoot .orderStatusIconOnline {
  background-color: #56E9C8;
}
.orderListCardRoot .orderStatusIconOffline {
  background-color: #f44336;
}
.searchBoxRoot {
  margin: 32px 16px;
}
.searchBoxRoot .searchInputWrapper {
  padding: 4px 8px 4px 16px;
  display: flex;
}
.searchBoxRoot .searchInput {
  flex: 1;
  display: flex;
  align-items: center;
}
.searchBoxRoot .iconButton {
  padding: 10px;
}
.searchBoxRoot .buttonDividerWrapper {
  display: flex;
  align-items: center;
  padding: 4px;
}
.searchBoxRoot .buttonDivider {
  height: 24px;
}
.searchBoxRoot .backLink {
  cursor: pointer;
  display: block;
  padding: 8px 16px;
}
.orderListRouteName {
  overflow: hidden;
  text-overflow: ellipsis;
}
.searchResultListRoot {
  overflow-y: auto;
}
.searchResultListRoot .searchResultListItem {
  overflow-x: hidden;
  display: list-item;
  padding: 8px 32px;
}
.searchResultListRoot .searchResultListItem:hover {
  background-color: #eeeeee;
  cursor: pointer;
}
.searchResultListRoot .loadingListItem {
  display: flex;
  justify-content: center;
}
.searchResultListRoot .dcFacilityInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.searchResultListRoot .dcFacilityName {
  flex: 1;
}
.searchResultListRoot .dcFacilityId {
  margin-left: 8px;
  justify-content: flex-end;
}
.searchResultListRoot .dcFacilityValue {
  margin: 0 8px 0 8px;
}
.searchResultListRoot .locationIcon {
  margin-left: -3px;
  margin-right: 3px;
}
.searchResultListRoot .addressInfo {
  display: flex;
  align-items: center;
}
.searchResultListRoot .noSearchResults {
  margin: 0 32px;
}
.singleCustomerInfoRoot {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
}
.singleCustomerInfoRoot .backLink {
  margin: 0 16px 16px;
}
.singleCustomerInfoRoot .dcInfo {
  display: flex;
  margin: 8px 16px 8px 16px;
}
.singleCustomerInfoRoot .dcExtId {
  flex: 1;
}
.singleCustomerInfoRoot .dcName {
  flex: 1;
}
.singleCustomerInfoRoot .pocValue {
  margin: 0 0 0 16px;
}
.singleCustomerInfoRoot .customerInfo {
  margin: 16px 16px;
}
.singleCustomerInfoRoot .locationIcon {
  margin-left: -3px;
  margin-right: 3px;
}
.singleCustomerInfoRoot .addressInfo {
  display: flex;
  align-items: center;
}
.singleCustomerInfoRoot .orderHistory {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
}
.singleCustomerInfoRoot .orderHistoryLabelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.singleCustomerInfoRoot .orders {
  flex: 1;
  overflow-y: auto;
}
.singleCustomerInfoRoot .orderHistoryLabel {
  margin: 16px;
}
.singleCustomerInfoRoot .spinnerContainer {
  text-align: center;
}
.singleCustomerInfoRoot .noOrdersText {
  margin: 16px;
}
.dcmHeight {
  max-height: calc(100vh - 64px);
}
.digitalCustomerManagerRoot {
  height: 100vh;
}
.digitalCustomerManagerRoot .sticky {
  z-index: 2;
}
.digitalCustomerManagerRoot .fullscreenContent {
  flex: 1 1 auto;
  justify-content: space-between;
  display: flex;
  max-width: 100vw;
}
.digitalCustomerManagerRoot .map-container {
  flex: 1 1 auto;
}
.digitalCustomerManagerRoot .map-container > div {
  width: 100%;
  height: 100%;
}
.digitalCustomerManagerRoot .helpButton {
  position: absolute;
  right: 0;
  padding: 10px;
}
.digitalCustomerManagerRoot .dcm-sidebar {
  width: 480px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  position: absolute;
}
.digitalCustomerManagerRoot .dcm-sidebar > ul {
  flex: 1 1 10px;
}
.digitalCustomerManagerRoot .sidebarBackground {
  background-color: #eeeeee;
  position: unset;
}
.digitalCustomerManagerRoot .sidebarMaxHeight {
  height: 100%;
}
.dcSettingPageStep {
  font-size: 0.875rem !important;
  margin-top: 1em;
}
.circularChartRoot {
  display: flex;
  height: 240px;
  position: relative;
  backgroundColor: #3f51b5;
}
.circularChartRoot .circularChartLabelContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circularChartRoot .circularChartContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.circularChartRoot .circularChartLabel {
  font-size: 2.125rem;
  font-weight: 400;
}
.circularChartRoot .circularChart {
  display: flex;
  align-items: center;
  max-width: 80%;
  height: 80%;
}
.routeNameAndLinkFormatterRoot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.goodValueFormatter {
  color: black;
}
.badValueFormatter {
  color: #F44336;
}
.neutralValueFormatter {
  color: #9E9E9E;
}
.successfulVisitStatusFormatter {
  color: #00B155;
}
.failedVisitStatusFormatter {
  color: #ED4957;
}
.visitLaterVisitStatusFormatter {
  color: #F7AF00;
}
.mixedVisitStatusFormatter {
  color: #00B155;
}
.authorizeReattemptVisitStatusFormatter {
  color: #718290;
}
.detailModalHeaderRoot {
  margin-bottom: 16px;
}
.detailModalRoot .appBar {
  position: relative;
}
.detailModalRoot .flex {
  flex: 1;
}
.detailModalRoot .contentRoot {
  margin-top: 24px;
  margin-bottom: 80px;
}
.driverPerformanceTableRoot .rowRoot {
  cursor: pointer;
}
.driverPerformancePageRoot .tableContainer {
  margin-bottom: 80px;
}
.driverPerformancePageRoot .alertContainer {
  background-color: #fcf8e3;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.kpiDefinitionDrawerRoot {
  margin-bottom: 100px;
  width: 100%;
  max-width: 400px;
}
.kpiDefinitionDrawerRoot .drawerHeading {
  margin-bottom: 8px;
}
.kpiDefinitionExampleCellRoot {
  display: flex;
  justify-content: center;
}
.kpiDefinitionExampleCellRoot .outerCell {
  display: flex;
  border-style: solid;
  border-width: 1px;
  width: 50%;
  align-items: center;
  justify-content: center;
}
.kpiDefinitionExampleCellRoot .innerCell {
  display: flex;
  flex-direction: column;
}
.kpiDefinitionExampleCellRoot .value {
  font-weight: bold;
}
.kpiDefinitionExampleCellRoot .historicalValue {
  font-weight: 200;
}
.kpiDefinitionExampleCellRoot .change {
  padding-left: 15px;
}
.dailyCalendarRoot {
  display: flex;
  margin-top: 8px;
  align-items: flex-start;
  padding-left: 100px;
  padding-right: 100px;
  justify-content: space-between;
}
.dailyCalendarRoot .monthLabel {
  margin-bottom: 8px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
}
.dailyCalendarRoot .calendarHeading {
  display: flex;
  justify-content: space-between;
}
.dailyCalendarRoot .weekWrapper {
  display: flex;
  justify-content: center;
}
.dailyCalendarRoot .day {
  width: 30px;
  height: 30px;
  font-size: 0.75rem;
  color: inherit;
  display: flex;
  flex-direction: column;
}
.dailyCalendarRoot .dayOfWeek {
  text-align: center;
  margin-left: 3px;
  margin-right: 3px;
  color: grey;
}
.dailyCalendarRoot .nonCurrentMonthDay {
  color: grey;
}
.dailyCalendarRoot .dayIsOutsideOfRange {
  color: grey;
}
.dailyCalendarRoot .highlightNonCurrentMonthDay {
  color: #676767;
}
.dailyCalendarRoot .comparisonPeriodWrapper {
  position: relative;
  overflow: hidden;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 2px;
}
.dailyCalendarRoot .inComparisonPeriod {
  background: #3f51b5;
  color: white;
}
.dailyCalendarRoot .firstInComparisonPeriod {
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
}
.dailyCalendarRoot .lastInComparisonPeriod {
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
}
.dailyCalendarRoot .selectedPeriodWrapper {
  padding-left: 3px;
  padding-right: 3px;
}
.dailyCalendarRoot .inSelectedPeriod {
  background: #f50057;
  color: white;
}
.dailyCalendarRoot .firstInSelectedPeriod {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  margin-left: 3px;
  padding-left: 0;
}
.dailyCalendarRoot .lastInSelectedPeriod {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  margin-right: 3px;
  padding-right: 0;
}
.headingRoot {
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: #3f51b5;
  color: white;
}
.headingRoot > .yearLabel {
  cursor: pointer;
  color: white;
}
.headingRoot > .dateLabel {
  color: white;
}
.fleetDatePicker {
  color: white !important;
}
.monthPickerRoot {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 107px;
  padding-right: 107px;
}
.monthPickerRoot .monthGroup {
  display: flex;
  justify-content: center;
}
.monthPickerRoot .comparisonPeriodWrapper {
  position: relative;
  overflow: hidden;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 4px;
}
.monthPickerRoot .inComparisonPeriod {
  background: #3f51b5;
}
.monthPickerRoot .firstInComparisonPeriod {
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
}
.monthPickerRoot .lastInComparisonPeriod {
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
}
.monthPickerRoot .selectedPeriodWrapper {
  padding-left: 3px;
  padding-right: 3px;
}
.monthPickerRoot .inSelectedPeriod {
  margin-left: 3px;
  margin-right: 3px;
  padding-left: 0;
  padding-right: 0;
  background: #f50057;
  border-radius: 15px;
}
.monthPickerRoot .monthButton {
  width: 100px;
  text-align: center;
  color: #3f51b5;
}
.monthPickerRoot .monthButtonInComparisonPeriod {
  color: white;
}
.monthPickerRoot .monthButtonDisabled {
  color: grey;
}
.yearSelectorRoot {
  overflow-y: auto;
}
.yearSelectorRoot .year {
  cursor: pointer;
  color: #002984;
}
.yearSelectorRoot .selectedYear {
  color: #3f51b5;
}
.fleetPerformanceToolbarDatePicker {
  flex-grow: 1;
}
.fleetPerformanceToolbarPageHeader {
  flex-grow: 2;
}
.fleetPerformanceToolbarinfoIcon {
  color: #0000008a;
}
.authorizeReattemptModalRoot .MuiDialog-container {
  align-items: start;
}
.authorizeReattemptModalRoot .modal-title {
  font-weight: 600;
  font-size: 1.125rem;
  text-align: center;
}
.authorizeReattemptModalRoot .section-label {
  color: #363e45;
  margin: 6px 0;
  font-weight: 600;
  font-size: 0.875rem;
}
.authorizeReattemptModalRoot .section-label .modal-title {
  color: #363e45;
  margin: 6px 0;
}
.authorizeReattemptModalRoot .modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.authorizeReattemptModalRoot .modal-header > .MuiTypography-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.authorizeReattemptModalRoot .modal-header > .MuiTypography-root .modal-title {
  font-weight: bolder;
}
.authorizeReattemptModalRoot .double-label-toggle-switch {
  color: #000000DE;
  font-size: 1rem;
}
.authorizeReattemptModalRoot .auth-modal-deliveries-container {
  border: 1px solid #DDD;
  border-radius: 8px;
  overflow-y: auto;
}
.authorizeReattemptModalRoot .auth-modal-deliveries-container > * {
  border-bottom: 1px solid #D9D9D9;
}
.authorizeReattemptModalRoot .auth-modal-deliveries-container > *:last-child {
  border: none;
}
.authorizeReattemptModalRoot .auth-modal-deliveries-container .auth-modal-switch-row {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  font-size: 1rem;
  color: #909090;
}
.authorizeReattemptModalRoot .modal-body form {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.authorizeReattemptModalRoot .modal-body form .auth-modal-deliveries-container {
  flex: 1 1 auto;
  max-height: 30vh;
}
.authorizeReattemptModalRoot .modal-body form .date-time-range-picker {
  display: flex;
  gap: 30px;
}
.authorizeReattemptModalRoot .modal-footer {
  padding: 15px;
  border-top: 1px solid #e5e5e5;
}
.authorizeReattemptModalRoot .modal-footer .MuiButton-root {
  border-radius: 2px;
  text-transform: uppercase;
  background-color: #FFF;
  border: 1px solid #CCC;
  font-size: 0.875rem;
  color: #333;
}
.authorizeReattemptModalRoot .modal-footer .MuiButton-textPrimary {
  background-color: #5cb85c;
  border-color: #4cae4c;
  color: #FFF;
}
.forgotPasswordPageRoot .paper {
  margin-top: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.forgotPasswordPageRoot .form {
  width: 100%;
}
.forgotPasswordPageRoot .forgotPassword {
  margin-top: 16px;
}
.bulkCreateDriversPageRoot .paper {
  padding-top: 32px;
  padding-bottom: 32px;
  margin: 32px 0;
}
.bulkCreateDriverPageDetectCollisionsButtonBar {
  margin-top: 8px;
}
.editUserDCAccessModalRoot .appBar {
  position: relative;
}
.editUserDCAccessModalRoot .title {
  margin-left: 16px;
  flex: 1;
}
.editUserDCAccessModalRoot .wrapper {
  margin-bottom: 32px;
}
.editUserDCAccessModalRoot .heading {
  margin: 32px 0;
}
.editUserDCAccessPageRoot .wrapper {
  margin-bottom: 32px;
}
.editUserDCAccessPageRoot .heading {
  margin: 32px 0;
}
.creatingStepRoot .spinnerContainer {
  text-align: center;
}
.loginRoot {
  display: flex;
  justify-content: center;
}
.loginPaper {
  margin-top: 16px;
  padding: 32px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.loginForm {
  width: 100%;
}
.forgotPassword {
  margin-top: 16px !important;
}
html {
  font-size: 16px !important;
  -webkit-font-smoothing: antialiased;
}
.MuiAppBar-root {
  z-index: 1;
}
.waypointRoot {
  margin: 0 16px 16px 16px;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
}
.waypointTabs .MuiTabs-scroller .MuiTabs-indicator {
  color: #3f51b5;
  background-color: #3f51b5 !important;
}
.active {
  border-color: #56E9C8 !important;
  box-shadow: 3px 4px rgba(0, 0, 0, 0.18);
  border-radius: 10px !important;
}
.activeStop {
  border-color: #56E9C8 !important;
  border-radius: 10px !important;
  background-color: #56e9c8 !important;
  color: white;
}
.finished {
  border-color: #060525;
  border-radius: 0;
  box-shadow: none;
}
.unfinished {
  border-color: #adadad;
  box-shadow: 3px 4px rgba(0.18);
  border-radius: 10px;
}
.expand {
  transform: rotate(0deg);
  margin-left: auto;
}
.expandOpen {
  transform: rotate(180deg);
}
.lineGraphBox {
  border-style: solid;
  border-color: whitesmoke;
  border-width: 1px;
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
  width: 100%;
}
.statsContainer {
  padding-top: 10px;
  text-align: center;
}
.legend {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 8px;
}
.legendStat {
  margin: 0 8px 0 8px;
}
.success {
  background-color: #00B155;
  font-weight: 600;
}
.attemptSuccess {
  background-color: #cbffe4;
}
.successText {
  color: #00B155;
  margin-right: 5px;
}
.failed {
  background-color: #ED4957;
  font-weight: 600;
}
.failedText {
  color: #ED4957;
  margin-right: 5px;
}
.visitLater {
  background-color: #F7AF00;
  font-weight: 600;
}
.visitLaterText {
  color: #F7AF00;
  margin-right: 5px;
}
.authorizeReattempt {
  background-color: #7F21FF;
  font-weight: 600;
}
.authorizeReattemptText {
  color: #7F21FF;
  margin-right: 5px;
}
.notAttempted {
  background-color: #adadad;
  font-weight: 600;
}
.notAttemptedText {
  color: #adadad;
  margin-right: 5px;
}
.successStatusIcon {
  background-color: #00B155;
}
.failedStatusIcon {
  background-color: #ED4957;
}
.visitLaterStatusIcon {
  background-color: #F7AF00;
}
.authorizeReattemptStatusIcon {
  background-color: #7F21FF;
}
.notAttemptedStatusIcon {
  background-color: #adadad;
}
.deliveryListContainer {
  display: flex;
  flex-direction: column;
  padding: 0 24px 16px 24px;
}
.deliveryListColumnHeaders {
  display: flex;
  padding: 8px 24px 8px 32px;
}
.productRow {
  display: flex;
  align-items: stretch;
}
.deliveryItem {
  display: flex;
  align-items: center;
}
.productCodeMsg {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 4px 8px 4px 8px;
}
.border-bottom {
  border-bottom: 1px solid lightgrey;
  padding-left: 8px;
}
.quantity {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.statusIcon {
  width: 4px;
}
.barAndStats {
  padding: 8px;
}
.deliveryCodeAndMessage {
  font-size: 10px;
}
.hideDiv {
  display: none;
}
.codeAndMsg {
  margin-left: 16px;
}
.notesIcon {
  width: 16px;
  height: 16px;
}
.notesContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.notesSpan {
  display: flex;
  align-items: center ;
}
.border {
  border-style: solid;
  display: flex;
  flex-direction: row;
  border-color: gray;
  border-width: 1px;
  border-radius: 5px;
}
.statusIcon {
  width: 4px;
}
.deliveryListContainer {
  display: flex;
  flex-direction: column;
  padding: 0 24px 16px 24px;
}
.deliveryListColumnHeaders {
  display: flex;
  padding: 8px 24px 8px 32px;
}
.productRow {
  display: flex;
  align-items: stretch;
}
.deliveryItem {
  display: flex;
  align-items: center;
}
.productCodeMsg {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 4px 8px 4px 8px;
}
.border-bottom {
  border-bottom: 1px solid lightgrey;
  padding-left: 8px;
}
.quantity {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.deliveryCodeAndMessage {
  font-size: 10px;
}
.hideDiv {
  display: none;
}
.codeAndMsg {
  margin-left: 16px;
}
.notesIcon {
  width: 16px;
  height: 16px;
}
.notesContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.notesSpan {
  display: flex;
  align-items: center;
}
.header {
  display: flex;
  align-items: center;
  margin: 8px 0;
  width: 100%;
  padding: 0 8px;
}
.stop-icon-waypoint {
  display: flex;
  justify-content: center;
  align-items: center;
}
.stop-icon-home {
  width: 42px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  border-radius: 50% !important;
  border: 1px solid black;
  margin: auto;
  padding-top: 6px;
}
.stop-icon-waypoint {
  width: 42px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  border-radius: 50% !important;
  border: 1px solid black;
  margin: auto;
}
.stopNumber {
  width: 50px;
}
.active {
  background-color: #56E9C8;
  color: #FFF;
}
.finished {
  background-color: #060525;
  color: #FFF;
}
.unfinished {
  background-color: #adadad;
  color: #FFF;
}
.unfinishedStop {
  background-color: #adadad;
  color: #FFF;
  border: unset;
}
.waypointInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding: 0 8px;
}
.waypointInfoText {
  flex: 1;
  display: flex;
}
.waypointNameAddressAndExtId {
  display: flex;
  flex-direction: column;
  width: 70%;
  text-align: left;
}
.waypointName {
  font-size: 14px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.waypointAddress {
  font-size: 12px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.visits {
  font-size: 12px;
}
.etaAndVisits {
  text-align: right;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.eta {
  font-style: italic;
}
.visitTimestamp {
  font-weight: 600;
}
.noEta {
  font-style: italic;
}
.progressBar {
  margin-top: 8px;
}
.progressBarHidden {
  visibility: hidden;
}
.expand {
  transform: rotate(0deg);
  margin-left: auto;
}
.expandOpen {
  transform: rotate(180deg);
}
.wrapper {
  overflow-y: auto;
}
.stickyWrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}
.stickyContents {
  overflow-y: auto;
  max-height: 100%;
  flex: 1;
}
.waypointHeader {
  display: flex;
  align-items: center;
  margin: 0 16px 16px 16px;
  padding: 8px;
  width: 100%;
}
.waypointInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 8px;
}
.waypointInfoText {
  flex: 1;
  display: flex;
}
.waypointNameAddressAndExtId {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 70%;
}
.waypointName {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.waypointAddress {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.timestamp {
  font-weight: 600 !important;
}
.etaAndVisits {
  text-align: right;
}
.dataContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0 0 8px;
}
.waitTimeIcon {
  width: 16px;
  height: 16px;
}
.timeIcon {
  width: 16px;
  height: 16px;
}
.confidenceIcon {
  width: 16px;
  height: 16px;
}
.iconContainer {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.confident {
  color: #00B155;
}
.unconfident {
  color: #ED4957;
}
.unknown {
  color: #adadad;
}
.dataLabel {
  margin-right: 5px;
  color: #9e9e9e;
}
.waypointInfo {
  font-size: 14px;
}
.waypointInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 8px;
}
.waypointInfoText {
  flex: 1;
  display: flex;
}
.waypointNameAddressAndExtId {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 70%;
}
.waypointName {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.waypointAddress {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.timestamp {
  font-size: 14px;
}
.etaAndVisits {
  text-align: right;
}
.route-list-day-root {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin: 32px 0;
}
.route-list-day-root .MuiCollapse-wrapperInner {
  overflow: auto;
}
.route-list-day-root .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
}
.route-list-day-root .headerChip {
  margin-right: 8px;
}
.route-list-day-root .expand {
  transform: rotate(0deg);
  margin-left: auto;
}
.route-list-day-root .expanded {
  transform: rotate(180deg);
}
.route-list-day-root .collapse {
  padding: 0 16px 16px 16px;
}
.route-list-day-root .links {
  text-align: center;
  max-width: max-content;
}
.route-list-day-root .links > div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 5px;
}
.route-list-day-root .start-time,
.route-list-day-root .import-time,
.route-list-day-root .finalized,
.route-list-day-root .totalClients {
  text-align: right;
}
.caption {
  margin-bottom: 0;
}
.runRoutePaper {
  padding-top: 16px;
  padding-bottom: 16px;
  margin: 32px 0;
}
.dialogContent {
  padding: 8px;
  margin: 8px;
}
.lineContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lineItem {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.finishRoutePaper {
  padding-top: 32px;
  padding-bottom: 32px;
  margin: 32px 0;
}
.customerSearchWrapper {
  margin-top: 32px;
  margin-bottom: 32px;
}
.mapDatePicker {
  padding-right: 20px;
}
.wrapper {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.maxWidthNone {
  max-width: none !important;
}
.expand {
  position: relative;
  flex: 1;
}
.messageCloseButton {
  padding: 8px;
}
.goodValue {
  color: #087f23;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.badValue {
  color: #ba000d;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.neutralValue {
  color: #9e9e9e;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.outerCell {
  display: flex;
  justify-content: space-evenly;
}
.innerCell {
  display: flex;
  flex-direction: column;
}
.value {
  font-weight: bold;
}
.historicalValue {
  font-weight: 200;
}
.spinning {
  text-align: center;
  vertical-align: middle;
}
.cell {
  text-align: center;
}
.routeAnalyzer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mapAndVisitList {
  flex: 1;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid;
}
.borderBottom {
  padding-left: 8px;
  border-bottom: 1px solid lightgrey;
}
.editableText {
  display: flex;
  align-items: center;
}
.editableText:hover > span {
  filter: opacity(100);
}
.editableTextRoute {
  display: flex;
  align-items: center;
}
.editableTextRoute > a {
  color: #060525;
  text-decoration: none;
}
.editableTextRoute:hover > span {
  filter: opacity(100);
}
.editableTextValue {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}
.editableTextValueRoute {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}
.editableTextValueRoute > a {
  color: #060525 !important;
  text-decoration: none !important;
}
.editableTextValueLeft {
  text-align: left;
}
.editableTextIcon {
  filter: opacity(0);
  cursor: pointer;
  padding-left: 10px;
}
.controls {
  color: #8a959e;
  display: flex;
  padding: 5px 0;
}
.playPauseAndTime {
  text-align: center;
  padding: 5px 15px;
  min-width: 125px;
  border-right: 1px solid #8a959e;
}
.currentTime {
  font-size: 20px;
}
.timeline {
  flex: 1;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
}
.warehouseRoot {
  position: absolute;
  width: 400px;
  right: 16px;
  margin-top: 16px;
}
.warehousePaper {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
}
.spinnerContainer {
  display: flex;
  justify-content: center;
}
.buttonHolder {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}
.contents {
  display: flex;
}
.leftPanel {
  padding: 16px;
}
.driversSettingsPanel {
  flex: 1;
  margin: 32px;
}
.driversSettingsPanel .MuiButton-root {
  background-color: #060525;
  color: white !important;
}
.secretCodePaper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 16px;
}
.dcSettingsPanel {
  flex: 1;
  margin: 32px;
}
.chips {
  display: flex;
  flex-wrap: wrap;
}
.chip {
  margin: 2px;
}
.usersSettingsPanel {
  flex: 1;
  margin: 32px;
}
.driverGroupsSettingsPanel {
  flex: 1;
  margin: 32px;
}
.loginBackground {
  margin: 0 auto;
  padding: 8px;
  background-color: #375065;
  max-width: 80%;
  border-radius: 8px;
  text-align: center;
}
.MuiMenuItem-root.Mui-selected {
  background-color: #428bca !important;
  color: white !important;
}
.MuiOutlinedInput-input.MuiSelect-select {
  min-height: auto !important;
}
.colorButton {
  background-color: #060525 !important;
}
.uploadButton {
  height: 34px;
}
.uploadField {
  width: 70%;
  height: 34px;
  color: #ccc !important;
  padding: 6px 12px;
  border: 1px solid #ccc;
  font-family: "Simplon";
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.mapName {
  height: 34px;
  color: #555;
  width: calc(100% - 10px);
  margin-right: 10px;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Simplon";
}
.mapImporterLabel {
  font-weight: 500;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-family: "Simplon";
}
.marginAuto {
  margin: auto;
}
.importerDateInput {
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc !important;
  border-radius: 4px;
}
.importerDateInput .MuiInput-input {
  padding: 5px 10px;
  border-right: 1px solid #ccc !important;
  color: #555;
  font-size: 14px;
}
.importerDateInput .MuiInputBase-root {
  border-bottom: unset;
}
.importerDateInput .MuiInput-root::before {
  border-bottom: unset;
}
.importerDateInput .MuiInputAdornment-root .MuiButtonBase-root {
  border-radius: 2px;
  text-transform: uppercase;
  font-family: "Simplon";
  font-weight: 300;
  padding: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding-right: 20px;
}
.realtimeDatePicker {
  padding-top: 16px !important;
  width: 100%;
}
.MuiInputLabel-root {
  z-index: 1;
  background-color: inherit;
}
.backgroundLabel-white {
  z-index: 1;
  background-color: white;
}
.mapClass {
  overflow: visible;
}
.MuiButton-textPrimary:hover {
  background-color: #060525 !important;
  color: white !important;
}
.MuiButton-textSecondary:hover {
  background-color: #7D7D8E !important;
}
.inlineFlex {
  display: inline-flex;
}
.attemptListHeader {
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  padding: 2px;
  border-bottom: 1px solid #8a959e;
}
.attemptList {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #8a959e;
}
.attemptsWrapper {
  flex: 1;
  overflow-y: auto;
  flex-direction: column;
  position: relative;
}
.attempts {
  position: absolute;
  width: 100%;
}
.attempt {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  border-bottom: 1px solid #8a959e;
}
.attemptFailed {
  background-color: #fbd4d8;
}
.attemptVisitLater {
  background-color: #ffdf91;
}
.attemptAuthorizeReattempt {
  background-color: #d7baff;
}
.attemptcurrent {
  background-color: #f1f8fe;
}
.numberBadge {
  position: relative;
  width: 28px;
  height: 28px;
  line-height: 24px;
  margin: 5px 5px 5px 0;
  padding: 5px;
  text-align: center;
  background-color: #8a959e;
  border-radius: 50%;
  box-shadow: 0 0 1px black;
  border: 2px solid white;
}
.actualNumber {
  position: absolute;
  color: white;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.waypointInfo {
  flex: 1;
  font-size: inherit !important;
}
.name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
}
.extIdAndServiceTime {
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.route-list-heading {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.route-list-heading > * {
  max-width: 33%;
}
.route-list-heading > div {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.marker-label {
  position: absolute;
  overflow: hidden;
  opacity: 0.01;
  margin-left: 16px;
  margin-top: -32px;
  left: -116px;
  top: -100px;
  z-index: -99;
  display: block;
  cursor: pointer;
}
body {
  font-weight: 300;
  font-size: 14px;
  font-family: "Simplon" !important;
}
.underlineDecoration {
  text-decoration: underline !important;
}
.MuiStepper-root {
  display: flex;
  padding: 24px;
}
@font-face {
  font-family: 'Simplon';
  src: url('../../app/fonts/simplonbp-regular-webfont.woff2') format('woff');
}
.MuiContainer-maxWidthMd {
  max-width: 960px !important;
}
.MuiContainer-maxWidthLg {
  max-width: 1280px !important;
}
.MuiTooltip-arrow {
  pointer-events: none !important;
}
.MuiTooltip-popper {
  pointer-events: none !important;
}
.modal-map {
  flex: 1;
  position: relative;
  width: 600px;
  height: 500px;
}
.displayFlex {
  display: flex;
}
.searchWrapper > input {
  width: inherit !important;
}
.MuiTabs-indicator {
  background-color: #f50057 !important;
}
.autoWidth {
  width: auto;
  padding: unset;
  margin: unset;
}
.addDeliveryCode .MuiTabs-indicator {
  background-color: #48e9c8 !important;
}
.warehouseExpand {
  flex: 1 1 auto;
  display: flex;
}
.warehouseExpand .map-container {
  flex: 1 1 auto;
}
.warehouseExpand .map-container > div {
  width: 100%;
  height: 100%;
}
.MuiDialog-container .map-container {
  width: 552px;
  height: 500px;
}
.MuiDialog-container .map-container > div {
  width: 100%;
  height: 100%;
}
.json-view > span > svg {
  height: 20px;
  width: 20px;
}
.json-view > svg {
  height: 20px;
  width: 20px;
}
.json-view--pair > span > svg {
  height: 20px;
  width: 20px;
}
.json-view--pair > svg {
  height: 20px;
  width: 20px;
}
.manageUserButton:hover {
  color: white;
}
.rightSideContainer {
  left: 480px;
}
.hasSidebar {
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-end;
  justify-content: center;
}
.MuiTypography-root.MuiTypography-h6,
.MuiTypography-root.MuiTypography-button,
h6.MuiTypography-root {
  font-weight: 600;
}
.MuiButton-root,
.MuiTab-root {
  font-weight: 600 !important;
}
.MuiButton-root > span,
.MuiTab-root > span {
  font-weight: 600;
}
.MuiTooltip-tooltip {
  font-weight: 600 !important;
}
